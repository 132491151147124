<template>
    <router-link :to="routerLinkTo" class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 flex items-center justify-between">
        <div>{{ resource.name }}</div>
        <div></div>
    </router-link>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    routerLinkTo() {
      return this.resource ? `/${this.resource.vuexModuleKey}/${this.resource.uuid}` : null;
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
