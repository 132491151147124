<template>

    <headlessDialog open class="relative z-40">

        <div class="fixed inset-0 bg-black/50" aria-hidden="true"/>

        <div class="fixed inset-0 flex w-screen items-center justify-center">

            <DialogPanel
                    class="relative bg-white dark:bg-neutral-900 md:rounded max-w-md w-full h-full md:h-auto md:max-h-[80vh] overflow-hidden md:overflow-auto flex flex-col"
            >

                <div class="flex items-center justify-between p-4"
                     :class="[
                         $isStandalone ? '!pb-6': null,
                     ]"
                >

                    <Title>Exercise</Title>

                    <div class="flex items-center justify-between gap-x-6">
                        <div class="cursor-pointer" @click="handleTimerToggle"
                             v-if="movement && !movement.trackDuration">
                            <Icon icon="stopwatch" class="text-2xl"/>
                        </div>
                        <div class="cursor-pointer" @click="handleCancel">
                            <Icon icon="times" class="text-2xl"/>
                        </div>
                    </div>

                </div>

                <div class="relative flex-grow overflow-scroll scroll-bar-hidden border-y border-y-neutral-300 dark:border-y-neutral-800 p-4 min-h-[46vh]">

                    <Field>
                        <Label>Movement</Label>
                        <SelectInput :options="movementsAsOptions" v-model="modelValue.movementId"></SelectInput>
                    </Field>

                    <template v-if="movement">

                        <Field v-if="movement.trackDuration || timer">
                            <Label>Timer</Label>
                            <Timer v-model="modelValue.duration"
                                   :metronome="true"
                            />
                        </Field>

                        <template v-if="movement.trackResistance">

                            <template v-if="movement.resistanceType === 'band'">

                                <Field>

                                    <Label>Bands
                                        {{
                                            movement.resistanceConfig && movement.resistanceConfig.bandDoubled ? `(Doubled)` : ''
                                        }}
                                    </Label>

                                    <BandsInput v-model="modelValue.bandIds" :lastExercise="lastExercise"/>

                                    <Help class="mt-3 text-center" v-if="lastExercise && lastExercise.bands.length">
                                        Previous: {{ lastExercise.bands.map(band => band.brandAndName).join(', ') }}
                                    </Help>
                                </Field>

                                <Field class="flex items-center justify-between">
                                    <p>Advanced</p>
                                    <Switch v-model="modelValue.advanced"/>
                                </Field>

                            </template>


                            <Field v-else>
                                <Label>Resistance ({{ movement.resistanceType }} in {{ weightDisplayUnit }})</Label>
                                <NumberInputUnitAware v-model="modelValue.weight"
                                                      baseUnit="kg"
                                                      :displayUnit="weightDisplayUnit"
                                                      class="text-center text-4xl py-6"
                                                      placeholder="0"
                                />
                                <Help class="py-1 text-center" v-if="lastExercise && lastExercise.weight">
                                    Previous:
                                    {{ $numeral($convert(lastExercise.weight, 'kg').to(weightDisplayUnit)).format(weightDisplayFormat) }}
                                    {{ weightDisplayUnit }}
                                </Help>

                            </Field>

                        </template>

                        <template v-if="movement.trackRepetitions">

                            <div class="flex gap-x-4">

                                <Field :class="[
                                  movement.trackRepetitionsPartial ? 'w-1/2' : 'w-full',
                                ]">
                                    <Label>Full Repetitions</Label>
                                    <NumberInput placeholder="0"
                                                 class="text-center text-4xl py-6"
                                                 v-model="modelValue.repetitions"/>
                                    <div class="flex">
                                        <Help class="py-1 text-center text-xl flex-1"
                                              v-if="lastExercise && lastExercise.repetitions !== undefined && lastExercise.repetitions !== null">
                                            Prev: {{ lastExercise.repetitions }}
                                        </Help>
                                        <Help class="py-1 text-center text-xl flex-1"
                                              v-if="maxExercise && maxExercise.repetitions !== undefined && maxExercise.repetitions !== null">
                                            Max: {{ maxExercise.repetitions }}
                                        </Help>
                                    </div>
                                </Field>

                                <Field class="w-1/2" v-if="movement.trackRepetitionsPartial">
                                    <Label>Partial Repetitions</Label>
                                    <NumberInput placeholder="0"
                                                 class="text-center text-4xl py-6"
                                                 v-model="modelValue.partialRepetitions"/>
                                    <Help class="py-1 text-center text-xl"
                                          v-if="lastExercise && lastExercise.partialRepetitions !== undefined && lastExercise.partialRepetitions !== null">
                                        Prev: {{ lastExercise.partialRepetitions }}
                                    </Help>
                                </Field>

                            </div>

                        </template>

                        <Field v-if="lastExercise && lastExercise.notes">
                            <div class="flex items-center justify-between">
                                <Label>Previous Notes</Label>
                                <div @click="handleLastExerciseNotesCopy">
                                    <Icon icon="copy" />
                                </div>
                            </div>
                            <p class="mt-3 bg-neutral-200 dark:bg-neutral-950 px-3 py-2 rounded">
                                {{lastExercise.notes}}
                            </p>
                        </Field>

                        <Field>
                            <Label>Notes</Label>
                            <TextInput v-model="modelValue.notes"/>
                        </Field>

                    </template>

                </div>

                <div class="flex items-center justify-between p-4">

                    <div>
                        <Button danger outlined @click="handleDelete" v-if="modelValue.id">
                            Delete
                        </Button>
                    </div>

                    <div>
                        <Button block primary @click="handleSave">
                            Save
                        </Button>
                    </div>

                </div>

            </DialogPanel>

        </div>

    </headlessDialog>

</template>

<script>
import {mapActions} from 'vuex';

import {
  Dialog as headlessDialog,
  DialogPanel,
} from '@headlessui/vue';

import BandsInput from '@/components/BandsInput.vue';
import Metronome from '@/components/Metronome.vue';
import Timer from '@/components/Timer.vue';

export default {
  name: 'Form',
  components: {
    BandsInput,
    DialogPanel,
    headlessDialog,
    Metronome,
    Timer,
  },
  computed: {
    lastExercise() {
      return this.lastExerciseId ? this.$store.getters['exercise/findBy'](this.lastExerciseId, 'id') : null;
    },
    maxExercise() {
      return this.maxExerciseId ? this.$store.getters['exercise/findBy'](this.maxExerciseId, 'id') : null;
    },
    movement() {
      return this.modelValue.movementId ? this.movements.find(movement => movement.id === this.modelValue.movementId) : null;
    },
    movements() {
      return this.$store.getters['movement/all'] || [];
    },
    movementsAsOptions() {
      return [
        {
          label: '- Select Movement -',
          value: null,
        },
        ...this.movements.sort((a, b) => a.name > b.name ? 1 : -1).map((movement) => {
          return {
            label: movement.name,
            value: movement.id,
          };
        }),
      ];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    weightDisplayFormat() {
      return this.user.systemOfMeasure === 'metric' ? '0,0.0' : '0,0';
    },
    weightDisplayUnit() {
      return this.user.systemOfMeasure === 'metric' ? 'kg' : 'lb';
    },
  },
  created() {
    if (this.modelValue.duration > 0) {
      this.timer = true;
    }
  },
  data() {
    return {
      lastExerciseId: null,
      loading: false,
      maxExerciseId: null,
      timer: false,
    }
  },
  methods: {
    ...mapActions({
      exerciseLast: 'exercise/last',
      exerciseMax: 'exercise/max',
    }),
    handleCancel() {
      this.$emit('cancel');
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleLastExerciseNotesCopy() {
      this.modelValue.notes = this.lastExercise.notes;
    },
    handleSave() {
      this.$emit('save');
    },
    handleTimerToggle() {
      this.timer = !this.timer;
    },
    loadLastExercise() {
      this.loading = false;
      this.exerciseLast({
        params: {
          exerciseId: this.modelValue.id || null,
          movementId: this.modelValue.movementId,
        },
      }).then((ids) => {
        this.lastExerciseId = ids.length ? ids[0] : null;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadMaxExercise() {
      this.loading = false;
      this.exerciseMax({
        params: {
          exerciseId: this.modelValue.id || null,
          movementId: this.modelValue.movementId,
        },
      }).then((ids) => {
        this.maxExerciseId = ids.length ? ids[0] : null;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  props: {
    modelValue: {
      required: true,
    }
  },
  watch: {
    ['modelValue.movementId']: {
      immediate: true,
      handler() {
        if (this.modelValue.movementId) {
          this.loadLastExercise();
          this.loadMaxExercise();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">

</style>
