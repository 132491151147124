<template>
    <div class="p-4 my-6"
         :class="[
            type === 'info' ? 'bg-neutral-400' : null,
            type === 'warning' ? 'bg-yellow-300 !text-neutral-950' : null,
            type === 'error' ? 'bg-red-700 !text-white' : null,
         ]"
    >
        <slot/>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Instruction',
  props: {
    type: {
      default: 'info',
      type: String,
    },
  }
})
</script>

<style scoped lang="scss">

</style>
