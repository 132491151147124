<template>
    <textarea
           class="input w-full block flex-1 p-2 rounded placeholder:text-gray-400 bg-transparent border border-neutral-300 dark:border-neutral-500"
           @input="handleInput"
           :placeholder="placeholder"
           :rows="rows"
    >{{modelValue}}</textarea>
</template>

<script>
export default {
  name: 'TextareaInput',
  methods: {
    handleInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
    rows: {
      default: 3,
      type: Number,
    }
  }
}
</script>

<style scoped lang="scss">

</style>
