<template>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Logout',
  created() {
    return this.$store.dispatch('auth/logout').then(() => {
      window.location = `${import.meta.env.VITE_APP_APP_URL}/auth/login`;
    });
  },
})
</script>

<style scoped lang="scss">

</style>
