import Index from '@/views/Leaderboard/Index';

const routes = [
  {
    name: 'leaderboard/index',
    path: '/leaderboard',
    component: Index,
    meta: {
      icon: 'trophy',
      title: 'Leaderboard',
    },
  },
];

export default routes;
