<template>
    <div class="my-6 rounded overflow-hidden">
        <highcharts :ref="mergedOptions.id"
                    :options="mergedOptions"
                    v-if="mergedOptions"

        />
    </div>
</template>

<script>
import _ from 'lodash';
import {defineComponent} from 'vue'
import {Chart} from 'highcharts-vue';
import {uuid} from '@/helpers.js';

export default defineComponent({
  name: 'Chart',
  components: {
    highcharts: Chart,
  },
  computed: {
    isDark() {
      return this.$store.state.system.settings.theme === 'dark';
    },
    mergedOptions() {
      return _.merge({
        id: `chart-${uuid()}`,
        chart: {
          animation: true,
          type: 'line',
          backgroundColor: this.isDark ? '#222' : '#eee',
          spacing: [40, 20, 20, 20],
          height: 400,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: this.isDark ? '#eee' : '#222',
          },
        },
        title: {
          text: '',
        },
        plotOptions: {
          column: {},
          series: {
            marker: {
              enabled: false,
            },
          },
        },
        xAxis: {
          tickLength: 0,
          minorTickLength: 0,
          crosshair: [true],
          labels: {
            enabled: true,
            style: {
              color: this.isDark ? '#eee' : '#222',
            },
          },
          min: this.range.start.valueOf(),
          max: this.range.end.valueOf(),
          title: {
            text: '',
          },
          type: 'datetime',
        },
        yAxis: {
          gridLineColor: this.isDark ? '#555' : '#bbb',
          labels: {
            enabled: true,
            style: {
              color: this.isDark ? '#eee' : '#222',
            },
          },
          title: {
            text: '',
          },
          startOnTick: false,
          endOnTick: false,
        },
      }, this.options);
    },
  },
  data() {
    return {};
  },
  props: {
    options: {},
    range: {},
  },
})
</script>

<style scoped lang="scss">

</style>
