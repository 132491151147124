<template>
    <div class="border-neutral-200 dark:border-neutral-700 my-2 flex items-center"
         :class="[
           bordered ? 'border rounded p-3' : 'border-t first:border-t-0 p-2',
         ]"
    >

        <div class="flex-1">

            <div class="flex items-center justify-between">

                <div class="flex items-center gap-x-2.5">
                    <div class="font-semibold">{{ exercise.movement ? exercise.movement.name : 'Movement' }}</div>
                    <sup v-if="exercise.advanced">ADV</sup>
                </div>

                <div class="flex items-center gap-x-4">

                    <div v-if="exercise.duration">
                        <Icon icon="stopwatch"/>
                        {{ $timerValue(exercise.duration) }}
                    </div>

                    <div v-if="exercise.movement && exercise.movement.trackRepetitions && !sortable" class="flex">
                        <div class="font-semibold">{{ exercise.repetitions || 0 }}</div>
                        <div v-if="exercise.movement.trackRepetitionsPartial">
                            +{{ exercise.partialRepetitions || 0 }}
                        </div>
                    </div>

                </div>

            </div>

            <div class="flex items-center justify-between" v-if="exercise.movement">

                <div class="flex gap-x-2.5">

                    <div v-if="exercise.movement.trackResistance">

                        <div v-if="exercise.movement.resistanceType === 'band'">
                            {{
                                exercise.bands && exercise.bands.length ? exercise.bands.map(b => b.brandAndName).join(', ') : 'No Band Selected'
                            }}
                        </div>

                        <div v-else-if="weight">
                            {{ weight }}
                        </div>

                    </div>

                </div>

            </div>

            <Notes :notes="exercise.notes" />

        </div>

        <div class="drag-handle rounded border border-neutral-500 px-5 py-2 ml-4" v-if="sortable">
            <Icon icon="sort"/>
        </div>

    </div>
</template>

<script>
import {defineComponent} from 'vue';
import Notes from '@/components/Exercise/Notes.vue';

export default defineComponent({
  name: 'Row',
  components: {
    Notes,
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    weight() {
      if (!this.exercise.weight) {
        return null;
      }

      const displayUnit = this.weightDisplayUnit;
      const displayWeight = this.$convert(this.exercise.weight, 'kg').to(displayUnit);
      const displayFormat = this.user.systemOfMeasure === 'metric' ? '0,0.0' : '0,0';
      return `${this.$numeral(displayWeight).format(displayFormat)} ${displayUnit}`;
    },
    weightDisplayUnit() {
      return this.user.systemOfMeasure === 'metric' ? 'kg' : 'lb';
    },
  },
  props: {
    bordered: {
      default: false,
      required: false,
    },
    exercise: {
      required: true,
    },
    sortable: {
      default: false,
      required: false,
    }
  },
});
</script>

<style scoped lang="scss">

</style>
