<template>
    <input type="number"
           class="input w-full block flex-1 p-2 rounded placeholder:text-gray-400 bg-transparent border border-neutral-300 dark:border-neutral-500"
           :value="formattedValue"
           @input="handleInput"
           @blur="handleBlur"
           :max="max"
           :min="min"
           :step="step"
    />
</template>

<script>
import convert from 'convert';

export default {
  name: 'NumberInputUnitAware',
  computed: {
    formattedValue() {
      return parseFloat(this.localValue) ? parseFloat(parseFloat(this.localValue).toFixed(3)) : null;
    },
    localAndModelValuesEqual() {

      if (this.modelValue === null && this.localValue === null) {
        return true;
      }

      let parsedLocalValue = parseFloat(this.localValue);
      let parsedModelValue = parseFloat(this.modelValue);

      if (parsedLocalValue === parsedModelValue) {
        return true;
      }

      return false;
    }
  },
  data() {
    return {
      localValue: null,
    };
  },
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleInput(event) {
      this.localValue = event.target.value;
    },
  },
  props: {
    max: {
      default: null,
      required: false,
      type: Number,
    },
    min: {
      default: null,
      required: false,
      type: Number,
    },
    step: {
      default: null,
      required: false,
      type: Number,
    },
    modelValue: {
      required: true,
    },
    baseUnit: {
      required: true,
      type: String,
    },
    displayUnit: {
      required: true,
      type: String,
    },
  },
  watch: {
    localValue: {
      handler() {
        if (!this.localAndModelValuesEqual) {
          this.$emit('update:modelValue', this.localValue ? convert(parseFloat(this.localValue), this.displayUnit).to(this.baseUnit) : null);
        }
      },
    },
    modelValue: {
      immediate: true,
      handler() {
        if (!this.localAndModelValuesEqual) {
          this.localValue = parseFloat(this.modelValue) ? convert(parseFloat(this.modelValue), this.baseUnit).to(this.displayUnit) : null;
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
