<template>

    <div class="min-w-[320px]" :class="[
      isMobile && !$authenticated() ? 'pt-20 mb-24' : null,
      isMobile && $authenticated() ? 'pt-0 mb-24' : null,
      !isMobile && $authenticated() ? 'ml-72' : null,
      !isMobile && !$authenticated() ? 'mt-20' : null,
    ]">
        <WebsocketManager v-if="$authenticated()" />

        <LogRocket />

        <Maintenance />

        <ImageEditor/>

        <Dialog/>

        <Snackbar/>

        <Navbar v-if="!$authenticated()"/>

        <Sidebar v-if="$authenticated() && !isMobile"/>

        <router-view :key="$route.fullPath"/>

        <MobileMenu v-if="isMobile" />

        <MobileTabs v-if="$authenticated() && isMobile"/>

<!--        <Streamr />-->

    </div>

</template>

<script>
import {mapActions} from 'vuex';

import Dialog from '@/components/Dialog.vue';
import ImageEditor from '@/components/ImageEditor.vue';
import Maintenance from '@/components/Maintenance.vue';
import MobileMenu from '@/components/MobileMenu';
import MobileTabs from '@/components/MobileTabs.vue';
import Navbar from '@/components/Navbar';
import Streamr from '@/components/Streamr.vue';
import Sidebar from '@/components/Sidebar.vue';
import Snackbar from '@/components/Snackbar.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher';
import WebsocketManager from '@/components/WebsocketManager.vue';
import LogRocket from '@/components/LogRocket.vue';

const isMobileThreshold = 768;

export default {
  name: 'App',
  components: {
    LogRocket,
    Dialog,
    ImageEditor,
    Maintenance,
    MobileMenu,
    MobileTabs,
    Navbar,
    Sidebar,
    Snackbar,
    Streamr,
    ThemeSwitcher,
    WebsocketManager,
  },
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    settings() {
      return this.$store.state.system.settings;
    },
  },
  async created() {
    this.setIsMobile(window.innerWidth < isMobileThreshold);
    window.addEventListener('resize', this.windowResize);

    this.systemStatus();
    this.heartbeat = setInterval(this.systemStatus, 60 * 1000);
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize);
    clearInterval(this.heartbeat);
  },
  data() {
    return {
      heartbeat: null,
    };
  },
  methods: {
    ...mapActions({
      setIsMobile: 'ui/isMobile',
      systemStatus: 'system/index',
    }),
    windowResize(event) {
      let windowWidth = event.target.innerWidth;
      this.setIsMobile(windowWidth < isMobileThreshold);
    }
  },
  watch: {
    'settings.theme': {
      immediate: true,
      handler() {
        if (this.settings.theme === 'dark') {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      },
    },
  },
};
</script>

<style>

</style>
