<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title>Sign Up</Title>

                <Field>
                    <Label>First Name *</Label>
                    <TextInput id="firstName" v-model="form.fields.firstName" placeholder="First Name"/>
                    <FormError :form="form" field="firstName"/>
                </Field>

                <Field>
                    <Label>Last Name *</Label>
                    <TextInput id="lastName" v-model="form.fields.lastName" placeholder="Last Name"/>
                    <FormError :form="form" field="lastName"/>
                </Field>

                <Field>
                    <Label>Email *</Label>
                    <TextInput id="email" v-model="form.fields.email" placeholder="Email"/>
                    <FormError :form="form" field="email"/>
                </Field>

                <Field>
                    <Label>Password *</Label>
                    <PasswordInput id="password" v-model="form.fields.password"/>
                    <FormError :form="form" field="password"/>
                </Field>

                <Field>
                    <Label>Password Confirmation *</Label>
                    <PasswordInput id="passwordConfirmation" v-model="form.fields.passwordConfirmation"/>
                    <FormError :form="form" field="passwordConfirmation"/>
                </Field>

                <Button id="signUpButton" primary block @click="handleSignUp" class="mb-3">
                    Start My Free Trial
                </Button>

                <Help class="text-center">No Payment Method Required</Help>

                <div class="text-center my-5">
                    - or -
                </div>

                <Button secondary outlined block @click="handleLogIn">
                    Log In
                </Button>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import {isLocal} from '@/helpers.js';

export default defineComponent({
  name: 'SignUp',
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (isLocal()) {
      this.form.fields = {
        firstName: 'Nick',
        lastName: 'Hough',
        email: `nick+${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}@portonefive.com`,
        password: 'secret',
        passwordConfirmation: 'secret',
      }
    }

    if (this.$route.query.email) {
      this.form.fields.email = this.$route.query.email;
    }
  },
  data() {
    return {
      form: new Form({
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirmation: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authSignUp: 'auth/signUp',
    }),
    handleLogIn() {
      this.$router.push(window.app.findRouteByName('auth/login'));
    },
    handleSignUp() {

      if (this.loading) {
        return;
      }

      this.form.errors.clear();

      this.loading = true;

      return this.authSignUp({
        ...this.form.fields,
      }).then(() => {

        if (this.user.isV2() && !this.user.isV2ImportComplete()) {

          window.app.snackbar('Welcome to V3!');

          this.$router.push(window.app.findRouteByName('my/import'));

        } else {

          window.app.snackbar({
            message: 'Sign Up Successful!',
            type: 'success',
          });

          this.$router.push(window.app.findRouteByName('my/onboard'));
        }

      }).catch((error) => {
        window.app.snackbarError(error);
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
</script>

<style scoped>

</style>
