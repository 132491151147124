import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {user} = payload;

  return new Menu({
    title: 'Account',
    open: true,
    menuItems: [
      // {
      //   route: window.app.findRouteByName('my/modules'),
      // },
      {
        route: window.app.findRouteByName('my/profile'),
      },
      {
        route: window.app.findRouteByName('my/security'),
      },
      // {
      //   route: window.app.findRouteByName('my/switch'),
      // },
      {
        route: window.app.findRouteByName('my/billing'),
      },
      {
        route: window.app.findRouteByName('auth/logout'),
      },
    ],
  });
};
