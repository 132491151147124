import {
  Log,
  LogEntryItem,
  LogEntryService as Service,
  Model,
} from '@/internal';

export default class LogEntry extends Model {

  static get key() {
    return 'logEntry';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      loggedAt: {
        type: Date,
      }
    };
  }

  get log() {
    return this.belongsTo(Log);
  }

  get logEntryItems() {
    return this.hasMany(LogEntryItem);
  }
}
