<template>
    <div class="mobile-tabs fixed bottom-0 left-0 right-0 flex justify-around items-center gap-x-2 bg-neutral-200 dark:bg-black p-2 border-t border-t-neutral-500"
         :class="[
            $isStandalone ? '!pb-6': null,
         ]"
    >
        <MobileDrawer :active="logEntryCreateMenuActive">

            <div class="-my-1">

                <UpdateAvailable class="mt-1.5" />

                <Button primary outlined block class="my-3" @click="handleWorkoutCreate">
                    Workout
                </Button>

                <div v-on-click-outside="handleLogEntryCreateMenuClose">
                    <template v-for="log in logs">
                        <Button primary outlined block class="my-3" @click="handleLogEntryCreate(log)">
                            {{ log.name }}
                        </Button>
                    </template>
                </div>

            </div>

        </MobileDrawer>

<!--        <div class="absolute border-t border-t-neutral-500 bottom-full w-full bg-white dark:bg-neutral-900 px-3 py-1" v-if="logEntryCreateMenuActive">-->

<!--            <UpdateAvailable class="mt-1.5" />-->

<!--            <Button primary outlined block class="my-3" @click="handleWorkoutCreate">-->
<!--                Workout-->
<!--            </Button>-->

<!--            <div v-on-click-outside="handleLogEntryCreateMenuClose">-->
<!--                <template v-for="log in logs">-->
<!--                    <Button primary outlined block class="my-3" @click="handleLogEntryCreate(log)">-->
<!--                        {{ log.name }}-->
<!--                    </Button>-->
<!--                </template>-->
<!--            </div>-->
<!--        </div>-->

        <template v-for="tab in tabs">
            <div class="mobile-tab flex-1 flex flex-col items-center justify-center rounded py-3"
                 @click="handleTabClick(tab)">
                <Icon class="text-2xl" :icon="tab.icon"/>
                <div class="text-xs mt-3 uppercase">{{ tab.label }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {vOnClickOutside} from '@vueuse/components'
import UpdateAvailable from '@/components/UpdateAvailable.vue';
import MobileDrawer from '@/components/MobileDrawer.vue';

export default defineComponent({
  name: 'MobileTabs',
  components: {MobileDrawer, UpdateAvailable},
  directives: {
    onClickOutside: vOnClickOutside,
  },
  computed: {
    logs() {
      return this.$store.getters['log/all'] || [];
    },
    tabs() {

      let tabs = [];

      // tabs.push({
      //   icon: 'dashboard',
      //   label: 'Dashboard',
      //   route: window.app.findRouteByName('dashboard')
      // });

      tabs.push({
        icon: 'arrow-trend-up',
        label: 'Analytics',
        route: window.app.findRouteByName('analytics/index')
      });

      tabs.push({
        icon: 'scroll',
        label: 'Logs',
        click: this.handleLogEntryCreateMenuOpen,
      });

      tabs.push({
        icon: 'bars',
        label: 'More',
        click: this.handleMobileMenuClick,
      });

      return tabs;
    },
  },
  created() {
    if (!this.logs.length) {
      this.logIndex();
    }
  },
  data() {
    return {
      logEntryCreateMenuActive: false,
    };
  },
  methods: {
    ...mapActions({
      logIndex: 'log/index',
      setMobileMenuActive: 'ui/mobileMenuActive',
    }),
    handleLogEntryCreate(log) {
      this.logEntryCreateMenuActive = false;
      this.$router.push(`/log/${log.key}`);
    },
    handleLogEntryCreateMenuOpen() {
      if (this.logs.length) {
        this.logEntryCreateMenuActive = true;
      } else {
        this.$router.push(window.app.findRouteByName('workout/index'));
      }
    },
    handleLogEntryCreateMenuClose() {
      this.logEntryCreateMenuActive = false;
    },
    handleMobileMenuClick() {
      this.setMobileMenuActive(!this.mobileMenuActive);
    },
    handleTabClick(tab) {
      if (tab.click) {
        tab.click();
      } else if (tab.route) {
        this.$router.push(tab.route);
      }
    },
    handleWorkoutCreate() {
      this.$router.push({
        name: 'workout/index',
      });
    },
  },
})
</script>

<style scoped lang="scss">
.mobile-tabs {

}
</style>
