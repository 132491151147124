import Index from '@/views/Wellness/Index';

const routes = [
  {
    name: 'wellness/index',
    path: '/wellness',
    component: Index,
    meta: {
      icon: 'battery',
      title: 'Wellness',
    },
  },
];

export default routes;
