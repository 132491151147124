import Form from '@/views/Band/Form.vue';
import Index from '@/views/Band/Index';

const routes = [
  {
    name: 'band/index',
    path: '/band',
    component: Index,
    meta: {
      icon: 'ring',
      title: 'Bands',
    },
  },
  {
    name: 'band/create',
    path: '/band/create',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Add Band',
    },
  },
  {
    name: 'band/edit',
    path: '/band/:bandUUID/edit',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Edit Band',
    },
  },
];

export default routes;
