<template>
    <AutocompleteInput :modelValue="modelValue"
                       @update:modelValue="handleInput"
                       :options="options"
                       placeholder="Search by City"
    />
</template>

<script>
import timezoneData from 'moment-timezone/data/meta/latest.json';

export default {
  name: 'TimezoneInput',
  computed: {
    options() {
      const zones = [];

      for (let [key, value] of Object.entries(timezoneData.zones)) {
        let label = value.name.replace(/_/g, ' ');
        zones.push({
          label,
          value: value.name,
        });
      }

      return zones;
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    modelValue: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
