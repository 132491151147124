<template>
    <ResourceList addLabel="Add"
                  :addRoute="!hideAddButton ? addRoute : null"
                  :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  :title="title"
    />
</template>

<script>
import {LogEntry} from '@/internal';
import LogEntryResourceListItem from '@/components/LogEntry/ResourceListItem.vue';

export default {
  name: 'LogEntryResourceList',
  data() {
    return {
      params: {
        include: [],
      },
      resource: LogEntry,
    };
  },
  props: {
    addRoute: {
      default() {
        return null;
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return LogEntryResourceListItem;
      },
      required: false,
    },
    title: {

    },
  },
};
</script>

<style scoped lang="scss">

</style>
