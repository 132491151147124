import MyService from './MyService';

import {
  ResourceStore,
} from '@/internal';

const service = new MyService();

export default {
  key: 'my',
  namespaced: true,
  state: {
    authenticated: false,
    userId: null,
  },
  actions: {
    destroy({commit, dispatch}, payload = {}) {
      return service.destroy(payload).then((response) => {
        return dispatch('auth/logout', null, {root: true});
      });
    },
    import({commit, dispatch}, payload = {}) {
      return service.import(payload);
    },
    onboard({commit, dispatch}, payload = {}) {
      return service.onboard(payload);
    },
    password({commit, dispatch}, payload = {}) {
      return service.password(payload);
    },
    show({commit, dispatch}, payload = {}) {
      return service.show(payload).then(response => {
        if (response) {
          commit('auth/userId', response.data.data.id, { root: true });
          return ResourceStore.processData('user', commit, response.data);
        }
      });
    },
    stripeCheckout({commit, dispatch}, payload = {}) {
      return service.stripeCheckout(payload);
    },
    stripePortal({commit, dispatch}, payload = {}) {
      return service.stripePortal(payload);
    },
    update({commit, dispatch}, payload = {}) {
      return service.update(payload).then(response => {
        return ResourceStore.processData('user', commit, response.data)
      });
    },
    x3LogConnect({commit, dispatch}, payload = {}) {
      return service.x3LogConnect(payload).then(response => {
        return ResourceStore.processData('user', commit, response.data)
      });;
    },
    x3LogDisconnect({commit, dispatch}, payload = {}) {
      return service.x3LogDisconnect(payload).then(response => {
        return ResourceStore.processData('user', commit, response.data)
      });;
    },
  },
};
