import {
  Model,
  FileService as Service
} from '@/internal';

export default class File extends Model {

  static get key() {
    return 'file';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

}
