<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm" @click="noSleepEnable">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <div v-if="error">
                    <Instruction type="error">
                        There's been an error.
                    </Instruction>

                    <Button primary block @click="handleReload">
                        Reload App
                    </Button>
                </div>

                <template v-else>

                    <div v-if="!format && !loading">
                        <Button primary outlined block @click="handleFormatSet('blank')">
                            Blank
                        </Button>
                        <div class="text-center my-3">
                            - or -
                        </div>
                        <Label>Templates</Label>
                        <template v-for="template in templates">
                            <Button primary outlined block @click="handleTemplateSet(template)" class="my-3">
                                {{ template.name }}
                            </Button>
                        </template>
                    </div>

                    <div v-show="showForm">

                        <Field>
                            <Label>Date</Label>
                            <DateTimeInput v-model="form.fields.workoutAt"/>
                        </Field>

                        <Field>
                            <Label>Timer</Label>
                            <Timer v-model="form.fields.duration"/>
                        </Field>

                        <Field v-if="template">
                            <Label>Template</Label>
                            <p class="font-bold">{{ template.name }}</p>
                        </Field>


                        <Field>
                            <ExercisesInput v-model="form.fields.exercises"
                                            :sorting="sorting"
                                            @toggle="sorting = !sorting"
                            />
                        </Field>

                        <Field>
                            <Label>Progress Photo</Label>
                            <FileInput v-model="form.fields.fileId" block/>
                        </Field>

                        <Field>
                            <Label>Notes</Label>
                            <TextareaInput v-model="form.fields.notes"/>
                        </Field>

                        <Controls>

                            <template v-slot:left>
                                <Button danger outlined @click="handleDelete" v-if="workout">
                                    Delete
                                </Button>
                            </template>

                            <template v-slot:right>
                                <div class="flex items-center justify-between gap-4">
                                    <Button primary @click="handleSave(false)">
                                        Save
                                    </Button>
                                </div>
                            </template>
                        </Controls>

                    </div>

                </template>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue';
import {
  mapActions,
} from 'vuex';
import {Form} from '@/internal.js';
import Timer from '@/components/Timer.vue';
import moment from 'moment-timezone';
import Row from '@/components/Exercise/Row.vue';
import {uuid} from '@/helpers.js';
import ExercisesInput from '@/components/ExercisesInput.vue';
import NoSleep from 'nosleep.js';
import Subtitle from '@/components/Subtitle.vue';
import Instruction from '@/components/Instruction.vue';

const defaultParams = {
  include: [
    'exercises',
    'file',
    'template',
  ],
};

export default defineComponent({
  name: 'Form',
  components: {
    Instruction,
    Subtitle,
    ExercisesInput,
    Row,
    Timer,
  },
  computed: {
    bands() {
      return this.$store.getters['band/all'] || [];
    },
    movements() {
      return this.$store.getters['template/all'] || [];
    },
    pageTitle() {
      return this.workout ? 'Edit Workout' : 'New Workout';
    },
    showForm() {

      if (this.loading) {
        return false;
      }

      return this.format === 'blank' || this.format === 'template' && this.template;
    },
    template() {
      return this.form.fields.templateId ? this.templates.find(template => template.id === this.form.fields.templateId) : null;
    },
    templateMovements() {

      let movements = this.template.movements || [];

      return this.template ? this.template.movementRank.map((item) => {

        let movement = {
          ...movements.find(movement => movement.id === item.id),
        };

        movement.rank = item.rank;
        return movement;
      }) : [];
    },
    templates() {
      return this.$store.getters['template/all'].sort((a, b) => a.name > b.name ? 1 : -1) || [];
    },
    timezone() {
      return this.user.timezone || moment.tz.guess();
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    workout() {
      return this.$route.params.workoutUUID ? this.$store.getters['workout/findBy'](this.$route.params.workoutUUID, 'uuid') : null;
    },
  },
  async created() {

    await this.loadMovements();
    await this.loadBands();
    await this.loadTemplates();

    if (this.$route.params.workoutUUID) {
      this.format = 'blank';
      this.loadWorkout();
    } else {
      this.form.fields.workoutAt = moment(new Date(), this.timezone).format('YYYY-MM-DDTHH:mm');
    }
  },
  destroyed() {
    this.noSleepDisable();
  },
  data() {
    return {
      autoSaving: false,
      error: null,
      format: null,
      form: new Form({
        duration: null,
        exercises: [],
        fileId: null,
        notes: '',
        templateId: null,
        workoutAt: null,
      }),
      loading: false,
      noSleep: new NoSleep(),
      sorting: false,
    };
  },
  methods: {
    ...mapActions({
      bandIndex: 'band/index',
      movementIndex: 'movement/index',
      templateIndex: 'template/index',
      templateShow: 'template/show',
      workoutDestroy: 'workout/destroy',
      workoutShow: 'workout/show',
      workoutStore: 'workout/store',
      workoutUpdate: 'workout/update',
    }),
    fillForm() {

      this.form.fields = {
        duration: this.workout.duration,
        exercises: [
          ...this.workout.exercises.map((exercise) => {

            if (exercise.bands) {
              exercise.bandIds = exercise.bands.map(band => band.id);
            }

            return exercise;
          }),
        ],
        fileId: this.workout.file ? this.workout.file.id : null,
        notes: this.workout.notes,
        templateId: this.workout.template ? this.workout.template.id : null,
        workoutAt: moment(this.workout.workoutAt, this.timezone).format('YYYY-MM-DDTHH:mm'),
      };

    },
    async handleCancel() {

      if (this.workout) {
        await this.handleSave(false);
      }

      this.noSleepDisable();
      this.$router.push(window.app.findRouteByName('workout/index'));
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Workout',
        message: 'Are you sure you want to delete this workout?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.workoutDestroy({
                id: this.workout.uuid,
              }).then(() => {
                window.app.snackbar('Workout Deleted');
                this.selectedFile = null;
                this.noSleepDisable();
                this.$router.push(window.app.findRouteByName('workout/index'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleFormatSet(format) {
      this.format = format;
    },
    handleReload() {
      this.loading = true;
      window.location.replace('/workout');
    },
    handleSave(autoSaved = false) {

      if (this.loading || this.autoSaving) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
        params: {
          ...defaultParams,
        },
        autoSaved,
        workoutAt: moment.tz(this.form.fields.workoutAt, this.timezone).utc().toISOString(),
      };

      if (this.workout) {
        action = this.workoutUpdate;
        payload.id = this.workout.uuid;
      } else {
        action = this.workoutStore;
      }

      this.autoSaving = autoSaved;
      this.loading = true;

      return action(payload).then((id) => {

        if (autoSaved) {
          if (!this.$route.params.workoutUUID) {
            const workout = this.$store.getters['workout/findBy'](id, 'id');
            this.$router.push(`/workout/${workout.uuid}/edit`);
          } else {
            this.loading = false;
            return this.loadWorkout(true);
          }

        } else {

          window.app.snackbar({
            type: 'success',
            message: 'Workout Saved',
          });

          this.noSleepDisable();
          this.$router.push(window.app.findRouteByName('workout/index'));
        }

      }).catch((error) => {
        console.error(error);
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.autoSaving = false;
        this.loading = false;
      });
    },
    handleTemplateSet(template) {

      this.form.fields.templateId = template.id;

      let exercises = [];

      this.templateMovements.map((movement => {
        exercises.push({
          id: uuid(),
          bandIds: [],
          movementId: movement.id,
          rank: movement.rank,
        });
      }));

      this.form.fields.exercises = [
        ...exercises.sort((a, b) => a.rank > b.rank ? 1 : -1)
      ];
    },
    loadBands() {
      this.loading = true;
      return this.bandIndex().finally(() => {
        this.loading = false;
      });
    },
    loadMovements() {
      this.loading = true;
      return this.movementIndex().finally(() => {
        this.loading = false;
      });
    },
    loadTemplates() {
      this.loading = true;
      return this.templateIndex({
        params: {
          include: [
            'movements',
          ],
        },
      }).finally(() => {
        this.loading = false;
      });
    },
    loadWorkout(inBackground = false) {

      if (this.loading) {
        return;
      }

      this.loading = !inBackground;

      return this.workoutShow({
        id: this.$route.params.workoutUUID,
        params: {
          ...defaultParams,
        },
      }).then(this.fillForm).catch((error) => {

        if (error.response.status === 403) {

          window.app.snackbar({
            message: 'Error: Reloading App',
            type: 'error',
          });

          setTimeout(() => {
            window.location.replace('/workout');
          }, 1000);

        } else {
          console.error(error);
        }

        this.error = error;

      }).finally(() => {
        this.loading = false;
      })
    },
    noSleepDisable() {
      if (this.noSleep.isEnabled) {
        try {
          this.noSleep.disable();
        } catch (error) {
        }
      }
    },
    async noSleepEnable() {
      if (!this.noSleep.isEnabled) {
        try {
          await this.noSleep.enable();
        } catch (error) {
        }
      }
    },
  },
  watch: {
    ['form.fields.exercises']: {
      deep: true,
      handler() {
        this.handleSave(true);
      },
    },
    format: {
      async handler() {
        if (this.format === 'blank') {
          this.handleSave(true);
        }
      },
    }
  }
})
</script>

<style scoped lang="scss">

</style>
