import {
  Menu,
} from '@/internal';

export default (payload = {}) => {

  const {user} = payload;

  const logs = user.logs || [];

  const logsMenuItems = [
    ...logs.sort((a, b) => a.name > b.name ? 1 : -1).map((log) => {
      return {
        icon: log.icon,
        title: log.name,
        route: {
          ...window.app.findRouteByName('log/show', [], {
            params: {
              logKey: log.key,
            },
          }),
        },
      };
    })
  ];

  return new Menu({
    title: 'Logs',
    open: true,
    menuItems: [
      {
        route: window.app.findRouteByName('workout/index'),
      },
      ...logsMenuItems,
    ],
  });
};
