import {
  Model,
  MovementService as Service,
  Workout
} from '@/internal';

export default class Movement extends Model {

  static get key() {
    return 'movement';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get workout() {
    return this.belongsTo(Workout);
  }
}
