<template>
  <div class="input h-10 w-full p-2 pr-3 rounded placeholder:text-gray-400 bg-transparent border border-transparent flex items-center justify-between">

      <div class="flex-1">
        <slot/>
    </div>

    <div class="flex items-center justify-between gap-x-3">

      <Tooltip label="Copy" v-if="clickToCopy">
        <span class="control text-input-click-to-copy" @click="handleCopy">
          <Icon icon="copy"/>
        </span>
      </Tooltip>

      <Tooltip label="Locked" v-if="locked">
        <span class="control fake-input-locked">
          <Icon icon="lock" />
        </span>
      </Tooltip>

    </div>

  </div>
</template>

<script>
import copy from 'copy-to-clipboard';

export default {
  name: 'FakeInput',
  methods: {
    handleCopy() {
      try {
        copy(this.$slots.default()[0].children.trim());
        window.app.snackbar({
          message: 'Copied to Clipboard',
        });
      } catch (e) {
        console.error(e);
        window.app.snackbar({
          message: 'Unable to Copy',
          type: 'is-danger',
        });
      }
    },
  },
  props: {
    clickToCopy: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    locked: {
      type: Boolean,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
