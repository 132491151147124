<template>
    <div class="flex items-center justify-between gap-x-4 gap-y-3 flex-col sm:flex-row">
        <DateTimeInput v-model="localValue.start" />
        <DateTimeInput v-model="localValue.stop" />
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import moment from 'moment-timezone';

export default defineComponent({
  name: 'StartStopInput',
  data() {
    return {
      localValue: {
        start: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
        stop: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
      },
    };
  },
  props: {
    modelValue: {
      required: true,
    },
  },
  watch: {
    localValue: {
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', this.localValue);
        }
      },
    },
    modelValue: {
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          if (this.modelValue) {
            this.localValue = this.modelValue;
          } else {
            this.$emit('update:modelValue', this.localValue);
          }
        }
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
