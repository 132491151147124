import Form from '@/views/Workout/Form.vue';
import Index from '@/views/Workout/Index';
import Share from '@/views/Workout/Share.vue';

const routes = [
  {
    name: 'workout/index',
    path: '/workout',
    component: Index,
    meta: {
      icon: 'bolt',
      title: 'Workouts',
    },
  },
  {
    name: 'workout/create',
    path: '/workout/create',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Add Workout',
    },
  },
  {
    name: 'workout/share',
    path: '/workout/:workoutUUID/share',
    component: Share,
    meta: {
      icon: 'bolt',
      title: 'Share Workout',
    },
  },
  {
    name: 'workout/edit',
    path: '/workout/:workoutUUID/edit',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Edit Workout',
    },
  },
];

export default routes;
