export default class Framework {

  constructor(config = {}) {
    this.booted = false;
    this.beforeBoot = null;
    this.components = {};
    this.globalProperties = [];
    this.plugins = [];
    this.vueServiceProviders = [];
    this.router = null;
    this.routerBuilder = null;
    this.store = null;
    this.vueCreateApp = null;
    this.vueConfig = {};
    this.vueRootComponent = null;
    this.userVuexAction = config.userVuexAction || 'auth/userShow';

    this.init();
  }

  init() {
    window.framework = this;
    window.app = {};
    window.app.d = console.log;
    window.app.vue = null;
    window.app.findRouteByName = (name, routesArg = [], payload = {}) => {

      if (!this.router) {
        console.error('router not set');
        return;
      }

      const routes = routesArg.length ? routesArg : this.router.options.routes;

      let route = null;

      routes.forEach(_route => {

        if (_route.name === name) {
          route = _route;
        }

        if (!route && _route.children) {
          route = window.app.findRouteByName(name, _route.children, payload);
        }

        return route;

      });

      if (!route) {
        console.error(`Could not find route with name: ${name}`);
      }

      if (payload.params) {
        route.params = {
          ...route.params,
          ...payload.params,
        };
      }

      if (payload.query) {
        route.query = {
          ...route.query,
          ...payload.query,
        };
      }

      return route;
    };
  }

  env(env = {}) {
    if (!window.app.env) {
      window.app.env = {};
    }

    window.app.env = {
      ...env,
    };
  }

  storage(storage = {}) {
    if (!window.app.storage) {
      window.app.storage = {};
    }

    window.app.storage = {
      ...storage,
    };
  }

  auth(auth = {}) {
    if (!window.app.auth) {
      window.app.auth = {};
    }

    window.app.auth = {
      ...auth,
    };
  }

  theme(theme = {}) {
    if (!window.app.theme) {
      window.app.theme = {};
    }

    window.app.theme = {
      ...theme,
    };
  }

  addHelper(key, helper) {
    window.app[key] = helper;
  }

  addVueServiceProvider(ServiceProvider, options = {}) {
    this.vueServiceProviders.push({
      ServiceProvider,
      options,
    });
  }

  addComponent(component, name = '') {
    let componentName = name || component.name;

    if (!componentName) {
      throw new Error('Missing component name');
    }

    this.components[componentName] = component;
  }

  addGlobalProperty(key, value) {
    this.globalProperties.push({
      key,
      value,
    });
  }

  addPlugin(plugin, config = {}) {
    this.plugins.push({
      plugin,
      config,
    });
  }

  addStore(store) {
    this.store = store;
  }

  addRouter(router, builder) {
    this.router = router;
    this.routerBuilder = builder;
  }

  loadComponents() {
    for (const [key, value] of Object.entries(this.components)) {
      window.app.vue.component(key, value);
    }
  }

  loadGlobalProperties() {
    this.globalProperties.forEach((property) => {
      window.app.vue.config.globalProperties[`$${property.key}`] = property.value;
    });
  }

  loadPlugins() {
    this.plugins.forEach((plugin) => {
      window.app.vue.use(plugin.plugin, plugin.config);
    });
  }

  loadServiceProviders() {

    if (this.vueServiceProviders.length) {
      this.vueServiceProviders.forEach(({ServiceProvider, options}) => {

        if (ServiceProvider.prototype.constructor) {

          const serviceProvider = new ServiceProvider({
            framework: this,
            options,
          });

          serviceProvider.boot();
        }

      });
    }

    // React Native ServiceProviders?

  }

  resetRouter() {
    this.router = this.routerBuilder.resetRouter();
  }

  addVue(createApp, RooComponent, config = {}) {
    this.vueCreateApp = createApp;
    this.vueRootComponent = RooComponent;
    this.vueConfig = config;
  }

  async boot() {

    this.loadServiceProviders();

    if (this.beforeBoot) {
      await this.beforeBoot();
    }

    if (!this.vueCreateApp) {
      console.error('framework.vueCreateApp not set');
      return;
    }

    window.app.vue = this.vueCreateApp(this.vueRootComponent, this.vueConfig);

    this.loadPlugins();

    this.loadComponents();

    this.loadGlobalProperties();

    window.app.vue.use(this.store);

    window.app.vue.$store = this.store; // This used to happen automatically in Vue 2. Not sure what changed. - NBH 01/12/24

    window.app.vue.use(this.router);

    window.app.vue.mount('#app');

    this.booted = true;
  }
}
