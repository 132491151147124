<template>
    <Page>
        <Container>
            <Loading v-if="loading" />
            <div class="mx-auto max-w-sm">
                <Title>Admin</Title>

                <table class="w-full" v-if="data">
                    <tr>
                        <td>Total Users</td>
                        <td class="text-right">{{data.total_users}}</td>
                    </tr>
                    <tr>
                        <td>Subscriptions</td>
                        <td class="text-right">{{data.subscriptions}}</td>
                    </tr>
                    <tr>
                        <td>ARR</td>
                        <td class="text-right">{{$numeral(data.arr).format('$0,0')}}</td>
                    </tr>
                    <tr>
                        <td>Active Trials</td>
                        <td class="text-right">{{data.active_trials}}</td>
                    </tr>
                </table>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';

export default defineComponent({
  name: 'Index',
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  methods: {
    ...mapActions({
      adminIndex: 'admin/index',
    }),
    loadData() {
        this.loading = true;
        return this.adminIndex().then((response) => {
          this.data = response.data.data;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
