import {
  ResourceService,
} from '@/internal';

export default class LogEntryService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'log-entry';
  }
}
