<template>

</template>

<script>
import {defineComponent} from 'vue'
// import {StreamrClient} from 'streamr-client';

export default defineComponent({
  name: 'Streamr',
  async created() {
    // this.streamr = await new StreamrClient({
    //   auth: {
    //     privateKey: window.app.env.streamrPrivateKey,
    //   },
    // });
    //
    // this.stream = this.streamr.subscribe(window.app.env.streamrStreamId, (message, metadata) => {
    //   window.app.emit('streamr', {
    //     message,
    //     metadata,
    //   });
    // });
  },
  destroyed() {
    // this.streamr.unsubscribe(window.app.env.streamrStreamId);
  },
  data() {
    return {
      streamr: null,
      stream: null,
    }
  },
})
</script>

<style scoped lang="scss">

</style>
