import {
  ApiService,
} from '@/internal';

export default class AdminService extends ApiService {

  index(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: '/admin',
      params: this.prepareParams(params),
    });
  }

}
