<template>
    <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 flex items-center justify-between cursor-pointer"
         @click="handleTemplateEdit">
        <div class="p-3 w-full">
            <div>
                {{ template.name }}
            </div>
            <div class="text-xs truncate text-neutral-500 w-full">
                {{template.movements.map(movement => movement.name).join(', ')}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    template() {
      return this.resource;
    },
  },
  methods: {
    handleTemplateEdit() {
      this.$router.push(`/template/${this.template.uuid}/edit`);
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
