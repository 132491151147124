<template>

    <Loading v-if="loading"/>

    <template v-else>
        <template v-for="(band, i) in selectedBands">
            <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-3 flex items-center justify-between" v-if="band">
                <div class="flex items-center p-3">
                    <div class="h-8 w-3 border border-neutral-800 dark:border-neutral-100"
                         :style="`background: ${band.color};`"></div>
                    <div class="ml-3">
                        {{ band.brandAndName }}
                    </div>
                </div>
                <div class="px-4 cursor-pointer flex items-center justify-center" @click="handleBandRemove(i)">
                    <Icon icon="circle-minus" class="text-xl"/>
                </div>
            </div>
        </template>

        <Dropdown class="mb-3">
            <template v-slot:toggle>
                <Button block primary outlined>
                    Add Band
                </Button>
            </template>
            <template v-slot:dropdown>
                <div class="max-h-56 w-full overflow-auto scroll-bar-hidden">
                    <template v-for="band in bands">
                        <div class="flex items-center justify-between px-1 cursor-pointer" @click="handleBandAdd(band)">
                            <div class="flex items-center p-3">
                                <div class="h-12 w-3 border border-neutral-700 dark:border-neutral-300" :style="`background: ${band.color};`"></div>
                                <div class="ml-3">
                                    <div>{{ band.brandAndName }}</div>
                                    <Help>{{ band.resistanceFormatted(user.systemOfMeasure) }}</Help>
                                </div>
                            </div>
                            <div class="px-4 cursor-pointer flex items-center justify-center">
                                <Icon icon="circle-plus" class="text-xl"/>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </Dropdown>

        <Button block secondary outlined class="flex-1" v-if="lastExercise && lastExercise.bands && !selectedBands.length"
                @click="handleBandsUsePrevious">
            Use Prev Bands
        </Button>

    </template>

</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';

export default defineComponent({
  name: 'BandsInput',
  computed: {
    allBands() {
      return this.$store.getters['band/all'] || [];
    },
    bands() {
      return this.allBands.filter(band => !band.deletedAt) || [];
    },
    selectedBands() {
      return this.localValue.map((bandId) => {
        return this.allBands.find(band => band.id === bandId);
      });
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadBands();
  },
  data() {
    return {
      loading: false,
      localValue: [],
    };
  },
  methods: {
    ...mapActions({
      bandIndex: 'band/index',
    }),
    handleBandAdd(band) {
      this.localValue = [
        ...this.localValue,
        band.id,
      ];
    },
    handleBandRemove(index) {
      this.localValue.splice(index, 1);
    },
    handleBandsUsePrevious() {

      let lastExerciseBandIds = this.lastExercise.bands.map(band => band.id);
      let warn = false;

      lastExerciseBandIds = lastExerciseBandIds.filter((bandId) => {
        if (!this.bands.map(band => band.id).includes(bandId)) {
            warn = true;
            return false;
        }

        return true;
      })

      if (warn) {
        window.app.snackbar({
          duration: 6000,
          message: 'Some previous bands could not be used, as they no longer exist.',
          type: 'error',
        })
      }

      this.localValue = [
        ...lastExerciseBandIds,
      ];
    },
    loadBands() {
      this.loading = true;
      return this.bandIndex().finally(() => {
        this.loading = false;
      });
    }
  },
  props: {
    lastExercise: {
      required: false,
    },
    modelValue: {
      required: true,
      type: Array,
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', this.localValue);
        }
      },
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.localValue = [
            ...this.modelValue,
          ];
        }
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
