<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <Field>
                    <Label>Name *</Label>
                    <TextInput v-model="form.fields.name" placeholder="Name"/>
                    <FormError :form="form" field="name"/>
                </Field>

                <Field>
                    <Label>Icon *</Label>
                    <FontAwesomeIconInput v-model="form.fields.icon"/>
                    <FormError :form="form" field="icon"/>
                </Field>

                <Field>
                    <Label>Items *</Label>
                    <LogItemsInput v-model="form.fields.logItems"/>
                    <FormError :form="form" field="logItems"/>
                </Field>

                <Controls>

                    <template v-slot:left>
                        <Button danger outlined @click="handleDelete" v-if="log">
                            Delete
                        </Button>
                    </template>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {Form} from '@/internal.js';
import {mapActions} from 'vuex';
import FontAwesomeIconInput from '@/components/FontAwesomeIconInput.vue';
import LogItemsInput from '@/components/LogItemsInput.vue';
import {parseBoolean, uuid} from '@/helpers.js';

export default defineComponent({
  name: 'Form',
  components: {
    FontAwesomeIconInput,
    LogItemsInput,
  },
  computed: {
    log() {
      return this.$route.params.logUUID ? this.$store.getters['log/findBy'](this.$route.params.logUUID, 'uuid') : null;
    },
    pageTitle() {
      return this.log ? 'Edit Log' : 'New Log';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {

    if (this.$route.params.logUUID) {
      this.loadLog();
    } else {
      await this.loadLogTemplates();
    }

    if (this.$route.query.template) {
      this.loadLogTemplate();
    }

  },
  data() {
    return {
      form: new Form({
        name: '',
        icon: '',
        logItems: [],
      }),
      loading: false,
      templates: [],
    };
  },
  methods: {
    ...mapActions({
      myShow: 'my/show',
      logDestroy: 'log/destroy',
      logShow: 'log/show',
      logStore: 'log/store',
      logTemplates: 'log/templates',
      logUpdate: 'log/update',
    }),
    fillForm() {
      this.form.fields = {
        ...this.form.fields,
        ...this.log,
        logItems: [
          ...this.log.logItems.filter(logItem => !logItem.deletedAt).map((logItem) => {
            return {
              id: logItem.id,
              name: logItem.name,
              uuid: logItem.uuid,
              input: logItem.input,
              multiple: logItem.multiple,
              config: {
                ...logItem.config,
              },
            };
          }),
        ],
      };
    },
    handleCancel() {
      this.$router.push(window.app.findRouteByName('log/index'));
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Log',
        message: 'Are you sure you want to delete this log?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.logDestroy({
                id: this.log.uuid,
              }).then(this.myShow).then(() => {
                window.app.snackbar('Log Deleted');
                this.selectedFile = null;
                this.$router.push(window.app.findRouteByName('log/index'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleSave() {

      if (this.loading) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
      };

      if (this.log) {
        action = this.logUpdate;
        payload.id = this.log.uuid;
      } else {
        action = this.logStore;
      }

      this.loading = true;
      action(payload).then(() => {
        window.app.snackbar({
          type: 'success',
          message: 'Log Saved',
        });
        this.$router.push({
          name: 'log/index',
        });
      }).then(this.myShow).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadLog() {
      this.loading = true;
      return this.logShow({
        id: this.$route.params.logUUID,
        params: {
          include: [
            'logItems',
          ],
        },
      }).then(this.fillForm)
        .catch((error) => {
          window.app.snackbarError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadLogTemplate() {

      const template = this.templates.find(template => template.key === this.$route.query.template);

      this.form.fields = {
        name: template.name,
        icon: template.icon,
        logItems: [
          ...template.log_items.filter(logItem => parseBoolean(this.$route.query[logItem.key])).map((logItem) => {
            logItem.uuid = uuid();
            return logItem;
          }),
        ],
      };
    },
    loadLogTemplates() {
      this.loading = true;
      return this.logTemplates().then((response) => {
        this.templates = [
          ...response.data.data.templates,
        ];
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
