<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">
                <Title class="mb-6">Modules</Title>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Modules',

})
</script>

<style scoped>

</style>
