<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title>Login</Title>

                <Field>
                    <Label>Email *</Label>
                    <TextInput v-model="form.fields.email" placeholder="Email"/>
                    <FormError :form="form" field="email"/>
                </Field>

                <Field>
                    <Label>Password *</Label>
                    <PasswordInput v-model="form.fields.password"/>
                    <FormError :form="form" field="password"/>
                </Field>

                <div class="text-right text-sm mb-5">
                    <router-link class="underline" to="/auth/forgot-password">Forgot your password?</router-link>
                </div>

                <Button primary block @click="handleLogIn">
                    Log In
                </Button>

                <div class="text-center my-5">
                    - or -
                </div>

                <Button secondary outlined block @click="handleSignUp">
                    Sign Up
                </Button>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {Form} from '@/internal.js';
import {mapActions} from 'vuex';
import {isLocal} from '@/helpers.js';

export default defineComponent({
  name: 'Login',
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (isLocal()) {
      this.form.fields = {
        email: 'nick@portonefive.com',
        password: '',
      }
    }
  },
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authLogin: 'auth/login',
    }),
    handleSignUp() {
      this.$router.push(window.app.findRouteByName('auth/sign-up'));
    },
    handleLogIn() {

      this.loading = true;

      return this.authLogin({
        ...this.form.fields,
      }).then(() => {

        if (this.user.isV2() && !this.user.isV2ImportComplete()) {

          window.app.snackbar('Welcome to V3!');

          this.$router.push(window.app.findRouteByName('my/import'));

        } else {

          window.app.snackbar('Welcome back!');

          this.$router.push(window.app.findRouteByName('dashboard'));

        }

      }).catch((error) => {
        console.log(error);
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
</script>

<style scoped>

</style>
