import {
  camelCase,
  snakeCase,
  startCase,
} from 'lodash';

import {
  v4 as uuidv4
} from 'uuid';

const env = (key = '') => {
  return import.meta.env[`VITE_${key}`] || null;
};

const isLocal = () => {
  return import.meta.env.VITE_APP_ENV === 'local';
};

const isMobile = () => {
  return window.innerWidth < 1200;
};

const parseBoolean = (truthyValue) => {
  return truthyValue === true || (typeof truthyValue === 'string' && truthyValue.toLowerCase() === 'true') || truthyValue === 1;
};

const randomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

const snakeCaseKeys = (object) => {

  let newObject = {};

  for (const [key, value] of Object.entries(object)) {
    newObject[snakeCase(key)] = value;
  }

  return newObject;
}

const titleize = (text) => {
  return startCase(camelCase(text));
};

const uuid = () => {
  return uuidv4();
}

export {
  env,
  isLocal,
  isMobile,
  parseBoolean,
  randomColor,
  snakeCaseKeys,
  titleize,
  uuid,
};
