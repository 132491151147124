<template>
    <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 overflow-hidden">

        <div class="dark:bg-neutral-700 bg-neutral-200 flex items-center justify-between p-3">

            <div>
                <span class="font-semibold">{{ logEntry.loggedAt.format('ddd MMM D, YYYY') }}</span>
            </div>

            <div class="flex items-center justify-between gap-x-5">
                <div class="cursor-pointer" @click="handleLogEntryEdit">
                    <Icon icon="edit" class="text-lg"></Icon>
                </div>
            </div>

        </div>

        <div>

            <template v-for="logEntryItem in logEntry.logEntryItems">

                <FilePreview :id="logEntryItem.value" v-if="logEntryItem.logItem.input === 'file'"/>

                <div class="flex items-center justify-between px-3 py-2"
                     v-else-if="logEntryItem.logItem.input === 'boolean'">
                    <div>
                        {{ logEntryItem.logItem.name }}
                    </div>
                    <div>
                        {{ $parseBoolean(logEntryItem.value) ? 'Yes' : 'No' }}
                    </div>
                </div>

                <div class="flex items-center justify-between px-3 py-2"
                     v-else-if="logEntryItem.logItem.input === 'start-stop'">
                    <div>
                        {{ logEntryItem.logItem.name }}
                    </div>
                    <div v-html="startEnd(logEntryItem)"></div>
                </div>

                <div class="flex items-center justify-between px-3 py-2" v-else>
                    <div>
                        {{ logEntryItem.logItem.name }}
                    </div>
                    <div>
                        {{ logEntryItem.value }}
                    </div>
                </div>

            </template>

        </div>

    </div>
</template>

<script>
import ExerciseRow from '@/components/Exercise/Row.vue';
import FileInput from '@/components/FileInput.vue';
import FilePreview from '@/components/FilePreview.vue';
import moment from 'moment-timezone';

export default {
  name: 'ResourceListItem',
  components: {FilePreview, FileInput, ExerciseRow},
  computed: {
    logEntry() {
      return this.resource;
    },
    timezone() {
      return this.user.timezone || moment.tz.guess();
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    handleLogEntryEdit() {
      this.$router.push(`/log/${this.logEntry.log.key}/log-entry/${this.logEntry.uuid}/edit`);
    },
    startEnd(logItemEntry) {

      const config = logItemEntry.logItem.config;
      const value = logItemEntry.value;

      const start = moment(value.start, 'YYYY-MM-DDTHH:mm');
      const stop = moment(value.stop, 'YYYY-MM-DDTHH:mm');
      const diff = moment.duration(stop.diff(start, 'hours'), 'hours').humanize();

      let response = '';

      if (config.rendering === 'timestamps' || config.rendering === 'both') {
        response = `${start.format('YYYY-MM-DDTHH:mm')}<br />
                    ${stop.format('YYYY-MM-DDTHH:mm')}`;
      }

      if (config.rendering === 'both') {
        response += `<br />`;
      }

      if (config.rendering === 'duration' || config.rendering === 'both') {
        return response += `${diff}`;
      }

      return response;
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
