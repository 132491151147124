<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">
                <Title class="mb-6">Dashboard</Title>
<!--                <UnderConstruction />-->
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import UnderConstruction from '@/components/UnderConstruction.vue';

export default defineComponent({
  name: 'Home',
  components: {UnderConstruction},
  created() {
    this.$router.push(window.app.findRouteByName('workout/index'));
  },
})
</script>

<style scoped lang="scss">

</style>
