<template>
    <div class="fixed inset-0 bg-neutral-100 text-neutral-950 dark:bg-neutral-950 dark:text-neutral-100 z-50 flex items-center justify-center" v-if="maintenanceMode">
        <div class="mx-auto max-w-sm text-center">
            <Logo />
            <p class="mt-6">
                We're down for maintenance.<br />
                We'll be back shortly.
            </p>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import Logo from '@/components/Logo.vue';

export default defineComponent({
  name: 'Maintenance',
  components: {Logo},
  computed: {
    maintenanceMode() {
      return this.$store.state.system.maintenance;
    },
  },
})
</script>

<style scoped lang="scss">

</style>
