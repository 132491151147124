import {
  LogItemService as Service,
  Model,
} from '@/internal';

export default class LogItem extends Model {

  static get key() {
    return 'logItem';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }
}
