<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">

                <Loading v-if="loading" />

                <template v-if="complete">

                    <Title>Onboarding Complete</Title>

                    <Paragraph>
                        You're all set. All the objects you selected have been created.
                    </Paragraph>

                    <div class="flex items-center justify-center mt-6">
                        <Button primary @click="handleWorkoutCreate">
                            Log My First Workout
                        </Button>
                    </div>

                </template>

                <template v-else>

                    <Title>Welcome to V3ME!</Title>

                    <Paragraph>
                        To help get your account up and running, we need to do a little setup. Below you'll
                        find some items we can create for you to get your account ready to use.
                    </Paragraph>

                    <Instruction type="warning" v-if="!user.isV2()">
                        <Paragraph>
                            If you are an <b class="font-bold">existing X3 Log </b>user and you're seeing this screen,
                            that means your new V3ME account didn't get connected to your X3 Log account.
                        </Paragraph>
                        <Paragraph>
                            You can manually connect them on your
                            <router-link class="underline" to="/my/profile">Profile</router-link>
                            page.
                        </Paragraph>
                    </Instruction>

                    <Subtitle>Bands</Subtitle>

                    <Box>
                        <div class="-my-1.5">
                            <template v-for="bandBrand in bands">
                                <div>

                                    <Subtitle class="mb-3">{{ bandBrand.name }}</Subtitle>

                                    <template v-for="band in bandBrand.bands">

                                        <div class="flex items-center justify-between">

                                            <div class="flex items-center my-2">
                                                <div class="h-4 w-4 border border-neutral-800 dark:border-neutral-100 rounded-full"
                                                     :style="`background: ${band.color};`"></div>
                                                <div class="ml-3">
                                                    {{ band.name }}
                                                </div>
                                            </div>

                                            <Switch v-model="band.import"/>

                                        </div>

                                    </template>

                                </div>
                            </template>
                        </div>
                    </Box>

                    <Subtitle>Movements</Subtitle>

                    <Box>
                        <div class="-my-1.5">
                            <template v-for="movement in movements">
                                <div class="flex items-center justify-between my-4">
                                    {{ movement.name }}
                                    <Switch v-model="movement.import" />
                                </div>
                            </template>
                        </div>
                    </Box>

                    <Subtitle>Templates</Subtitle>

                    <Box>
                        <template v-for="brandTemplate in templates">
                            <div>
                                <Subtitle>{{ brandTemplate.name }}</Subtitle>
                                <template v-for="template in brandTemplate.templates">
                                    <div class="flex items-center justify-between my-4">
                                        <div class="flex-3">
                                            {{ template.name }}<br/>
                                            <div class="text-xs">
                                                {{ template.movements.map(key => movementByKey(key).name).join(', ') }}
                                            </div>
                                        </div>
                                        <div class="flex-1 flex items-center justify-end">
                                            <Switch v-model="template.import"
                                                    @update:modelValue="handleTemplateToggle(template)"
                                            />
                                        </div>

                                    </div>
                                </template>
                            </div>
                        </template>
                    </Box>

                    <Controls>
                        <template v-slot:right>
                            <Button primary @click="handleCreate">
                                Create Selected Objects
                            </Button>
                        </template>
                    </Controls>

                </template>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import Paragraph from '@/components/Paragraph.vue';

export default defineComponent({
  name: 'Onboard',
  components: {Paragraph},
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadOnboardData();
  },
  data() {
    return {
      bands: [],
      complete: false,
      movements: [],
      templates: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      systemOnboardData: 'system/onboardData',
      myOnboard: 'my/onboard',
    }),
    handleCreate() {
        this.loading = true;
        this.myOnboard({
          bands: this.bands.map(set => set.bands).flat().filter(band => band.import).map(band => band.key),
          movements: this.movements.filter(movement => movement.import).map(movement => movement.key),
          templates: this.templates.map(set => set.templates).flat().filter(template => template.import).map(template => template.key),
        }).then((response) => {
          this.complete = true;
        }).finally(() => {
          this.loading = false;
        });
    },
    handleWorkoutCreate() {
      this.$router.push(window.app.findRouteByName('workout/create'));
    },
    handleTemplateToggle(template) {

      if (template.import) {

        this.movements = [
          ...this.movements.map((movement) => {
            movement.import = template.movements.includes(movement.key) || movement.import;
            return movement;
          }),
        ];

      }
    },
    loadOnboardData() {
      this.loading = true;
      return this.systemOnboardData().then((response) => {

        const onboardData = response.data.data;

        this.bands = [
          ...onboardData.bands.map((set) => {

            set.bands = set.bands.map((band) => {
              band.import = true;
              return band;
            });

            return set;
          }),
        ];

        this.movements = [
          ...onboardData.movements.map((movement) => {
            movement.import = true;
            return movement;
          }),
        ];

        this.templates = [
          ...onboardData.templates.map((brand) => {

            brand.templates = brand.templates.map((template) => {
              template.import = true;
              return template;
            });

            return brand;
          }),
        ];

      }).finally(() => {
        this.loading = false;
      });
    },
    movementByKey(key) {
      return this.movements ? this.movements.find(movement => movement.key === key) : '';
    }
  },
  watch: {
    movements: {
      deep: true,
      handler() {

        let dontImportThese = this.movements.filter(movement => !movement.import).map(movement => movement.key);

        let templates = [];

        this.templates.forEach((template) => {

          template.templates.forEach((_template) => {

            _template.movements.forEach((key) => {
              if (dontImportThese.includes(key)) {
                _template.import = false;
              }
            });

          });

          templates.push(template);
        });

        this.templates = [
          ...templates,
        ];
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
