<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">
                <Title class="mb-6">Features</Title>

                <div class="flex font-semibold">
                    <div class="py-2 flex justify-center items-center w-1/2"></div>
                    <div class="py-2 flex justify-center items-center flex-1">Basic</div>
                    <div class="py-2 flex justify-center items-center flex-1">Pro</div>
                </div>

                <div class="flex" v-for="feature in features">
                    <div class="py-2 flex justify-start items-center w-1/2 px-3">{{feature.title}}</div>
                    <div class="py-2 flex justify-center items-center flex-1 text-brand-primary-600"><Icon icon="circle-check" style="font-weight: bold;" class="text-2xl" v-if="feature.basic"></Icon></div>
                    <div class="py-2 flex justify-center items-center flex-1 text-brand-primary-600"><Icon icon="circle-check" style="font-weight: bold;" class="text-2xl" v-if="feature.pro"></Icon></div>
                </div>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Features',
  data() {
    return {
      features: [
        {
          title: 'Workouts',
          basic: true,
          pro: true,
        },
        {
          title: 'Body (Weight, BF%)',
          basic: true,
          pro: true,
        },
        {
          title: 'Custom Logs',
          basic: false,
          pro: true,
        },
        {
          title: 'Progress Photos',
          basic: false,
          pro: true,
        },
      ],
    };
  },
})
</script>

<style scoped lang="scss">

</style>
