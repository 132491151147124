<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title class="mb-6">Security</Title>

                <Field>
                    <Label>New Password *</Label>
                    <PasswordInput v-model="form.fields.password"/>
                    <FormError :form="form" field="password"/>
                </Field>

                <Field>
                    <Label>Confirm New Password *</Label>
                    <PasswordInput v-model="form.fields.passwordConfirmation"/>
                    <FormError :form="form" field="passwordConfirmation"/>
                </Field>

                <Controls>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';

export default defineComponent({
  name: 'Security',
  data() {
    return {
      form: new Form({
        password: '',
        passwordConfirmation: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      passwordUpdate: 'my/password',
    }),
    handleSave() {
      if (this.loading) {
        return;
      }

      this.form.errors.clear();
      this.loading = true;
      this.passwordUpdate({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Password Updated',
          type: 'success',
        });
        this.form.reset();
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
