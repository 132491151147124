<template>
    <div class="fixed top-0 left-0 bottom-0 z-30 flex flex-col w-72 bg-neutral-100 dark:bg-black overflow-hidden border-r border-r-neutral-200 dark:border-r-neutral-700">

        <div class="top px-4 py-3 border-b border-b-neutral-200 dark:border-b-neutral-700">
            <router-link to="/">
                <Logo />
            </router-link>

            <UpdateAvailable class="mt-3" />

            <OnTrial class="mt-3" />
        </div>

        <div class="middle px-4 flex-1 flex flex-col overflow-scroll scroll-bar-hidden">

            <template v-for="menu in menus">
                <Menu :menu="menu" />
            </template>

            <ThemeSwitcher/>

            <Version />

        </div>

    </div>
</template>

<script>
import {defineComponent} from 'vue'

import Logo from '@/components/Logo.vue';
import Menu from '@/components/Menu.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher.vue';
import UpdateAvailable from '@/components/UpdateAvailable.vue';
import Version from '@/components/Version.vue';

import AccountMenu from '@/menus/AccountMenu.js';
import AdminMenu from '@/menus/AdminMenu.js';
import CommunityMenu from '@/menus/CommunityMenu.js';
import LegalMenu from '@/menus/LegalMenu.js';
import LogsMenu from '@/menus/LogsMenu.js';
import ManageMenu from '@/menus/ManageMenu.js';
import ToolsMenu from '@/menus/ToolsMenu.js';
import OnTrial from '@/components/OnTrial.vue';

export default defineComponent({
  name: 'Sidebar',
  components: {
    OnTrial,
    Logo,
    Menu,
    ThemeSwitcher,
    UpdateAvailable,
    Version,
  },
  computed: {
    menus() {

      let menus = [];

      menus.push(ToolsMenu());
      menus.push(LogsMenu({
        user: this.user,
      }));
      menus.push(ManageMenu());
      menus.push(CommunityMenu());
      menus.push(AccountMenu({
        user: this.user,
      }));
      menus.push(LegalMenu());

      if (this.user.isAdmin) {
        menus.push(AdminMenu());
      }

      return menus;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
})
</script>

<style scoped lang="scss">

</style>
