<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <Field>
                    <Label>Name *</Label>
                    <TextInput v-model="form.fields.name" placeholder="Name"/>
                    <FormError :form="form" field="name"/>
                </Field>

                <Field>
                    <Label>Description</Label>
                    <TextInput v-model="form.fields.description" placeholder="Description"/>
                    <FormError :form="form" field="description"/>
                </Field>

                <Field class="flex items-center justify-between">
                    <p>Track Resistance</p>
                    <Switch v-model="form.fields.trackResistance"/>
                </Field>

                <Field v-if="form.fields.trackResistance">
                    <Label>Resistance Type *</Label>
                    <SelectInput v-model="form.fields.resistanceType" :options="resistanceTypeOptions"/>
                </Field>

                <div v-if="form.fields.trackResistance && form.fields.resistanceType === 'band'">

                    <Field class="flex items-center justify-between">
                        <p>Band Doubled</p>
                        <Switch v-model="form.fields.resistanceConfig.bandDoubled"/>
                    </Field>

                </div>

                <Field class="flex items-center justify-between">
                    <p>Track Repetitions</p>
                    <Switch v-model="form.fields.trackRepetitions"/>
                </Field>

                <Field class="flex items-center justify-between" v-if="form.fields.trackRepetitions">
                    <p>Partial Repetitions</p>
                    <Switch :disabled="!form.fields.trackRepetitions"
                            v-model="form.fields.trackRepetitionsPartial"/>
                </Field>

                <Field class="flex items-center justify-between">
                    <p>Track Duration</p>
                    <Switch v-model="form.fields.trackDuration"/>
                </Field>

                <Field v-if="form.fields.trackDuration">
                    <Label>Calories per Minute</Label>
                    <NumberInput v-model="form.fields.caloriesPerMinute" placeholder="Calories per Minute"/>
                    <FormError :form="form" field="caloriesPerMinute"/>
                </Field>


                <Controls>

                    <template v-slot:left>
                        <Button danger outlined @click="handleDelete" v-if="movement">
                            Delete
                        </Button>
                    </template>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';

export default defineComponent({
  name: 'Form',
  computed: {
    movement() {
      return this.$route.params.movementUUID ? this.$store.getters['movement/findBy'](this.$route.params.movementUUID, 'uuid') : null;
    },
    pageTitle() {
      return this.movement ? 'Edit Movement' : 'New Movement';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.movementUUID) {
      this.loadMovement();
    }
  },
  data() {
    return {
      form: new Form({
        name: '',
        caloriesPerMinute: '',
        description: '',
        resistanceConfig: {
          bandDoubled: false,
        },
        resistanceType: 'band',
        trackDuration: false,
        trackRepetitionsPartial: false,
        trackRepetitions: true,
        trackResistance: true,
      }),
      loading: false,
      resistanceTypeOptions: [
        {
          label: 'Band',
          value: 'band',
        },
        {
          label: 'Body Weight',
          value: 'body-weight',
        },
        {
          label: 'Free Weight',
          value: 'free-weight',
        },
        {
          label: 'Isometric',
          value: 'isometric',
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      movementDestroy: 'movement/destroy',
      movementShow: 'movement/show',
      movementStore: 'movement/store',
      movementUpdate: 'movement/update',
    }),
    fillForm() {
      this.form.fields = {
        ...this.movement,
        resistanceConfig: {
          bandDoubled: false,
          ...this.movement.resistanceConfig,
        }
      };
    },
    handleCancel() {
      this.$router.push(window.app.findRouteByName('movement/index'));
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Movement',
        message: 'Are you sure you want to delete this movement?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.movementDestroy({
                id: this.movement.uuid,
              }).then(() => {
                window.app.snackbar('Movement Deleted');
                this.selectedFile = null;
                this.$router.push(window.app.findRouteByName('movement/index'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleSave() {

      if (this.loading) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
      };

      if (this.movement) {
        action = this.movementUpdate;
        payload.id = this.movement.uuid;
      } else {
        action = this.movementStore;
      }

      this.loading = true;
      action(payload).then(() => {
        window.app.snackbar({
          type: 'success',
          message: 'Movement Saved',
        });
        this.$router.back();
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadMovement() {
      this.loading = true;
      this.movementShow({
        id: this.$route.params.movementUUID,
      }).then(this.fillForm)
        .catch((error) => {
          window.app.snackbarError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    ['form.fields.trackRepetitions']: {
      handler() {
        if (!this.form.fields.trackRepetitions) {
          this.form.fields.trackRepetitionsPartial = false;
        }
      },
    }
  }
})
</script>

<style scoped lang="scss">

</style>
