<template>
  <div class="mobile-drawer" :class="{ 'is-standalone': $isStandalone }">

    <div class="overlay" :class="{ 'is-active': active }" @click="handleClose"/>

    <div class="mobile-drawer-content bg-white dark:bg-neutral-950" :class="{ 'is-active': active }">
      <slot/>
    </div>

  </div>
</template>

<script>
export default {
  name: 'MobileDrawer',
  computed: {},
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  props: {
    active: {
      default: false,
      type: Boolean,
    }
  },
}
</script>

<style scoped lang="scss">
$zIndex: 50;

.mobile-drawer {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  right: 0;
  z-index: $zIndex;

  .overlay {
    position: fixed;
    background: #222;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    opacity: 0;
    transition: opacity .3s, top .01s .3s;
    z-index: $zIndex + 1;

    &.is-active {
      opacity: .8;
      top: 0;
      transition: top .01s, opacity .3s .01s;
    }
  }

  .mobile-drawer-content {

    border-radius: 10px 10px 0 0;
    bottom: 0;
    display: block;
    left: 0;
    max-height: 85%;
    overflow-y: auto;
    position: fixed;
    padding: 15px;
    right: 0;
    transform: translateY(100%);
    transition: all .2s;
    z-index: $zIndex + 2;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &.is-active {
      transform: translateY(0);
    }

    &.is-standalone {
      padding-bottom: $standalonePaddingBottom;
    }
  }
}
</style>
