import {
  ResourceService,
} from '@/internal';

export default class LogService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'log';
  }

  charts(id, payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/${id}/charts`, params),
      params: this.prepareParams(params),
    });
  }

  templates(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: `${this.baseUrl}/templates`,
      params: this.prepareParams(params),
    });
  }
}
