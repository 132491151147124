import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Admin',
    open: true,
    menuItems: [
      {
        route: window.app.findRouteByName('admin/index'),
      }
    ],
  });
};
