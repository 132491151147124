<template>
    <Listbox :modelValue="modelValue" @update:modelValue="handleInput">
        <div class="relative mt-1">
            <ListboxButton class="relative w-full cursor-pointer rounded py-2 pl-3 pr-10 text-left border border-neutral-300 dark:border-neutral-500">
                <div class="block truncate">
                    {{ selectedOption ? selectedOption.label : '&nbsp;' }}
                </div>
                <div class="pointer-events-none absolute top-0 right-0 bottom-0 w-10 flex items-center justify-center">
                    <Icon icon="chevron-down" aria-hidden="true" />
                </div>
            </ListboxButton>

            <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
            >
                <ListboxOptions
                        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none z-10">
                    <ListboxOption
                            v-slot="{ active, selected }"
                            v-for="option in options"
                            :key="option.value"
                            :value="option.value"
                            as="template"
                    >
                        <li class="relative cursor-pointer select-none px-4 py-2"
                            :class="[
                                active ? 'bg-brand-primary-600 text-white' : 'text-gray-900',
                            ]"
                        >
                            <span class="block truncate"
                                  :class="[
                                    selected ? 'font-semibold' : 'font-normal',
                                  ]"
                            >
                                {{ option.label }}
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script>
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'

export default {
  name: 'SelectInput',
  components: {
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.value === this.modelValue);
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    modelValue: {},
    options: {
      default: () => [],
      required: true,
      type: Array,
    },
  },
}
</script>
