import {
  ResourceService,
} from '@/internal';

export default class PerkService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'perk';
  }

  claim(id, payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/${id}/claim`, params),
      params: this.prepareParams(params),
    });
  }
}
