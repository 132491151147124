<template>
    <LogItemForm v-if="editing"
                  v-model="form.fields"
                  @cancel="handleLogItemCancel"
                  @delete="handleLogItemDelete"
                  @save="handleLogItemSave"
    />
    <div>
        <draggable v-model="localValue"
                   @start="drag=true"
                   @end="drag=false"
                   item-key="id"
                   handle=".drag-handle"
        >
            <template #item="{element, index}">
                <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-3 flex items-center justify-between p-3 cursor-pointer" @click="handleLogItemEdit(element)">
                    <div>
                        {{ element.name }}
                    </div>
                    <div class="flex items-center justify-between gap-x-3">
                        <div class="inline-block text-xs bg-neutral-400 px-2 py-1 rounded uppercase">{{ element.input }}</div>
                        <Icon class="cursor-pointer drag-handle mr-1" icon="sort"/>
                    </div>
                </div>
            </template>
        </draggable>
    </div>
    <Button block primary outlined @click="handleLogItemAdd">
        Add Item
    </Button>
</template>

<script>
import {defineComponent} from 'vue'
import {
  Form,
  LogItem,
} from '@/internal.js';
import {uuid} from '@/helpers.js';
import LogItemForm from '@/components/LogItem/Form.vue';
import draggable from 'vuedraggable';

const defaultLogItem = {
  name: '',
  uuid: null,
  input: null,
  multiple: false,
  config: {},
};

export default defineComponent({
  name: 'LogItemsInput',
  components: {
    draggable,
    LogItemForm,
  },
  data() {
    return {
      drag: false,
      editing: false,
      form: new Form({
        ...defaultLogItem,
      }),
      localValue: [],
    };
  },
  methods: {
    handleLogItemAdd() {
      this.form.fields = {
        ...defaultLogItem,
      };
      this.editing = true;
    },
    handleLogItemCancel() {
      this.form.fields = {};
      this.editing = false;
    },
    handleLogItemDelete() {
      this.localValue = [
        ...this.localValue.filter((item) => {

          if (item.uuid === this.form.fields.uuid) {
            return false;
          }

          return true;
        }),
      ];
      this.editing = false;
    },
    handleLogItemEdit(logItem) {
      this.form.fields = {
        ...defaultLogItem,
        ...logItem,
      };
      this.editing = true;
    },
    handleLogItemSave() {

      if (this.form.fields.uuid) {
        this.localValue = [
          ...this.localValue.map((item) => {
            if (item.uuid === this.form.fields.uuid) {
              return new LogItem({
                ...this.form.fields,
              });
            }

            return item;
          }),
        ];
      } else {
        this.localValue = [
          ...this.localValue,
          new LogItem({
            ...this.form.fields,
            uuid: uuid(),
          }),
        ]
      }

      this.editing = false;
      this.form.fields = {};
    },
  },
  props: {
    modelValue: {
      required: true,
      type: Array,
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', this.localValue);
        }
      },
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.localValue = [
            ...this.modelValue,
          ];
        }
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
