<template>
    <nav class="fixed top-0 w-full bg-neutral-200 dark:bg-black flex items-center justify-between p-6 md:p-3 border-b border-b-neutral-500">

        <div class="flex justify-start">
            <router-link to="/dashboard" v-if="$authenticated()">
                <Logo />
            </router-link>
            <a :href="marketingSiteUrl" v-else>
                <Logo />
            </a>
        </div>

        <div class="flex flex-1 justify-end gap-x-4" v-if="!isMobile">
            <a :href="hrefs.pricing" class="dark:text-white py-2 px-3 font-bold" to="/pricing">Pricing</a>
            <router-link :to="hrefs.login" class="dark:text-white py-2 px-3 font-bold">Log in</router-link>
            <router-link :to="hrefs.signUp" class="dark:text-white py-2 px-3 font-bold bg-brand-primary-400 rounded">7 Day Free Trial</router-link>
        </div>

        <div v-else-if="!$authenticated()">
            <div @click="handleMobileMenuClick">
                <Icon class="text-lg dark:text-white"
                      icon="bars"
                      style="font-size: 1.7em;"
                />
            </div>
        </div>

    </nav>
</template>

<script>
import {defineComponent} from 'vue';
import {
  mapActions,
} from 'vuex';

import Logo from '@/components/Logo.vue';

export default defineComponent({
  name: 'Navbar',
  computed: {
    isMobile() {
      return this.$store.state.ui.isMobile;
    },
    marketingSiteUrl() {
      return import.meta.env.VITE_APP_MARKETING_URL;
    },
    mobileMenuActive() {
      return this.$store.state.ui.mobileMenuActive;
    },
  },
  components: {
    Logo,
  },
  data() {
    return {
      hrefs: {
        login: `/auth/login`,
        pricing: `${import.meta.env.VITE_APP_MARKETING_URL}/pricing`,
        signUp: `/auth/sign-up`,
      },
    }
  },
  methods: {
    ...mapActions({
      setMobileMenuActive: 'ui/mobileMenuActive',
    }),
    handleMobileMenuClick() {
      this.setMobileMenuActive(!this.mobileMenuActive);
    },
  },
})
</script>


<style scoped>

</style>
