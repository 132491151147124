import {
  Perk as Model,
  PerkService as Service,
  ResourceStore,
} from '@/internal';

const service = new Service();

const store = new ResourceStore({
  model: Model,
  actions: {
    claim: ({commit}, payload) => {
      return service.claim(payload.id, payload).then((response) => {
        return ResourceStore.processData(Model.key, commit, response.data);
      });
    },
  },
});

export default store.toObject();
