import store from '@/store';

export default (to, from, next) => {

  let user = store.getters['auth/user'];
  let path = window.app.findRouteByName('dashboard').path;

  if (!user || !user.isAdmin) {

    window.app.d(`[authMiddleware] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
