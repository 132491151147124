<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">
                <Title>Privacy Policy</Title>
                <UnderConstruction />
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import Container from '@/components/Container.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';

export default defineComponent({
  name: 'PrivacyPolicy',
  components: {UnderConstruction, Container}
})
</script>

<style scoped lang="scss">

</style>
