<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">

                <Title>Billing</Title>

                <Box>
                    <Subtitle>Subscription</Subtitle>

                    <template v-if="user.subscribed">

                        <Paragraph>Manage your subscriptions, payment methods, etc. in your Billing Portal.</Paragraph>

                        <Button block primary @click="handleBillingPortalLaunch">
                            Launch Billing Portal
                        </Button>

                    </template>

                    <template v-else>

                        <div class="text-center text-8xl mb-3">
                            {{ proPrice }}
                        </div>

                        <div class="flex items-center justify-between my-6 flex-col sm:flex-row">

                            <div class="mb-3 sm:mb-0">Billed Every</div>

                            <div class="flex cursor-pointer">
                                <div class="px-3 py-1 rounded-tl rounded-bl text-center"
                                     :class="[interval === 'year' ? 'bg-brand-primary-400' : 'bg-neutral-400']"
                                     @click="interval = 'year'; frequency = 1;">
                                    Year
                                </div>
                                <div class="px-3 py-1 text-center"
                                     :class="[interval === 'month' && frequency === 6 ? 'bg-brand-primary-400' : 'bg-neutral-400']"
                                     @click="interval = 'month'; frequency = 6;">
                                    6 Months
                                </div>
                                <div class="px-3 py-1 rounded-tr rounded-br text-center"
                                     :class="[interval === 'month' && frequency === 1 ? 'bg-brand-primary-400' : 'bg-neutral-400']"
                                     @click="interval = 'month'; frequency = 1;">
                                    Month
                                </div>
                            </div>
                        </div>

                        <Button block primary @click="handleCheckoutLaunch">
                            Subscribe
                        </Button>

                    </template>

                </Box>

                <Subtitle class="text-center mb-3">
                    Indiegogo Contributor Perks
                </Subtitle>

                <Help class="text-center">
                    <Paragraph>
                        If you contributed to the Indiegogo campaign, you can find/claim your perks below.
                    </Paragraph>
                    <Paragraph>
                        You'll need to use the Promo Code at checkout.
                    </Paragraph>
                </Help>

                <Box>

                    <div class="flex items-center justify-between">
                        <Subtitle>Perks</Subtitle>
                        <div class="underline cursor-pointer" @click="handlePerkFind">Find Perk</div>
                    </div>

                    <div class="bg-white dark:bg-neutral-950 p-3 mb-6 rounded" v-if="findPerkFormActive">
                        <Field>
                            <Label>Claim Code</Label>
                            <TextInput v-model="perkUUID" class="mb-2"/>
                            <Help>
                                <Paragraph>
                                    Your claim code can be found in the email sent to you for your Indiegogo contribution.
                                </Paragraph>
                                <Paragraph>
                                    If you need assistance, just shoot us an email at hello@v3me.io.
                                </Paragraph>
                            </Help>
                        </Field>
                        <Controls>
                            <template v-slot:right>
                                <Button primary @click="handlePerkSearch">
                                    Find Perks
                                </Button>
                            </template>
                        </Controls>
                    </div>

                    <div class="-my-2">
                        <template v-for="perk in perks">
                            <div class="flex items-center justify-between border border-neutral-500 rounded px-3 py-2 my-2">
                                <div>
                                    <div class="mb-1">{{ perk.perk }}</div>
                                    <div class="text-xs">{{ perk.uuid }}</div>
                                </div>
                                <div class="text-center">
                                    <div class="text-sm mb-1">
                                        Promo Code
                                    </div>
                                    <div class="flex items-center justify-center px-2 py-1 rounded bg-brand-primary-400 text-sm cursor-pointer font-mono"
                                         @click="handleStripePromotionCodeCopy(perk)">
                                        {{ perk.stripePromotionCode }}
                                        <Icon icon="copy" class="ml-2"/>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </Box>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import copy from 'copy-to-clipboard';
import TextareaInput from '@/components/TextareaInput.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';
import Paragraph from '@/components/Paragraph.vue';

export default defineComponent({
  name: 'Billing',
  components: {Paragraph, UnderConstruction, TextareaInput},
  computed: {
    perks() {
      return this.$store.getters['perk/all'].filter(perk => !perk.claimedAt) || [];
    },
    proPrice() {
      if (this.interval === 'month' && this.frequency === 6) {
        return '$30';
      } else if (this.interval === 'month' && this.frequency === 1) {
        return '$5';
      } else if (this.interval === 'year') {
        return '$50';
      }
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadUser();
  },
  data() {
    return {
      interval: 'year',
      frequency: 1,
      features: [
        {
          name: 'Data Retention',
          type: String,
          basic: '30 Days',
          pro: 'Unlimited',
        },
        {
          name: 'Workout Log',
          type: Boolean,
          basic: true,
          pro: true,
        },
        {
          name: 'Body Log',
          type: Boolean,
          basic: true,
          pro: true,
        },
        {
          name: 'Custom Logs',
          type: Boolean,
          basic: false,
          pro: true,
        },
        {
          name: 'Analytics',
          type: Boolean,
          basic: false,
          pro: true,
        },
        {
          name: 'Progress Photos',
          type: Boolean,
          basic: false,
          pro: true,
        },
      ],
      findPerkFormActive: false,
      loading: false,
      perkUUID: '',
    };
  },
  methods: {
    ...mapActions({
      myShow: 'my/show',
      myStripeCheckout: 'my/stripeCheckout',
      myStripePortal: 'my/stripePortal',
      perkClaim: 'perk/claim',
    }),
    handleBillingPortalLaunch() {
      this.loading = true;
      this.myStripePortal().then((response) => {

        const url = response.data.data.url;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbarError('Unable to launch Stripe Billing Portal');
        }

      }).catch((error) => {
        this.loading = false;
        window.app.snackbarError(error);
      });
    },
    handleCheckoutLaunch() {
      this.loading = true;
      this.myStripeCheckout({
        interval: this.interval,
      }).then((response) => {

        const url = response.data.data.url;

        if (url) {
          window.location.replace(url);
        } else {
          window.app.snackbarError('Unable to launch Stripe Billing Portal');
        }

      }).catch((error) => {
        this.loading = false;
        window.app.snackbarError(error);
      });
    },
    handlePerkFind() {
      this.findPerkFormActive = !this.findPerkFormActive
    },
    handlePerkSearch() {

      if (!this.perkUUID) {
        window.app.snackbar({
          message: 'Claim code required',
          type: 'error',
        });

        return;
      }

      this.loading = true;
      this.perkClaim({
        id: this.perkUUID,
      }).then(() => {

        window.app.snackbar({
          message: 'Perk Found',
        });

        this.findPerkFormActive = false;

      }).catch((response) => {
        window.app.snackbar({
          message: 'Claim code invalid or already claimed.',
          type: 'error',
        });
        console.log(response);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleStripePromotionCodeCopy(perk) {
      copy(perk.stripePromotionCode);
      window.app.snackbar({
        message: 'Copied to Clipboard',
      });
    },
    loadUser() {
      this.loading = true;
      this.myShow({
        params: {
          include: ['perks'],
        },
      }).finally(() => {
        this.loading = false;
      });
    },
  }
})
</script>

<style scoped lang="scss">

</style>
