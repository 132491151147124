<template>
<p class="my-4 first:mt-0 last:mb-0">
    <slot />
</p>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Paragraph'
})
</script>

<style scoped lang="scss">

</style>
