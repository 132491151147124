<template>
    <div class="text-sm text-neutral-500 text-center">
        v{{version}}
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import appData from '@/../package.json';

export default defineComponent({
  name: 'Version',
  computed: {
    version() {
      return appData.version;
    },
  },
})
</script>

<style scoped lang="scss">

</style>
