import {
  ResourceService,
} from '@/internal';

export default class TemplateService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'template';
  }
}
