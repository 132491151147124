import {
  Exercise,
  File,
  Model,
  Template,
  WorkoutService as Service
} from '@/internal';

export default class Workout extends Model {

  static get key() {
    return 'workout';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {
      workoutAt: {
        type: Date,
      }
    };
  }

  get exercises() {
    return this.hasMany(Exercise);
  }

  get file() {
    return this.belongsTo(File);
  }

  get template() {
    return this.belongsTo(Template);
  }

  sinceStart(startDate) {

    if (!startDate) {
      return '';
    }

    const days = this.workoutAt.diff(startDate, 'day');
    const weeks = Math.floor(days / 7) + 1;
    const dayOfWeek = days % 7 + 1;

    return `W${weeks}-D${dayOfWeek}`;
  }
}
