import {
  ResourceService,
} from '@/internal';

export default class MovementService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'movement';
  }
}
