import {createRouter, createWebHistory} from 'vue-router';

import RouterBuilder from '@/domain/RouterBuilder';

import AdminMiddleware from '@/router/middleware/AdminMiddleware.js';
import AuthMiddleware from '@/router/middleware/AuthMiddleware';
import EmailVerifiedMiddleware from '@/router/middleware/EmailVerifiedMiddleware';
import GuestMiddleware from '@/router/middleware/GuestMiddleware';
import SubscribedOrOnTrialMiddleware from '@/router/middleware/SubscribedOrOnTrialMiddleware.js';
import TitleMiddleware from '@/router/middleware/TitleMiddleware';
import TitleEnvMiddleware from '@/router/middleware/TitleEnvMiddleware';

import AnalyticsRoutes from '@/router/routes/AnalyticsRoutes';
import AdminRoutes from '@/router/routes/AdminRoutes.js';
import AuthRoutes from '@/router/routes/AuthRoutes';
import BandRoutes from '@/router/routes/BandRoutes';
import FoodRoutes from '@/router/routes/FoodRoutes';
import GoalRoutes from '@/router/routes/GoalRoutes.js';
import LeaderboardRoutes from '@/router/routes/LeaderboardRoutes';
import LogRoutes from '@/router/routes/LogRoutes';
import LogEntryRoutes from '@/router/routes/LogEntryRoutes.js';
import MarketplaceRoutes from '@/router/routes/MarketplaceRoutes';
import MovementRoutes from '@/router/routes/MovementRoutes';
import MyRoutes from '@/router/routes/MyRoutes';
import PlannerRoutes from '@/router/routes/PlannerRoutes';
import PublicRoutes from '@/router/routes/PublicRoutes';
import TemplateRoutes from '@/router/routes/TemplateRoutes';
import WellnessRoutes from '@/router/routes/WellnessRoutes';
import WorkoutRoutes from '@/router/routes/WorkoutRoutes';

import FourZeroFour from '@/views/Public/FourZeroFour';

export const routerBuilder = new RouterBuilder();

routerBuilder.registerGlobalMiddleware('title', TitleMiddleware);
routerBuilder.registerGlobalMiddleware('titleEnv', TitleEnvMiddleware);
// routerBuilder.registerGlobalMiddleware('emailVerified', EmailVerifiedMiddleware);
routerBuilder.registerRouteMiddleware('auth', AuthMiddleware);
routerBuilder.registerRouteMiddleware('admin', AdminMiddleware);
routerBuilder.registerRouteMiddleware('guest', GuestMiddleware);
routerBuilder.registerRouteMiddleware('subscribedOrOnTrial', SubscribedOrOnTrialMiddleware);

// Authorized
routerBuilder.addRoutes(AdminRoutes, ['auth', 'admin']);
routerBuilder.addRoutes(AnalyticsRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(BandRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(FoodRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(GoalRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(LeaderboardRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(LogRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(LogEntryRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(MarketplaceRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(PlannerRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(MovementRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(MyRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(TemplateRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(WellnessRoutes, ['auth', 'subscribedOrOnTrial']);
routerBuilder.addRoutes(WorkoutRoutes, ['auth', 'subscribedOrOnTrial']);

// Authorized or Not
routerBuilder.addRoutes(PublicRoutes);

// Guest
routerBuilder.addRoutes(AuthRoutes);

// 404
routerBuilder.addRoute({
  name: 'four-zero-four',
  component: FourZeroFour,
  path: '/:pathMatch(.*)*',
});

routerBuilder.resetRouter = () => {

  const router = createRouter({
    history: createWebHistory('/'),
    routes: routerBuilder.getRoutes(),
  });

  router.beforeEach((to, from, next) => routerBuilder.beforeEach(to, from, next));

  router.afterEach((to, from) => {

    const redirectRoute = window.app.storage.getItem('redirectRoute');

    if (redirectRoute && redirectRoute.name === to.name) {
      window.app.d('Remove redirectRoute');
      window.app.storage.removeItem('redirectRoute');
    }

  });

  return router;
};

const router = routerBuilder.resetRouter();

export default router;
