import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';

import MenuItem from './MenuItem';

export default class Menu {
  constructor(config = {}) {
    this.id = slugify(config.title || uuidv4());
    this.title = config.title || '';
    this.open = config.open !== undefined ? config.open : true;
    this.allMenuItems = config.menuItems ? config.menuItems.map(menuItem => new MenuItem(menuItem)) : [];
    this.menuItems = this.allMenuItems.filter(menuItem => menuItem.when === true || menuItem.when === null)
  }
}
