<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title class="mb-6">Profile</Title>

                <Field>
                    <Label>Profile Photo</Label>
                    <FileInput v-model="form.fields.fileId"/>
                    <FormError :form="form" field="fileId"/>
                </Field>

                <Field>
                    <Label>First Name *</Label>
                    <TextInput v-model="form.fields.firstName" placeholder="First Name"/>
                    <FormError :form="form" field="firstName"/>
                </Field>

                <Field>
                    <Label>Last Name *</Label>
                    <TextInput v-model="form.fields.lastName" placeholder="Last Name"/>
                    <FormError :form="form" field="lastName"/>
                </Field>

                <Field>
                    <Label>Email *</Label>
                    <TextInput v-model="form.fields.email" placeholder="Email"/>
                    <FormError :form="form" field="email"/>
                </Field>

                <Field v-if="false">
                    <Label>Username *</Label>
                    <TextInput v-model="form.fields.username" placeholder="Username"/>
                    <FormError :form="form" field="username"/>
                    <Help class="mt-2">
                        <a class="underline text-brand-primary-800 dark:text-brand-primary-400" target="_blank"
                           :href="profileUrl">{{ profileUrl }}</a>
                    </Help>
                </Field>

                <Field>
                    <Label>Birth Date</Label>
                    <DateInput v-model="form.fields.birthDate" placeholder="Birth Date"/>
                    <FormError :form="form" field="birthDate"/>
                </Field>

                <Field>
                    <Label>Start Date</Label>
                    <DateInput v-model="form.fields.startDate" placeholder="Start Date"/>
                    <FormError :form="form" field="startDate"/>
                </Field>

                <Field>
                    <Label>Timezone (Search by nearest Major City)</Label>
                    <TimezoneInput v-model="form.fields.timezone"/>
                    <FormError :form="form" field="timezone"/>
                </Field>

                <Field>
                    <Label>System of Measure</Label>
                    <SelectInput :options="systemOfMeasureOptions" v-model="form.fields.systemOfMeasure"/>
                    <FormError :form="form" field="timezone"/>
                </Field>

                <Field>

                    <Label>X3 Log Connection</Label>

                    <Button secondary outlined block v-if="user.isV2()" @click="handleX3LogDisconnect">
                        Disconnect from X3 Log
                    </Button>

                    <Button secondary outlined block v-else @click="handleX3LogFormShow" v-if="!x3LogFormActive">
                        Connect to X3 Log
                    </Button>

                    <div v-if="user.isV2()" class="my-3">
                        <Button primary outlined block @click="handleX3LogImport">
                            Import X3 Log Data
                        </Button>
                    </div>

                    <Box v-if="x3LogFormActive">

                        <Subtitle>X3 Log Credentials</Subtitle>

                        <Field>
                            <Label>Email</Label>
                            <TextInput v-model="x3LogForm.fields.email"/>
                        </Field>

                        <Field>
                            <Label>Password</Label>
                            <PasswordInput v-model="x3LogForm.fields.password"/>
                        </Field>

                        <div class="flex items-center justify-end gap-x-4">
                            <Button @click="handleX3LogCancel">
                                Cancel
                            </Button>
                            <Button primary @click="handleX3LogConnect">
                                Connect
                            </Button>
                        </div>

                    </Box>

                </Field>

                <Field v-if="false">
                    <Label>Public *</Label>
                    <div class="flex items-center justify-between">
                        <div>
                            Allow your profile to be viewed by others.
                        </div>
                        <Switch v-model="form.fields.public"></Switch>
                    </div>

                    <FormError :form="form" field="public"/>
                </Field>

                <Controls>
                    <template v-slot:left>
                        <Button danger outlined @click="handleUserDelete">
                            Close
                        </Button>
                    </template>
                    <template v-slot:right>
                        <Button primary @click="handleUserSave">
                            Save
                        </Button>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {Form} from '@/internal.js';
import {mapActions} from 'vuex';
import SelectInput from '@/components/SelectInput.vue';
import FakeInput from '@/components/FakeInput.vue';
import Paragraph from '@/components/Paragraph.vue';
import TextInput from '@/components/TextInput.vue';
import PasswordInput from '@/components/PasswordInput.vue';

export default defineComponent({
  name: 'Profile',
  components: {PasswordInput, TextInput, Paragraph, FakeInput, SelectInput},
  computed: {
    profileUrl() {
      return `${window.app.env.appBaseUrl}/u/${this.form.fields.username}`;
    },
    systemOfMeasureOptions() {
      return [
        {
          label: 'Imperial',
          value: 'imperial',
        },
        {
          label: 'Metric',
          value: 'metric',
        },
      ];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.loadUser();
    window.app.on('fileUpdated', this.fileUpdateCheck)
  },
  destroyed() {
    window.app.off('fileUpdated', this.fileUpdateCheck)
  },
  data() {
    return {
      file: null,
      fileUpdate: false,
      form: new Form({
        birthDate: null,
        email: '',
        fileId: null,
        firstName: '',
        lastName: '',
        public: false,
        startDate: null,
        systemOfMeasure: 'imperial',
        timezone: '',
        username: '',
      }),
      loading: false,
      x3LogForm: new Form({
        email: '',
        password: '',
      }),
      x3LogFormActive: false,
    };
  },
  methods: {
    ...mapActions({
      authUserShow: 'my/show',
      authUserUpdate: 'my/update',
      authUserDestroy: 'my/destroy',
      authUserX3LogConnect: 'my/x3LogConnect',
      authUserX3LogDisconnect: 'my/x3LogDisconnect',
      uiFileDialog: 'ui/imageEditor',
    }),
    fileUpdateCheck(payload = {}) {
      if (this.form.fields.fileId === payload.fileId) {
        this.loadUser();
      }
    },
    fillForm() {
      this.form.fields = {
        birthDate: this.user.birthDate ? this.user.birthDate.format('YYYY-MM-DD') : null,
        email: this.user.email,
        fileId: this.user.file ? this.user.file.id : null,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        public: this.user.public,
        startDate: this.user.startDate ? this.user.startDate.format('YYYY-MM-DD') : null,
        systemOfMeasure: this.user.systemOfMeasure,
        timezone: this.user.timezone,
        username: this.user.username,
      };
    },
    handleUserDelete() {

      if (this.loading) {
        return;
      }

      window.app.dialog({
        title: 'Delete Account',
        message: `Are you absolutely sure you want to delete your account? There's no ability to undo this action. All your data will be deleted permanently.`,
        actions: [
          {
            text: 'Cancel',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Delete My Account',
            danger: true,
            click: () => {

              this.loading = true;
              this.authUserDestroy().then(() => {

                window.app.snackbar({
                  message: `Your account has been deleted`,
                });

                this.$router.push(window.app.findRouteByName('auth/sign-up'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });

    },
    handleUserSave() {

      if (this.loading) {
        return;
      }

      this.loading = true;

      this.form.errors.clear();

      return this.authUserUpdate({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Profile Updated',
          type: 'success',
        });
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.fileUpdate = false;
        this.loading = false;
      });
    },
    handleX3LogCancel() {
      this.x3LogFormActive = false;
    },
    handleX3LogConnect() {
      this.loading = true;
      this.authUserX3LogConnect({
        ...this.x3LogForm.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'X3 Log Connected',
        });
        this.x3LogFormActive = false;
      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleX3LogDisconnect() {
      this.loading = true;
      this.authUserX3LogDisconnect().then(() => {
        window.app.snackbar({
          message: 'X3 Log Disconnected',
        });
      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleX3LogFormShow() {
      this.x3LogFormActive = true;
    },
    handleX3LogImport() {
      this.$router.push(window.app.findRouteByName('my/import'));
    },
    loadUser() {
      this.loading = true;
      return this.authUserShow({
        params: {
          include: [
            'file',
          ]
        }
      }).then(this.fillForm).finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    ['form.fields.fileId']: {
      handler() {
        this.handleUserSave();
      },
    }
  },
})
</script>

<style scoped>

</style>
