import moment from 'moment-timezone';

import SystemService from './SystemService';
import storage from '@/drivers/LocalStorageDriver';

const service = new SystemService();

import appData from '../../../package.json';

const defaultSettings = {
  datetimeFormat: 'll LTS z',
  layout: 'floating',
  theme: 'dark',
  timezone: moment.tz.guess() || 'America/Los_Angeles',
  userLocation: null,
};

const stored = storage.getItem('system') || {};

const updateRequired = (state) => {
  const appVersion = state.version.app.split('.');
  const apiVersion = state.version.api.split('.');
  return apiVersion[0] !== appVersion[0] || apiVersion[1] !== appVersion[1];
};

export default {
  key: 'system',
  namespaced: true,
  state: {
    featuresAvailable: {},
    maintenance: false,
    settings: {
      ...defaultSettings,
      ...stored.settings,
    },
    updateAvailable: false,
    updateRequired: false,
    version: {
      app: appData.version,
      api: null,
    },
  },
  actions: {
    index({commit}) {
      return service.index().then((response) => {
        if (response && response.data && response.data.data) {
          const system = response.data.data;
          commit('system', system);
          return system;
        }

        return null;
      });
    },
    onboardData({commit}) {
      return service.onboardData();
    },
    settings({state, commit, dispatch, rootState}, payload) {
      return commit('settings', payload);
    },
  },
  mutations: {
    system(state, payload = {}) {
      state.maintenance = payload.maintenance;
      state.version.api = payload.version;
      state.updateRequired = updateRequired(state);
      state.updateAvailable = state.version.app !== payload.version;
    },
    settings(state, payload) {

      const nextSettings = {
        ...state.settings,
        ...payload,
      };

      state.settings = {
        ...nextSettings,
      };

      storage.setItem('system', {
        ...state,
        settings: {
          ...nextSettings,
        }
      });
    },
  },
};
