<template>
    <div class="relative"
         :class="[
           active ? 'z-20' : null,
         ]"
         v-on-click-outside="handleDropdownClose"
    >
        <div @click="handleDropdownToggle">
            <slot name="toggle"></slot>
        </div>
        <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
        >
            <div v-if="active" class="absolute left-0 right-0 mt-1 rounded-md bg-white text-black py-2 shadow-lg ring-1 ring-black/5 focus:outline-none" @click="handleDropdownClose">
                <slot name="dropdown" />
            </div>
        </transition>
    </div>
 </template>

<script>
import { vOnClickOutside } from '@vueuse/components'

export default {
  name: 'Dropdown',
  directives: {
    onClickOutside: vOnClickOutside,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    handleDropdownClose() {
      this.active = false;
    },
    handleDropdownToggle() {
      this.active = !this.active;
    },
  },
};
</script>
