import Index from '@/views/Marketplace/Index';

const routes = [
  {
    name: 'marketplace/index',
    path: '/marketplace',
    component: Index,
    meta: {
      icon: 'store',
      title: 'Marketplace',
    },
  },
];

export default routes;
