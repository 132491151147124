<template>
    <ResourceList addLabel="Add"
                  :addRoute="!hideAddButton ? addRoute : null"
                  :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  title="Workouts"
    />
</template>

<script>
import {Workout} from '@/internal';
import WorkoutResourceListItem from '@/components/Workout/ResourceListItem.vue';

export default {
  name: 'WorkoutResourceList',
  data() {
    return {
      params: {
        include: [
          'exercises',
          'file',
          'template',
        ],
      },
      resource: Workout,
    };
  },
  props: {
    addRoute: {
      default() {
        return window.app.findRouteByName('workout/create');
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return WorkoutResourceListItem;
      },
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
