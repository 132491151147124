<template>
    <ResourceList addLabel="Add"
                  :addRoute="!hideAddButton ? addRoute : null"
                  :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  title="Templates"
    />
</template>

<script>
import {Template} from '@/internal';
import TemplateResourceListItem from '@/components/Template/ResourceListItem.vue';

export default {
  name: 'TemplateResourceList',
  data() {
    return {
      params: {
        include: ['movements'],
      },
      resource: Template,
    };
  },
  props: {
    addRoute: {
      default() {
        return window.app.findRouteByName('template/create');
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return TemplateResourceListItem;
      },
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
