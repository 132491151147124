import {
  ResourceService,
} from '@/internal';

export default class FileService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'file';
  }

  store(payload = {}) {

    const {params, formData, ...rest} = payload;

    return this.request({
      method: 'post',
      url: this.baseUrl,
      params: this.prepareParams(params),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  replace(id, payload = {}) {

    const {params, formData, ...rest} = payload;

    return this.request({
      method: 'post',
      url: `${this.baseUrl}/${id}/replace`,
      params: this.prepareParams(params),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

}
