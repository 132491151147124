<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <Field>
                    <Label>Name *</Label>
                    <TextInput v-model="form.fields.name" placeholder="Name"/>
                    <FormError :form="form" field="name"/>
                </Field>

                <Field>
                    <Label>Movements</Label>

                    <draggable v-model="form.fields.movements"
                               handle=".drag-handle"
                               @start="drag=true"
                               @end="drag=false"
                               item-key="id">
                        <template #item="{element, index}">
                            <div class="bg-white dark:bg-neutral-950 border border-neutral-200 dark:border-neutral-700 rounded my-2 p-4 flex items-center justify-between">
                                <div>
                                    {{ element.name }}
                                </div>
                                <div class="flex items-center justify-between gap-x-4">
                                    <Icon class="cursor-pointer drag-handle" icon="sort"/>
                                    <Icon class="cursor-pointer" icon="times" @click="handleMovementRemove(index)"/>
                                </div>
                            </div>
                        </template>
                    </draggable>

                    <Dropdown>
                        <template v-slot:toggle>
                            <Button block primary outlined>
                                Add Movement
                            </Button>
                        </template>
                        <template v-slot:dropdown>
                            <div class="max-h-56 w-full overflow-auto scroll-bar-hidden">
                                <template v-for="movement in movements">
                                    <div class="px-4 py-2 cursor-pointer" @click="handleMovementAdd(movement)">
                                        {{ movement.name }}
                                    </div>
                                </template>
                            </div>
                        </template>
                    </Dropdown>
                </Field>

                <Controls>

                    <template v-slot:left>
                        <Button danger outlined @click="handleDelete" v-if="template">
                            Delete
                        </Button>
                    </template>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>

                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'Form',
  components: {
    draggable,
  },
  computed: {
    movements() {
      return this.$store.getters['movement/all'].sort((a, b) => a.name > b.name ? 1 : -1) || [];
    },
    pageTitle() {
      return this.template ? 'Edit Template' : 'New Template';
    },
    template() {
      return this.$route.params.templateUUID ? this.$store.getters['template/findBy'](this.$route.params.templateUUID, 'uuid') : null;
    },
  },
  async created() {

    await this.loadMovements();

    if (this.$route.params.templateUUID) {
      this.loadTemplate();
    }
  },
  data() {
    return {
      drag: false,
      form: new Form({
        name: '',
        movements: [],
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      movementIndex: 'movement/index',
      templateDestroy: 'template/destroy',
      templateShow: 'template/show',
      templateStore: 'template/store',
      templateUpdate: 'template/update',
    }),
    fillForm() {
      this.form.fields = {
        name: this.template.name,
        movements: [
          ...this.template.movements,
        ],
      };
    },
    handleCancel() {
      this.$router.push(window.app.findRouteByName('template/index'));
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Template',
        message: 'Are you sure you want to delete this template?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.templateDestroy({
                id: this.template.id,
              }).then(() => {
                window.app.snackbar('Template Deleted');
                this.selectedFile = null;
                this.$router.push(window.app.findRouteByName('template/index'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleMovementAdd(movement) {
      this.form.fields.movements = [
        ...this.form.fields.movements,
        movement,
      ];
    },
    handleMovementRemove(index) {
      this.form.fields.movements.splice(index, 1);
    },
    handleSave() {

      if (this.loading) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
        movements: [
          ...this.form.fields.movements.map(movement => movement.id),
        ],
      };

      if (this.template) {
        action = this.templateUpdate;
        payload.id = this.template.uuid;
      } else {
        action = this.templateStore;
      }

      this.loading = true;
      action(payload).then(() => {
        window.app.snackbar({
          type: 'success',
          message: 'Template Saved',
        });
        this.$router.back();
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadMovements() {
      this.loading = true;
      return this.movementIndex().finally(() => {
        this.loading = false;
      });
    },
    loadTemplate() {
      this.loading = true;
      this.templateShow({
        id: this.$route.params.templateUUID,
        params: {
          include: [
            'movements',
          ],
        }
      }).then(this.fillForm)
        .catch((error) => {
          window.app.snackbarError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
