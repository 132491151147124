<template>
    <Combobox v-model="localValue">
        <div class="relative">
            <div class="relative">

                <ComboboxInput class="w-full p-2 rounded placeholder:text-gray-400 bg-transparent border border-neutral-300 dark:border-neutral-500 flex items-center justify-between"
                        @change="search = $event.target.value"
                        :placeholder="placeholder"
                />

                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-3">
                    <Icon icon="chevron-down" />
                </ComboboxButton>

            </div>

            <ComboboxOptions class="absolute bg-white text-neutral-950 mt-1 max-h-60 w-full overflow-auto rounded-md z-20 border" v-if="search && search.length">
                <ComboboxOption class="p-3 cursor-pointer" v-for="option in filteredOptions" :key="option.value" :value="option.value" v-if="filteredOptions.length === 0">
                    No results for that search
                </ComboboxOption>
                <ComboboxOption class="p-3 cursor-pointer" v-for="option in filteredOptions" :key="option.value" :value="option.value" v-else>
                    <slot name="option" :option="option">
                        {{ option.label }}
                    </slot>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script>
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'

export default {
  name: 'AutocompleteInput',
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    TransitionRoot,
  },
  computed: {
    filteredOptions() {
      return this.options
        .filter(option => {

          if (!this.search.length) {
            return false;
          }

          return option.label.toLowerCase().includes(this.search.toLowerCase());
        })
        .filter((o, i) => i < 20)
    },
  },
  data() {
    return {
      localValue: null,
      search: '',
    };
  },
  props: {
    modelValue: {
      required: true,
    },
    options: {
      required: true,
    },
    placeholder: {
      default: 'Search',
      required: false,
    },
  },
  watch: {
    localValue: {
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', this.localValue);
        }
      },
    },
    modelValue: {
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.localValue = this.modelValue;
          this.search = this.modelValue;
        }
      },
    },
  },
}
</script>

<style lang="scss">

</style>
