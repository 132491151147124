import {
  Log as Model,
  LogService as Service,
  ResourceStore,
} from '@/internal';

const service = new Service();

const store = new ResourceStore({
  model: Model,
  actions: {
    charts: ({commit}, payload) => {
      return service.charts(payload.id, payload);
    },
    templates: ({commit}, payload) => {
      return service.templates(payload);
    },
  },
});

export default store.toObject();
