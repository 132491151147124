<template>
    <ResourceList addLabel="Add"
                  :addRoute="!hideAddButton ? addRoute : null"
                  :params="params"
                  :query="query"
                  ref="resourceList"
                  :resource="resource"
                  :renderAs="renderAs"
                  title="Logs"
    />
</template>

<script>
import {Log} from '@/internal';
import LogResourceListItem from '@/components/Log/ResourceListItem.vue';

export default {
  name: 'LogResourceList',
  data() {
    return {
      params: {
        include: [],
      },
      resource: Log,
    };
  },
  props: {
    addRoute: {
      default() {
        return window.app.findRouteByName('log/create');
      },
      required: false,
    },
    hideAddButton: {
      default: false,
      type: Boolean,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => {
        return LogResourceListItem;
      },
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
