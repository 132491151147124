<template>
    <Instruction type="warning" class="rounded-xl p-4 text-center">
        <Title>Under Construction</Title>
        <Paragraph>
            This feature is still under construction.
        </Paragraph>
    </Instruction>
</template>

<script>
import {defineComponent} from 'vue'
import Instruction from '@/components/Instruction.vue';
import Paragraph from '@/components/Paragraph.vue';

export default defineComponent({
  name: 'UnderConstruction',
  components: {Paragraph, Instruction},
})
</script>

<style scoped lang="scss">

</style>
