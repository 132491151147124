import Form from '@/views/Template/Form.vue';
import Index from '@/views/Template/Index';

const routes = [
  {
    name: 'template/index',
    path: '/template',
    component: Index,
    meta: {
      icon: 'list',
      title: 'Templates',
    },
  },
  {
    name: 'template/create',
    path: '/template/create',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Add Template',
    },
  },
  {
    name: 'template/edit',
    path: '/template/:templateUUID/edit',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Edit Template',
    },
  },
];

export default routes;
