import {
  ResourceStore,
  Workout as Model,
  WorkoutService as Service,
} from '@/internal';

const service = new Service();

const store = new ResourceStore({
  model: Model,
  actions: {
    charts: ({commit}, payload) => {
      return service.charts(payload);
    },
    duplicate: ({commit}, payload) => {
      return service.duplicate(payload.id, payload).then((response) => {
        if (response) {
          const idInArray = ResourceStore.processData(Model.key, commit, response.data);
          return idInArray[0];
        }
      });
    },
    share: ({commit}, payload) => {
      return service.share(payload.id, payload);
    },
  },
});

export default store.toObject();
