import Index from '@/views/Goal/Index';

const routes = [
  {
    name: 'goal/index',
    path: '/goal',
    component: Index,
    meta: {
      icon: 'bullseye-arrow',
      title: 'Goals',
    },
  },
];

export default routes;
