import {
  Model,
  Movement,
  TemplateService as Service,
} from '@/internal';

export default class Template extends Model {

  static get key() {
    return 'template';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get movements() {
    return this.hasMany(Movement);
  }
}
