<template>
  <div class="password-input">
    <TextInput :type="type" :modelValue="modelValue" @update:modelValue="handleInput" :placeholder="placeholder" />
    <div class="toggle-visibility" @click="handleToggleVisibility">
      <Icon :icon="type === 'password' ? 'eye-slash' : 'eye'" />
    </div>
  </div>
</template>

<script>
import TextInput from './TextInput';

export default {
  name: 'PasswordInput',
  components: {
    TextInput,
  },
  data() {
    return {
      type: 'password',
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
    handleToggleVisibility() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
  },
  props: {
    placeholder: {
      default: '********',
      required: false,
    },
    modelValue: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">
.password-input {
  position: relative;

  .toggle-visibility {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
