<template>

    <div class="fixed left-0 top-0 right-0 sm:top-4 sm:right-4 sm:left-auto" style="z-index: 60;" v-if="active">

        <div class="bg-neutral-800 sm:rounded pl-4 pr-5 py-4 w-full md:max-w-md flex items-center justify-between">

            <div class="flex-1 cursor-default text-white" v-html="message"></div>

            <div @click="handleAction"
                 class="bg-neutral-600 hover:bg-neutral-500 font-semibold rounded-sm ml-10 flex items-center justify-center p-2 cursor-pointer aspect-square" :class="[
                type === 'success' ? 'text-green-500' : null,
                type === 'error' ? 'text-red-500' : null,
            ]">
                <Icon icon="times" fixed-width style="font-weight: 800;" />
            </div>

        </div>

    </div>

</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'Snackbar',
  computed: {
    active() {
      return this.$store.state.ui.snackbar.active || false;
    },
    message() {
      return this.$store.state.ui.snackbar.message || '';
    },
    action() {
      return this.$store.state.ui.snackbar.action || null;
    },
    duration() {
      return this.$store.state.ui.snackbar.duration || null;
    },
    type() {
      return this.$store.state.ui.snackbar.type || null;
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    ...mapActions({
      setSnackbar: 'ui/snackbar',
      snackbarClose: 'ui/snackbarClose',
    }),
    handleClose() {
      this.snackbarClose();
      clearTimeout(this.timer);
      this.timer = null;
    },
    handleAction() {
      if (this.action && this.action.click) {
        this.action.click();
      }

      this.handleClose();
    },
  },
  watch: {
    active: {
      immediate: true,
      handler() {
        if (this.active && this.duration) {
          this.timer = setTimeout(this.handleClose, this.duration);
        }
      },
    }
  }
};
</script>

<style scoped lang="scss">

</style>
