<template>
    <div class="flex items-center justify-between" v-if="paginator">
        <div class="">
            {{ paginator.count }} {{ $pluralize('Result', paginator.count) }} | Page {{ paginator.currentPage }} of
            {{ paginator.totalPage }}
        </div>
        <div class="">
            <div class="flex items-center justify-between">
                <Button @click="handlePrevious">
                    <Icon icon="chevron-left"/>
                </Button>
                <Button @click="handleNext">
                    <Icon icon="chevron-right"/>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Paginator',
  computed: {
    hasMorePages() {
      return this.paginator.totalPage > this.paginator.currentPage;
    },
  },
  data() {
    return {};
  },
  methods: {
    handleNext() {
      if (this.hasMorePages) {
        this.$emit('next');
      }
    },
    handlePrevious() {
      if (this.paginator.currentPage > 1) {
        this.$emit('previous');
      }
    },
  },
  props: {
    paginator: {
      required: true,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
