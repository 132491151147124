import {
  LogEntry,
  LogItem,
  LogService as Service,
  Model,
} from '@/internal';

export default class Log extends Model {

  static get key() {
    return 'log';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get logEntries() {
    return this.hasMany(LogEntry);
  }

  get logItems() {
    return this.hasMany(LogItem);
  }
}
