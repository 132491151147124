<template>
    <AutocompleteInput :modelValue="modelValue"
                       @update:modelValue="handleInput"
                       :options="options"
                       placeholder="Search for an Icon"
    >
        <template #option="optionProps">
            <Icon :icon="optionProps.option.value" class="mr-2" /> {{optionProps.option.label}}
        </template>
    </AutocompleteInput>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons'
library.add(fal);

export default {
  name: 'FontAwesomeIconInput',
  computed: {
    options() {
      return Object.keys(library.definitions.fal).map((icon) => {
        return {
          label: icon,
          value: icon,
        };
      }) || [];
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    modelValue: {
      required: true,
    },
  },
}
</script>

<style lang="scss">

</style>
