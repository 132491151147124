import {
  Model,
  ExerciseService as Service,
  Workout, Movement, Band
} from '@/internal';

export default class Exercise extends Model {

  static get key() {
    return 'exercise';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get bands() {
    return this.hasMany(Band);
  }

  get movement() {
    return this.belongsTo(Movement);
  }

  get workout() {
    return this.belongsTo(Workout);
  }
}
