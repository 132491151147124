<template>
  <div class="text-sm">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Help',
}
</script>

<style scoped lang="scss">

</style>
