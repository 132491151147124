<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title class="text-center mb-6">Trial Ended</Title>

                <p class="mb-6 text-center">
                    Your trial has ended. We hope this is just the beginning of your journey and not the end.
                </p>

                <Button block primary class="mb-3" @click="handleSubscribe">
                    Continue using V3ME
                </Button>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'TrialEnded',
  created() {
    if (this.subscribedOrOnTrial) {
      this.$router.push(window.app.findRouteByName('dashboard'));
    }
  },
  computed: {
    subscribedOrOnTrial() {
      const subscribed = this.user ? this.user.subscribed : false;
      const onTrial = this.user ? this.user.onTrial : false;
      return subscribed || onTrial;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    handleSubscribe() {
      this.$router.push(window.app.findRouteByName('my/billing'));
    },
  },
})
</script>

<style scoped lang="scss">

</style>
