<template>
    <template v-if="file">
        <div class="aspect-square bg-cover bg-center" :style="`background-image: url('${file.proxyUrl}');`" v-if="isImage"></div>
        <div class="p-5 bg-neutral-200 flex items-center justify-center" v-else>
            {{ file.name }}
        </div>
    </template>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'FilePreview',
  computed: {
    file() {
      return this.id ? this.$store.getters['file/show'](this.id) : null;
    },
    isImage() {
      return this.file && this.file.mime ? this.file.mime.includes('image/') : false;
    },
  },
  props: {
    id: {
      required: true,
    }
  },
})
</script>

<style scoped lang="scss">

</style>
