import Index from '@/views/Food/Index';

const routes = [
  {
    name: 'food/index',
    path: '/food',
    component: Index,
    meta: {
      icon: 'fork-knife',
      title: 'Food',
    },
  },
];

export default routes;
