import AuthService from './AuthService';

import {
  ResourceStore,
  User,
} from '@/internal';

const service = new AuthService();

export default {
  key: 'auth',
  namespaced: true,
  state: {
    authenticated: false,
    userId: null,
  },
  actions: {
    forgotPassword({commit, dispatch}, payload) {
      return service.forgotPassword(payload);
    },
    login({commit, dispatch, rootGetters}, payload) {
      return service.login(payload).then((response) => {

        if (response.data && response.data.data && response.data.data.verification_required) {
          return {
            verificationUUID: response.data.data.uuid,
            verificationRequired: true,
          };
        }

        if (response && response.data) {

          const token = response.data.data.attributes.token;

          if (token) {
            window.app.auth.setToken(token);
            commit('authenticated', true);
            commit('userId', response.data.data.id);
            return ResourceStore.processData('user', commit, response.data);
          } else {
            return dispatch('logout');
          }
        }
      });
    },
    logout({commit}) {
      return service.logout().then(() => {
        commit('authenticated', false);
        commit('userId', null);
        window.app.auth.removeToken();
        return true;
      });
    },
    resetPassword({commit, dispatch}, payload) {
      return service.resetPassword(payload).then((response) => {

        if (response && response.data) {

          const token = response.data.data.attributes.token;

          if (token) {
            window.app.auth.setToken(token);
            commit('userId', response.data.data.id);
            return ResourceStore.processData('user', commit, response.data);
          }

        }
      });
    },
    signUp({commit, dispatch}, payload) {
      return service.signUp(payload).then((response) => {

        if (response && response.data) {
          const token = response.data.data.attributes.token;

          if (token) {
            window.app.auth.setToken(token);
            commit('authenticated', true);
            commit('userId', response.data.data.id);
            return ResourceStore.processData('user', commit, response.data);
          } else {
            return dispatch('logout');
          }
        }
      });
    },
  },
  mutations: {
    authenticated(state, authenticated) {
      state.authenticated = authenticated;
    },
    userId(state, userId) {
      state.userId = userId;
    },
  },
  getters: {
    user: (state, getters, rootState) => {
      if (state.userId) {
        const user = rootState.user && rootState.user.items[state.userId];
        if (user) {
          return new User(user);
        } else {
          return null;
        }
      }
    },
  },
};
