import Index from '@/views/Analytics/Index';

const routes = [
  {
    name: 'analytics/index',
    path: '/analytics',
    component: Index,
    meta: {
      icon: 'arrow-trend-up',
      title: 'Analytics',
    },
  },
];

export default routes;
