<template>
    <div class="flex items-center justify-between">

        <template v-if="editing">

            <div class="flex items-start justify-between">
                <div class="flex items-center justify-between gap-x-1 w-1/2">
                    <div class="flex-1 flex flex-col items-center justify-center gap-y-1">
                        <NumberInput :min="0" v-model="form.minutes" class="text-center" />
                        <Label>Minutes</Label>
                    </div>
                    <div class="-mt-7">:</div>
                    <div class="flex-1 flex flex-col items-center justify-center gap-y-1">
                        <NumberInput :min="0" :max="59" v-model="form.seconds" class="text-center" />
                        <Label>Seconds</Label>
                    </div>
                </div>

                <div class="flex gap-x-2">
                    <Button secondary outlined @click="handleTimerReset">
                        <Icon fixedWidth icon="refresh"/>
                    </Button>
                    <Button primary @click="handleSave">
                        <Icon fixedWidth icon="save"/>
                    </Button>
                </div>
            </div>

        </template>

        <template v-else>

            <div class="text-2xl font-mono">
                {{ $timerValue(duration) }}
            </div>

            <div class="flex gap-x-2">
                <Button :primary="running" :secondary="!running" outlined @click="handleTimerToggle">
                    <Icon fixedWidth :icon="buttonIcon"/>
                </Button>
                <Button secondary outlined @click="handleEdit">
                    <Icon fixedWidth icon="pencil"/>
                </Button>
                <Metronome v-if="metronome" />
            </div>

        </template>

    </div>
</template>

<script>
import {defineComponent} from 'vue'
import moment from 'moment-timezone';
import Metronome from '@/components/Metronome.vue';
import NumberInput from '@/components/NumberInput.vue';

export default defineComponent({
  name: 'Timer',
  components: {
    NumberInput,
    Metronome
  },
  computed: {
    buttonIcon() {
      if (!this.running) {
        return 'timer';
      } else {
        return 'pause';
      }
    },
  },
  created() {
    this.interval = setInterval(this.calculateDuration, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  data() {
    return {
      editing: false,
      duration: 0,
      form: {
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      running: false,
      start: null,
      stop: null,
    };
  },
  methods: {
    calculateDuration() {
      if (this.running && this.start) {
        const now = moment();
        this.duration = now.diff(this.start, 'seconds');
      }
    },
    handleEdit() {
      if (this.running) {
        this.handleTimerToggle();
      }

      this.form.minutes = this.duration ? Math.floor(this.duration / 60) : 0;
      this.form.seconds = this.duration ? this.duration % 60 : 0;
      this.editing = true;
    },
    handleSave() {
      this.duration = parseInt(this.form.minutes * 60) + parseInt(this.form.seconds);
      this.editing = false;
    },
    handleTimerReset() {
      this.duration = 0;
      this.form.minutes = 0;
      this.form.seconds = 0;
    },
    handleTimerToggle() {

      if (this.running) {
        this.running = false;
      } else {

        if (this.duration > 0) {
          this.start = moment().subtract(this.duration, 'seconds');
        } else {
          this.start = moment();
        }

        this.running = true;
      }
    },
  },
  props: {
    metronome: {
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
    },
  },
  watch: {
    duration: {
      handler() {
        if (this.modelValue !== this.duration) {
            this.$emit('update:modelValue', this.duration);
        }
      },
    },
    modelValue: {
      immediate: true,
      handler() {
        if (this.modelValue !== this.duration) {
          this.duration = this.modelValue ? this.modelValue : 0;
        }
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
