<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">

                <LogEntryResourceList v-if="log"
                                      :key="$route.fullPath"
                                      :query="query"
                                      :params="params"
                                      :title="log.name"
                                      :add-route="addRoute"
                />
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import LogEntryResourceList from '@/components/LogEntry/ResourceList.vue';

export default defineComponent({
  name: 'Index',
  components: {LogEntryResourceList},
  computed: {
    addRoute() {
      return window.app.findRouteByName('log/log-entry/create', [], {
        logKey: this.log.key,
      });
    },
    log() {
      return this.$route.params.logKey ? this.$store.getters['log/findBy'](this.$route.params.logKey, 'key') : null;
    },
    params() {
      return {
        include: [
          'logEntryItems',
        ],
      };
    },
    query() {
      return {
        log_uuid: this.log.uuid,
      };
    },
  },
  created() {
    if (this.$route.params.logKey) {
      this.loadLog();
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      logShow: 'log/show',
    }),
    loadLog() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.logShow({
        id: this.$route.params.logKey,
        params: {
          include: [
            'logItems',
          ],
        },
      }).then(() => {

      }).finally(() => {
        this.loading = false;
      });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
