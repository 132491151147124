import AdminService from './AdminService.js';

const service = new AdminService();

export default {
  key: 'admin',
  namespaced: true,
  state: {},
  actions: {
    index() {
      return service.index();
    },
  },
  mutations: {},
};
