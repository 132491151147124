import Billing from '@/views/My/Billing.vue';
import Dashboard from '@/views/Dashboard.vue';
import Import from '@/views/My/Import.vue';
import Modules from '@/views/My/Modules.vue';
import Onboard from '@/views/Onboard.vue';
import Profile from '@/views/My/Profile';
import Security from '@/views/My/Security.vue';
import Switcher from '@/views/My/Switcher.vue';
import TrialEnded from '@/views/My/TrialEnded.vue';

const routes = [
  {
    name: 'dashboard',
    path: '/',
    component: Dashboard,
    meta: {
      icon: 'desktop',
      middleware: ['auth'],
      title: 'Dashboard',
    },
  },
  {
    name: 'my/modules',
    path: '/my/modules',
    component: Modules,
    meta: {
      icon: 'puzzle',
      middleware: ['auth'],
      title: 'Modules',
    },
  },
  {
    name: 'my/import',
    path: '/my/import',
    component: Import,
    meta: {
      icon: 'cloud-arrow-up',
      middleware: ['auth'],
      title: 'Import',
    },
  },
  {
    name: 'my/onboard',
    path: '/my/onboard',
    component: Onboard,
    meta: {
      icon: 'wand-magic-sparkles',
      middleware: ['auth'],
      title: 'Onboard',
    },
  },
  {
    name: 'my/profile',
    path: '/my/profile',
    component: Profile,
    meta: {
      icon: 'user-circle',
      middleware: ['auth'],
      title: 'Profile',
    },
  },
  {
    name: 'my/security',
    path: '/my/security',
    component: Security,
    meta: {
      icon: 'shield',
      middleware: ['auth'],
      title: 'Security',
    },
  },
  {
    name: 'my/switch',
    path: '/my/switch',
    component: Switcher,
    meta: {
      icon: 'shuffle',
      middleware: ['auth'],
      title: 'Switch',
    },
  },
  {
    name: 'my/billing',
    path: '/my/billing',
    component: Billing,
    meta: {
      icon: 'dollar',
      middleware: ['auth'],
      title: 'Billing',
    },
  },
  {
    name: 'my/trial-ended',
    path: '/my/trial-ended',
    component: TrialEnded,
    meta: {
      icon: 'dollar',
      middleware: [
        'auth',
      ],
      title: 'Trial Ended',
    },
  },
];

export default routes;
