<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>Workout Share</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <div class="mt-3">

                    <div class="relative" :class="[!workout.file || !settings.file ? 'aspect-square' : null]"
                         id="share-image" v-if="workout">

                        <div class="absolute inset-0 z-10 opacity-100"
                             :style="`background-color: ${settings.backgroundColor};`"></div>

                        <div class="relative h-full flex flex-col z-20 p-3 share-content"
                             :class="[dark ? 'text-white' : 'text-black']">

                            <div class="flex items-center justify-center mb-3">
                                <LogoBackgroundAware :background="settings.backgroundColor"/>
                            </div>

                            <div class="header-items flex items-start justify-around font-semibold mb-3" v-if="settings.date || settings.startDate || settings.duration">
                                <div v-if="settings.date">{{ workout.workoutAt.format('ll') }}</div>
                                <div v-if="user.startDate && settings.startDate">{{
                                        workout.sinceStart(user.startDate)
                                    }}
                                </div>
                                <div v-if="settings.duration">
                                    <Icon icon="stopwatch"/>
                                    {{ $timerValue(workout.duration) }}
                                </div>
                            </div>

                            <div class="mb-3" v-if="workout.file && settings.file">
                                <div class="aspect-square bg-center bg-cover"
                                     :style="`background-image: url('${workout.file.proxyUrl}');`"></div>
                            </div>

                            <div class="flex h-1/2 my-3 items-center justify-center flex-wrap" v-if="settings.exercises">

                                <template v-for="exercise in workout.exercises">

                                    <div class="flex flex-col items-center justify-center px-3 py-1" :class="[thirds ? 'w-1/3' : 'w-1/2']">

                                        <div class="text-xs whitespace-nowrap exercise-name">
                                            {{ exercise.movement.name }}
                                            <span v-if="settings.repetitions && exercise.repetitions">x {{ exercise.repetitions }}</span>
                                        </div>

                                        <div class="h-6 w-full my-2" v-if="settings.resistance">

                                            <template v-if="exercise.bands.length">
                                                <div class="h-full flex">
                                                    <template v-for="band in exercise.bands">
                                                        <div class="flex-1 h-[20px]"
                                                             :style="`background-color: ${band.color};`"></div>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-else-if="exercise.weight">
                                                <div class="h-[20px] flex items-center justify-center weight-text">
                                                    {{ weight(exercise) }}
                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                </template>
                            </div>

                            <div class="text-center pt-3" v-if="settings.notes">
                                {{ workout.notes }}
                            </div>

                        </div>
                    </div>
                </div>

                <div class="py-6">

                    <Field class="flex items-center justify-between">
                        <p>Background Color</p>
                        <div class="-mr-3">
                            <ColorPicker v-model:pureColor="settings.backgroundColor"/>
                        </div>
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>Date</p>
                        <Switch v-model="settings.date"/>
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>From Start Date</p>
                        <Switch v-model="settings.startDate"/>
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>Duration</p>
                        <Switch v-model="settings.duration"/>
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>Progress Photo</p>
                        <Switch v-model="settings.file" :disabled="workout && !workout.file" />
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>Exercises</p>
                        <Switch v-model="settings.exercises"/>
                    </Field>

                    <Field class="flex items-center justify-between" v-if="settings.exercises">
                        <p>Repetitions</p>
                        <Switch v-model="settings.repetitions"/>
                    </Field>

                    <Field class="flex items-center justify-between" v-if="settings.exercises">
                        <p>Resistance</p>
                        <Switch v-model="settings.resistance"/>
                    </Field>

                    <Field class="flex items-center justify-between">
                        <p>Notes</p>
                        <Switch v-model="settings.notes"/>
                    </Field>

                    <Controls>
                        <template v-slot:right>
                            <Button primary @click="handleSave">
                                Save Image
                            </Button>
                        </template>
                    </Controls>

                </div>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import html2canvas from 'html2canvas';
import {saveAs} from 'file-saver';
import Logo from '@/components/Logo.vue';
import LogoBackgroundAware from '@/components/LogoBackgroundAware.vue';
import map from '@/assets/images/map.png';
import Controls from '@/components/Controls.vue';
import color from 'color';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'Share',
  components: {
    Controls,
    Logo,
    LogoBackgroundAware,
  },
  computed: {
    dark() {
      return color(this.settings.backgroundColor).isDark();
    },
    thirds() {
      return this.workout.exercises.length % 3 === 0;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
    weightDisplayUnit() {
      return this.user.systemOfMeasure === 'metric' ? 'kg' : 'lb';
    },
    workout() {
      return this.$route.params.workoutUUID ? this.$store.getters['workout/findBy'](this.$route.params.workoutUUID, 'uuid') : null;
    },
  },
  created() {
    if (this.$route.params.workoutUUID) {
      this.loadWorkout().then(() => {
        if (!this.workout.file) {
          this.settings.file = false;
        }
      });
    }
  },
  data() {
    return {
      image: map,
      loading: false,
      saving: false,
      settings: {
        backgroundColor: '#212121',
        date: true,
        duration: true,
        exercises: true,
        file: true,
        notes: true,
        repetitions: true,
        resistance: true,
        startDate: true,
      },
    };
  },
  methods: {
    ...mapActions({
      workoutShow: 'workout/show',
    }),
    handleCancel() {
      this.$router.back();
    },
    handleSave() {

      this.saving = true;

      const element = document.getElementById('share-image');

      element.classList.add('saving');

      html2canvas(element, {
        useCORS: true,
      }).then(canvas => {

        canvas.toBlob((blob) => {
          saveAs(blob, `${moment.now().valueOf()}.png`);
          element.classList.remove('saving');
        });

      });
    },
    loadWorkout() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      return this.workoutShow({
        id: this.$route.params.workoutUUID,
        params: {
          include: [
            'exercises',
            'file',
          ],
        },
      }).finally(() => {
        this.loading = false;
      });
    },
    weight(exercise) {

      if (!exercise.weight) {
        return null;
      }

      const displayWeight = this.$convert(exercise.weight, 'kg').to(this.weightDisplayUnit);
      const displayFormat = this.user.systemOfMeasure === 'metric' ? '0,0.0' : '0,0';
      return `${this.$numeral(displayWeight).format(displayFormat)} ${this.weightDisplayUnit}`;
    },
  },
})
</script>

<style lang="scss">
.saving {
    .share-content {
        padding-top: 0;
        padding-bottom: 25px;
    }

    .header-items {
        margin-bottom: 18px;
    }

    .exercise-name {
        margin-bottom: 5px;
    }

    .weight-text {
       margin-top: -8px;
    }
}
</style>
