import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Community',
    open: true,
    menuItems: [
      {
        href: 'https://www.facebook.com/groups/444623373019965',
        icon: 'facebook',
        iconPack: 'fa-brands',
        target: '_blank',
        title: 'FB Group',
      },
      {
        href: 'https://trello.com/b/D9j7YTSK/x3-log',
        icon: 'trello',
        iconPack: 'fa-brands',
        target: '_blank',
        title: 'Development',
      },
      // {
      //   route: window.app.findRouteByName('leaderboard/index'),
      // },
      // {
      //   route: window.app.findRouteByName('marketplace/index'),
      // },
    ],
  });
};
