import {
  PerkService as Service,
  Model,
} from '@/internal';

export default class Perk extends Model {

  static get key() {
    return 'perk';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

}
