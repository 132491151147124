<template>
    <headlessSwitch :value="modelValue ? 'true' : 'false'"
                    @click="handleInput"
                    :class="[
                      modelValue ? 'bg-brand-primary-500' : 'bg-gray-200 dark:bg-gray-500',
                      disabled ? 'opacity-75 cursor-not-allowed' : null,
                    ]"
                    class="relative inline-flex h-6 w-11 items-center rounded-full"
                    :disabled="disabled"
    >
        <span class="sr-only"></span>
        <span
                :class="modelValue ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
        />
    </headlessSwitch>
</template>

<script>
import {defineComponent} from 'vue'

import {
  Switch as headlessSwitch,
} from '@headlessui/vue';

export default defineComponent({
  name: 'Switch',
  components: {
    headlessSwitch,
  },
  methods: {
    handleInput() {
      if (this.disabled) {
        return;
      }

      this.$emit('update:modelValue', !this.modelValue);
    },
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    modelValue: {
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">

</style>
