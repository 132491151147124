import store from '@/store';

export default (to, from, next) => {

  let authenticated = store.state.auth.authenticated;
  let path = window.app.findRouteByName('auth/login').path;

  if (!authenticated && to.path !== path) {

    window.app.d('setting redirectRoute', to);

    window.app.storage.setItem('redirectRoute', to);

    window.app.snackbar('You must login to access that page.');

    window.app.d(`[authMiddleware] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
