<template>
    <p class="text-sm text-red-500 mt-2" v-if="error">
        {{error}}
    </p>
</template>

<script>
export default {
  name: 'FormError',
  computed: {
    error() {
      return this.form.errors.has(this.field) ? this.form.errors.get(this.field) : false;
    },
  },
  props: {
    field: {
      required: true,
      type: String,
    },
    form: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
