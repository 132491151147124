<template>
    <div class="flex items-center justify-between mt-8">
        <div class="flex flex-grow gap-x-2 items-center">
            <slot name="left"></slot>
        </div>
        <div class="flex flex-grow gap-x-2 items-center justify-end">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Controls'
})
</script>

<style scoped lang="scss">

</style>
