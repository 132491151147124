<template>
    <div class="phone-input">
        <TextInput :modelValue="modelValue"
                   @update:modelValue="handleInput"
                   @blur="validateNumber"
                   :placeholder="placeholder"
        />
    </div>
</template>

<script>
import {phone} from 'phone';
import _ from 'lodash';

export default {
  name: 'PhoneInput',
  data() {
    return {};
  },
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
    validateNumber(event) {

      let value = event.target.value;

      const valid = phone(value, {country: 'USA'}).isValid;

      if (value && !valid) {
        window.app.snackbar({
          message: 'Phone Number Invalid',
          type: 'is-danger',
        });
      }
    },
  },
  props: {
    placeholder: {},
    modelValue: {},
  },
};
</script>

<style lang="scss">
.phone-input {

}
</style>
