<template>
    <div class="w-full" v-if="updateAvailable">
        <Button block primary @click="handleClick" :loading="loading">
            Update Available
        </Button>
    </div>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'UpdateAvailable',
  computed: {
    updateAvailable() {
      return this.$store.state.system.updateAvailable;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleClick() {
      this.loading = true;
      window.location.reload(true);
    },
  }
})
</script>

<style scoped lang="scss">

</style>
