<template>
    <div class="">
        <input ref="file" class="hidden h-0" type="file"accept="image/*" capture="user" @change="handleFileChange">
        <Button primary @click="handleFilePick">
            <template v-if="file">
                File: {{ file.name }}
            </template>
            <div class="flex items-center justify-between" v-else>
                <Icon icon="upload" class="mr-3"/>
                <div>Upload</div>
            </div>
        </Button>
    </div>
</template>

<script>

export default {
  name: 'FilePickerButton',
  created() {
    if (this.modelValue) {
      this.file = this.modelValue;
    }
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    handleFilePick() {
      this.$refs.file.click();
    },
  },
  props: {
    extensions: {
      default: () => [],
      required: false,
      type: Array,
    },
    disabled: {
      default: false,
      required: false
    },
    loading: {
      default: false,
      type: Boolean,
    },
    types: {
      default: () => [],
      required: false,
      type: Array,
    },
    modelValue: {
      required: false
    },
  },
  watch: {
    file: {
      handler() {
        this.$emit('update:modelValue', this.file);
      },
    }
  }
};
</script>

<style lang="scss">

</style>
