import Form from '@/views/Log/Form.vue';
import Index from '@/views/Log/Index';
import Show from '@/views/Log/Show.vue';
import LogEntryForm from '@/views/LogEntry/Form.vue';

const routes = [
  {
    name: 'log/index',
    path: '/log',
    component: Index,
    meta: {
      icon: 'scroll',
      title: 'Logs',
    },
  },
  {
    name: 'log/create',
    path: '/log/create',
    component: Form,
    meta: {
      icon: 'circle-plus',
      title: 'Create Log',
    },
  },
  {
    name: 'log/show',
    path: '/log/:logKey',
    component: Show,
    meta: {
      title: 'Log',
    },
  },
  {
    name: 'log/edit',
    path: '/log/:logUUID/edit',
    component: Form,
    meta: {
      title: 'Edit Log',
    },
  },
  {
    name: 'log/log-entry/edit',
    path: '/log/:logKey/log-entry/:logEntryUUID/edit',
    component: LogEntryForm,
    meta: {
      title: 'Edit Log Entry',
    },
  },
  {
    name: 'log/log-entry/create',
    path: '/log/:logKey/log-entry/create',
    component: LogEntryForm,
    meta: {
      title: 'Create Log Entry',
    },
  },
];

export default routes;
