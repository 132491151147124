import Index from '@/views/Admin/Index';

const routes = [
  {
    name: 'admin/index',
    path: '/admin',
    component: Index,
    meta: {
      icon: 'crown',
      title: 'Admin',
    },
  },
];

export default routes;
