<template>

    <div v-if="menuItem.onClick"
         :class="{
            'na-menu-item': !menuItem.class,
            [menuItem.class]: menuItem.class && !menuItem.cta,
            'button': menuItem.cta,
            'is-dark': menuItem.cta,
            'is-block': menuItem.cta,
        }"
         @click="menuItem.onClick"
         style="cursor: pointer;"
    >
        <MenuItemContent :menu-item="menuItem"/>
    </div>

    <div v-else-if="menuItem.cta"
         :class="{
            [menuItem.class]: menuItem.class,
        }"
    >
        <a class="button is-block is-fullwidth is-dark" :href="menuItem.href" v-if="menuItem.href">
            <MenuItemContent :menu-item="menuItem" align="center"/>
        </a>

        <router-link class="button is-block is-fullwidth is-dark"
                     v-else-if="menuItem.route"
                     :to="menuItem.route"
        >
            <MenuItemContent :menu-item="menuItem" align="center"/>
        </router-link>
    </div>

    <a v-else-if="menuItem.href"
       :class="{
            'na-menu-item': !menuItem.class,
            [menuItem.class]: menuItem.class && !menuItem.cta,
            'button': menuItem.cta,
            'is-dark': menuItem.cta,
            'is-block': menuItem.cta,
        }"
       :href="menuItem.href"
       :target="menuItem.target"
    >
        <MenuItemContent :menu-item="menuItem"/>
    </a>

    <router-link v-else-if="menuItem.route"
                 :class="{
                    'na-menu-item': !menuItem.class,
                    [menuItem.class]: menuItem.class && !menuItem.cta,
                    'button': menuItem.cta,
                    'is-dark': menuItem.cta,
                    'is-block': menuItem.cta,
                 }"
                 :to="menuItem.route"
    >
        <MenuItemContent :menu-item="menuItem"/>
    </router-link>

</template>

<script>
import MenuItemContent from './MenuItemContent';

export default {
  name: 'MenuItem',
  components: {
    MenuItemContent,
  },
  props: {
    menuItem: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.na-menu-item {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-size: 1.2em;
    padding: 2.5px 0;
    margin: 5px 0;
}
</style>
