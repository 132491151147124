<template>

    <headlessDialog open class="relative z-40">

        <div class="fixed inset-0 bg-black/50" aria-hidden="true"/>

        <div class="fixed inset-0 flex w-screen items-center justify-center">

            <DialogPanel
                    class="relative bg-white dark:bg-neutral-900 md:rounded max-w-md w-full h-full md:h-auto md:max-h-[80vh] overflow-hidden md:overflow-auto flex flex-col">

                <div class="flex items-center justify-between p-4">

                    <Title>Item</Title>

                    <div class="flex items-center justify-between gap-x-4">
                        <div class="cursor-pointer" @click="handleCancel">
                            <Icon icon="times" class="text-2xl"/>
                        </div>
                    </div>

                </div>

                <div class="relative p-4">

                    <Field>
                        <Label>Name *</Label>
                        <TextInput v-model="modelValue.name" placeholder="Name" data-1p-ignore/>
                    </Field>

                    <Field>
                        <Label>Input *</Label>
                        <SelectInput v-model="modelValue.input" :options="logItemInputOptions"/>
                    </Field>

                    <div class="flex items-center justify-between gap-x-3" v-if="modelValue.input === 'number'">

                        <Field>
                            <Label>Precision</Label>
                            <NumberInput v-model="modelValue.config.precision"/>
                        </Field>

                        <Field>
                            <Label>Min</Label>
                            <NumberInput v-model="modelValue.config.min"/>
                        </Field>

                        <Field>
                            <Label>Max</Label>
                            <NumberInput v-model="modelValue.config.max"/>
                        </Field>

                    </div>

                    <div class="flex items-center justify-between gap-x-3" v-else-if="modelValue.input === 'range'">

                        <Field>
                            <Label>Step</Label>
                            <NumberInput v-model="modelValue.config.step"/>
                        </Field>

                        <Field>
                            <Label>Min</Label>
                            <NumberInput v-model="modelValue.config.min"/>
                        </Field>

                        <Field>
                            <Label>Max</Label>
                            <NumberInput v-model="modelValue.config.max"/>
                        </Field>

                    </div>

                    <Field v-else-if="modelValue.input === 'start-stop'">
                        <Label>Rendering</Label>
                        <SelectInput v-model="modelValue.config.rendering" :options="startStopRenderingOptions"/>
                    </Field>

                    <Field v-if="false">
                        <Label>Multiple *</Label>
                        <Switch v-model="modelValue.multiple"/>
                    </Field>

                </div>

                <div class="flex items-center justify-between p-4">

                    <div>
                        <Button danger outlined @click="handleDelete" v-if="modelValue.uuid">
                            Delete
                        </Button>
                    </div>

                    <div>
                        <Button block primary @click="handleSave">
                            Save
                        </Button>
                    </div>

                </div>

            </DialogPanel>

        </div>

    </headlessDialog>

</template>

<script>
import {
  Dialog as headlessDialog,
  DialogPanel,
} from '@headlessui/vue';
import SelectInput from '@/components/SelectInput.vue';

export default {
  name: 'Form',
  components: {
    SelectInput,
    headlessDialog,
    DialogPanel,
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      logItemInputOptions: [
        {
          label: '- Select an Input -',
          value: null,
        },
        {
          label: 'Text',
          value: 'text',
        },
        {
          label: 'Number',
          value: 'number',
        },
        {
          label: 'Range',
          value: 'range',
        },
        {
          label: 'Yes/No',
          value: 'boolean',
        },
        {
          label: 'Start/Stop',
          value: 'start-stop',
        },
        {
          label: 'Timer',
          value: 'timer',
        },
        {
          label: 'File',
          value: 'file',
        },
      ],
      loading: false,
      startStopRenderingOptions: [
        {
          label: '- Select Rendering -',
          value: null,
        },
        {
          label: 'Timestamps',
          value: 'timestamps',
        },
        {
          label: 'Duration',
          value: 'duration',
        },
        {
          label: 'Timestamps + Duration',
          value: 'both',
        },
      ],
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleSave() {
      this.$emit('save');
    },
  },
  props: {
    modelValue: {
      required: true,
    }
  },
};
</script>

<style scoped lang="scss">

</style>
