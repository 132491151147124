import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Welcome',
    open: true,
    menuItems: [
      {
        href: `${import.meta.env.VITE_APP_MARKETING_URL}/pricing`,
        icon: 'dollar',
        title: 'Pricing',
      },
      {
        route: window.app.findRouteByName('auth/login'),
      },
      {
        route: window.app.findRouteByName('auth/sign-up'),
      },
    ],
  });
};
