import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Tools',
    open: true,
    menuItems: [
      // {
      //   route: window.app.findRouteByName('dashboard'),
      // },
      // {
      //   route: window.app.findRouteByName('planner/index'),
      // },
      // {
      //   route: window.app.findRouteByName('goal/index'),
      // },
      {
        route: window.app.findRouteByName('analytics/index'),
      },
    ],
  });
};
