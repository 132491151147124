import {
  ResourceService,
} from '@/internal';

export default class BandService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'band';
  }
}
