<template>
    <input :type="type"
           class="input w-full block flex-1 p-2 rounded placeholder:text-gray-400 bg-transparent border border-neutral-300 dark:border-neutral-500"
           :value="modelValue"
           @input="handleInput($event.target.value)"
           @blur="handleBlur"
           :placeholder="placeholder"
           :maxlength="maxlength"
           :max="max"
           :min="min"
           :step="step"
    />
</template>

<script>
export default {
  name: 'TextInput',
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    maxlength: {
      required: false,
    },
    max: {
      default: null,
      required: false,
      type: Number,
    },
    min: {
      default: null,
      required: false,
      type: Number,
    },
    placeholder: {
      type: String,
      required: false,
    },
    step: {
      default: null,
      required: false,
      type: Number,
    },
    type: {
      default: 'text',
      type: String,
      required: false,
    },
    modelValue: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
