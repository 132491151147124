<template>
    <div class="page py-6">
        <slot/>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Page',
})
</script>

<style scoped lang="scss">
.page {

}
</style>
