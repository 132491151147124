<template>
    <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 flex items-center justify-between cursor-pointer"
         @click="handleBandEdit">
        <div class="flex items-center p-3">
            <div class="h-4 w-4 border border-neutral-800 dark:border-neutral-100 rounded-full"
                 :style="`background: ${resource.color};`"></div>
            <div class="ml-3">
                {{ resource.brandAndName }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    band() {
      return this.resource;
    },
  },
  methods: {
    handleBandEdit() {
      this.$router.push(`/band/${this.band.uuid}/edit`);
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
