<template>
    <div class="text-sm mt-3 bg-neutral-200 dark:bg-neutral-900 px-3 py-2 rounded" v-if="notes">
        <Icon icon="note-sticky" class="mr-1"/> {{ notes }}
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Notes',
  props: {
    notes: {},
  }
})
</script>

<style scoped lang="scss">

</style>
