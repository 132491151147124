<template>
    <div class="mb-6">
        <div class="flex items-center justify-around border border-neutral-500 rounded-full p-1 mb-3">
            <template v-for="option in options">
                <div class="flex-1 flex items-center justify-center rounded-full p-1 cursor-pointer"
                     :class="[
                    localValue.value === option.value ? 'bg-brand-primary-400' : null,
                 ]"
                     @click="handleRangePicked(option)"
                >
                    {{ option.label }}
                </div>
            </template>
        </div>
        <div class="flex items-center justify-around" v-if="localValue">
            <Button outlined secondary @click="handlePrevious">
                <Icon icon="chevron-left" />
            </Button>
            <div>
                {{from}} - {{to}}
            </div>
            <Button outlined secondary @click="handleNext">
                <Icon icon="chevron-right" />
            </Button>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import moment from 'moment-timezone';

const defaultLocalValue = {
  value: '1w',
  end: moment().endOf('week').toDate(),
  start: moment().startOf('week').toDate(),
};

export default defineComponent({
  name: 'DateRangeInput',
  computed: {
    from() {
      return moment(this.localValue.start).format('ll');
    },
    to() {
      return moment(this.localValue.end).format('ll');
    },
  },
  created() {

  },
  data() {
    return {
      localValue: {
        ...defaultLocalValue,
      },
      options: [
        {
          label: '1W',
          value: '1w',
          duration: {
            amount: 1,
            unit: 'weeks'
          }
        },
        {
          label: '1M',
          value: '1m',
          duration: {
            amount: 1,
            unit: 'months'
          }
        },
        {
          label: '3M',
          value: '3m',
          duration: {
            amount: 3,
            unit: 'months'
          }
        },
        {
          label: '1Y',
          value: '1y',
          duration: {
            amount: 1,
            unit: 'years'
          }
        },
      ],
    };
  },
  methods: {
    handleNext() {
      const { amount, unit } = this.options.find(option => option.value === this.localValue.value).duration;
      this.localValue = {
        ...this.localValue,
        start: moment(this.localValue.start).add(amount, unit).toDate(),
        end: moment(this.localValue.end).add(amount, unit).toDate(),
      };
    },
    handlePrevious() {
      const { amount, unit } = this.options.find(option => option.value === this.localValue.value).duration;
      this.localValue = {
        ...this.localValue,
        start: moment(this.localValue.start).subtract(amount, unit).toDate(),
        end: moment(this.localValue.end).subtract(amount, unit).toDate(),
      };
    },
    handleRangePicked(option) {

      let end = moment().endOf(option.duration.unit).toDate();
      let start = moment().subtract(option.duration.amount - 1, option.duration.unit).startOf(option.duration.unit).toDate();

      this.localValue = {
        value: option.value,
        end,
        start,
      };
    },
  },
  props: {
    modelValue: {
        require: true,
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.modelValue) !== JSON.stringify(this.localValue)) {
          this.$emit('update:modelValue', {
            ...this.localValue,
          });
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.modelValue) !== JSON.stringify(this.localValue)) {
          this.localValue = {
            ...this.localValue,
            ...this.modelValue,
          }
        }
      },
    }
  },
})
</script>

<style scoped lang="scss">

</style>
