<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>Import</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <template v-if="complete">

                    <p class="mb-6">
                        You're all set! Your data has been imported.
                    </p>

                    <p class="mb-6">
                        One of the new features is the ability to have a custom log.
                    </p>

                    <p class="mb-6">
                        Would you like to try out our Wellness log? You'll be able to customize it in the next step.
                    </p>

                    <Label>Wellness Log</Label>

                    <div class="border border-neutral-500 p-4 rounded mb-6">

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Happiness</p>
                            <Switch v-model="form.fields.wellness.happiness"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Energy</p>
                            <Switch v-model="form.fields.wellness.energy"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Stress</p>
                            <Switch v-model="form.fields.wellness.stress"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Sex</p>
                            <Switch v-model="form.fields.wellness.sex"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Sleep</p>
                            <Switch v-model="form.fields.wellness.sleep"/>
                        </Field>

                        <Field class="flex items-center justify-between !mb-0">
                            <p class="font-semibold">Notes</p>
                            <Switch v-model="form.fields.wellness.notes"/>
                        </Field>

                    </div>

                    <Controls>
                        <template v-slot:right>
                            <Button class="mr-3" @click="handleWellnessLogDecline">
                                No Thanks
                            </Button>
                            <Button primary @click="handleWellnessLogCreate" :loading="loading">
                                Yes Please!
                            </Button>
                        </template>
                    </Controls>

                </template>

                <template v-else-if="importing">

                    <div class="p-2">
                        Import Started
                    </div>

                    <template v-for="item in importStatus">
                        <div class="p-2">
                            {{ item.key }}: {{ item.imported }} / {{ item.total }} Imported
                        </div>
                    </template>

                    <template v-if="importError">
                        <Instruction type="error" class="p-2">
                            <span class="font-bold">Error:</span> There was an error during the import.
                        </Instruction>
                    </template>

                    <div class="p-2" v-if="complete">
                        Import Complete
                    </div>

                </template>

                <template v-else-if="prepared">

                    <p class="mb-6">
                        Next, you can toggle which data you'd like to import.
                    </p>

                    <Label>Workout Log</Label>

                    <div class="border border-neutral-500 p-4 rounded mb-6">

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Bands</p>
                            <Switch v-model="form.fields.bands"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Movements</p>
                            <Switch v-model="form.fields.movements"/>
                        </Field>

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Templates</p>
                            <Switch v-model="form.fields.templates"/>
                        </Field>

                        <Help class="bg-yellow-300 rounded p-3 my-4" v-if="!form.fields.movements">
                            Bands are required to import Templates
                        </Help>

                        <Field class="flex items-center justify-between !mb-0">
                            <p class="font-semibold">Workouts</p>
                            <Switch v-model="form.fields.workouts"/>
                        </Field>

                        <Help class="bg-yellow-300 rounded p-3 mt-4"
                              v-if="!form.fields.bands || !form.fields.movements">
                            Bands & Movements are required to import Workouts
                        </Help>

                    </div>

                    <p class="mb-6">
                        In V3, weight and body fat have been moved into their own new Body log.
                    </p>

                    <Label>Body Log</Label>

                    <div class="border border-neutral-500 p-4 rounded mb-6">

                        <Field class="flex items-center justify-between">
                            <p class="font-semibold">Weight</p>
                            <Switch v-model="form.fields.body.weight"/>
                        </Field>

                        <Field class="flex items-center justify-between !mb-0">
                            <p class="font-semibold">Body Fat %</p>
                            <Switch v-model="form.fields.body.bodyFat"/>
                        </Field>

                    </div>

                    <Controls>
                        <template v-slot:right>
                            <Button primary @click="handleImport" :loading="loading">
                                Start Import
                            </Button>
                        </template>
                    </Controls>
                </template>

                <template v-else-if="user">

                    <template v-if="isUserV2">

                        <p class="mb-6">
                            Looks like you're coming from X3 Log.
                        </p>

                        <p class="mb-6">
                            Before we get started on the import, let's confirm your timezone. We'll use this during the
                            import.
                        </p>

                        <Field>
                            <Label>Timezone (Search by Major City)</Label>
                            <TimezoneInput v-model="form.fields.timezone"/>
                            <FormError :form="form" field="timezone"/>
                        </Field>

                        <Controls>
                            <template v-slot:right>
                                <Button primary @click="prepared = true">
                                    Next
                                </Button>
                            </template>
                        </Controls>

                    </template>

                    <template v-else>

                        <Paragraph>
                            Welcome! Looks like this is a fresh account, so there's no data to import.
                        </Paragraph>

                    </template>

                </template>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import Paragraph from '@/components/Paragraph.vue';
import Instruction from '@/components/Instruction.vue';

export default defineComponent({
  name: 'Import',
  components: {Instruction, Paragraph},
  computed: {
    isUserV2() {
      return this.user && this.user.sourceType === 'x3-log-v2';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    window.app.on('importStatusUpdate', this.handleStatusUpdate);
    this.form.fields = {
      ...this.form.fields,
      timezone: this.user.timezone,
    };
  },
  destroyed() {
    window.app.off('importStatusUpdate', this.handleStatusUpdate);
  },
  data() {
    return {
      complete: false,
      importing: false,
      importStatus: {},
      importError: null,
      form: new Form({
        bands: true,
        movements: true,
        templates: true,
        workouts: true,
        body: {
          weight: true,
          bodyFat: true,
        },
        wellness: {
          happiness: true,
          energy: true,
          stress: true,
          sex: true,
          sleep: true,
          notes: true,
        },
        timezone: null,
      }),
      loading: false,
      prepared: false,
    };
  },
  methods: {
    ...mapActions({
      show: 'my/show',
      import: 'my/import',
    }),
    handleCancel() {
      this.$router.back();
    },
    handleImport() {
      this.loading = true;
      this.import({
        ...this.form.fields,
      }).then(() => {
        this.importing = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    handleStatusUpdate(e) {

      const payload = e.payload;

      if (payload.key === 'error') {
        this.importError = payload;
        return;
      }

      this.importStatus[payload.key] = {
        ...payload,
      };

      this.complete = e.complete;
    },
    handleWellnessLogCreate() {
      this.$router.push({
        name: 'log/create',
        query: {
          template: 'wellness',
          ...this.form.fields.wellness,
        },
      });
    },
    handleWellnessLogDecline() {
      this.$router.push({
        name: 'workout/index',
      });
    },
  },
  watch: {
    complete: {
      handler() {
        if (this.complete) {
          this.show();
        }
      },
    },
    ['form.fields']: {
      deep: true,
      handler() {

        let fields = {
          ...this.form.fields,
        };

        if ((!this.form.fields.bands || !this.form.fields.movements) && this.form.fields.workouts) {
          fields.workouts = false;
        }

        if (!this.form.fields.movements && this.form.fields.templates) {
          fields.templates = false;
        }

        if (JSON.stringify(fields) !== JSON.stringify(this.form.fields)) {
          this.form.fields = {
            ...fields,
          };
        }
      },
    }
  }
})
</script>

<style scoped lang="scss">

</style>
