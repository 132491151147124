<template>
    <ExerciseForm v-if="editing"
                  v-model="form.fields"
                  @cancel="handleExerciseCancel"
                  @delete="handleExerciseDelete"
                  @save="handleExerciseSave"
    />

    <div class="flex items-center justify-between mb-1">
        <Label class="!mb-0">Exercises</Label>
        <div class="border rounded px-2 text-sm cursor-pointer" @click="$emit('toggle')" v-if="localValue.length > 1">
            {{ sorting ? 'Done Sorting' : 'Sort Exercises' }}
        </div>
    </div>

    <div class="mb-3">
        <draggable v-model="localValue"
                   @start="drag=true"
                   @end="drag=false"
                   item-key="id"
                   handle=".drag-handle"
                   chosen-class="chosen"
        >
            <template #item="{element, index}">
                <Row class="cursor-pointer"
                     :exercise="element"
                     @click="handleExerciseEdit(element)"
                     :sortable="sorting"
                     :bordered="true"
                />
            </template>
        </draggable>
    </div>

    <Button block primary outlined @click="handleExerciseAdd">
        Add Exercise
    </Button>
</template>

<script>
import {defineComponent} from 'vue'
import Row from '@/components/Exercise/Row.vue';
import ExerciseForm from '@/components/Exercise/Form.vue';
import {Exercise, Form} from '@/internal.js';
import {uuid} from '@/helpers.js';
import draggable from 'vuedraggable'

const defaultExercise = {
  advanced: false,
  bandIds: [],
  duration: 0,
  movementId: null,
  notes: null,
  partialRepetitions: null,
  rank: null,
  repetitions: null,
  weight: null,
};

export default defineComponent({
  name: 'ExercisesInput',
  components: {
    draggable,
    ExerciseForm,
    Row,
  },
  emits: [
    'update:modelValue',
    'toggle',
  ],
  data() {
    return {
      drag: false,
      editing: false,
      form: new Form({
        ...defaultExercise,
      }),
      localValue: [],
    };
  },
  methods: {
    handleExerciseAdd() {
      this.form.fields = {
        ...defaultExercise,
      };
      this.editing = true;
    },
    handleExerciseCancel() {
      this.form.fields = {};
      this.editing = false;
    },
    handleExerciseDelete() {
      this.localValue = [
        ...this.localValue.filter((exercise) => {

          if (exercise.id === this.form.fields.id) {
            return false;
          }

          return true;
        }),
      ];
      this.editing = false;
    },
    handleExerciseEdit(exercise) {

      if (this.sorting) {
        return;
      }

      this.form.fields = {
        ...defaultExercise,
        ...exercise,
      };
      this.editing = true;
    },
    handleExerciseSave() {
      if (this.form.fields.id) {
        this.localValue = [
          ...this.localValue.map((exercise) => {
            if (exercise.id === this.form.fields.id) {
              return new Exercise({
                ...this.form.fields,
              });
            }

            return exercise;
          }),
        ];
      } else {
        this.localValue = [
          ...this.localValue,
          new Exercise({
            id: uuid(),
            ...this.form.fields,
          }),
        ]
      }

      this.editing = false;
      this.form.fields = {};
    },
  },
  props: {
    modelValue: {
      required: true,
      type: Array,
    },
    sorting: {
      required: false,
      type: Boolean,
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.$emit('update:modelValue', this.localValue);
        }
      },
    },
    modelValue: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) !== JSON.stringify(this.modelValue)) {
          this.localValue = [
            ...this.modelValue,
          ];
        }
      },
    },
  },
});
</script>

<style scoped lang="scss">
.chosen {
    background: #888;
}
</style>
