<template>
    <div class="mobile-menu bg-white dark:bg-black" :class="[
      $isStandalone ? '!pb-6': null,
      active ? 'is-active' : null,
    ]">

        <div class="mobile-menu-header">

            <div class="flex items-center justify-between mb-4 px-5 pt-5 pb-1">
                <Logo/>
                <div @click="handleClose">
                    <Icon icon="times" size="2x" class="dark:text-white"/>
                </div>
            </div>

            <UpdateAvailable class="mt-3" />

            <OnTrial class="mt-3" />

        </div>

        <div class="mobile-menu-content px-6 pb-6">

            <template v-for="menu in menus">
                <Menu :menu="menu" @menuItemClick="handleClose"/>
            </template>

            <ThemeSwitcher/>

            <Version />

        </div>

    </div>
</template>

<script>
import {mapActions} from 'vuex';

import Logo from '@/components/Logo.vue';
import Menu from '@/components/Menu.vue';
import ThemeSwitcher from '@/components/ThemeSwitcher.vue';

import AdminMenu from '@/menus/AdminMenu.js';
import AccountMenu from '@/menus/AccountMenu.js';
import CommunityMenu from '@/menus/CommunityMenu.js';
import LegalMenu from '@/menus/LegalMenu.js';
import LogsMenu from '@/menus/LogsMenu.js';
import ManageMenu from '@/menus/ManageMenu.js';
import PublicMenu from '@/menus/PublicMenu.js';
import ToolsMenu from '@/menus/ToolsMenu.js';
import Version from '@/components/Version.vue';
import UpdateAvailable from '@/components/UpdateAvailable.vue';
import OnTrial from '@/components/OnTrial.vue';

export default {
  name: 'MobileMenu',
  components: {
    OnTrial,
    UpdateAvailable,
    Version,
    ThemeSwitcher,
    Menu,
    Logo,
  },
  computed: {
    active() {
      return this.$store.state.ui.mobileMenuActive;
    },
    menus() {

      let menus = [];

      if (this.$authenticated()) {
        menus.push(ToolsMenu());
        menus.push(LogsMenu({
          user: this.user,
        }));
        menus.push(ManageMenu());
        menus.push(CommunityMenu());
        menus.push(AccountMenu({
          user: this.user,
        }));
      } else {
        menus.push(PublicMenu());
      }

      menus.push(LegalMenu());

      if (this.user && this.user.isAdmin) {
        menus.push(AdminMenu());
      }

      return menus;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    ...mapActions({
      setMobileMenuActive: 'ui/mobileMenuActive',
    }),
    handleClose() {
      this.setMobileMenuActive(false);
    },
  },
}
</script>

<style scoped lang="scss">
.mobile-menu {

    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow-y: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateY(100%);
    transition: all .2s;
    z-index: 50;

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    &.is-active {
        transform: translateY(0);
    }

    &.is-standalone {
        padding-bottom: $standalonePaddingBottom;
    }

    .mobile-menu-header {

    }

    .mobile-menu-content {
        flex: 1;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }
}
</style>
