import ForgotPassword from '@/views/Auth/ForgotPassword';
import Login from '@/views/Auth/Login';
import ResetPassword from '@/views/Auth/ResetPassword';
import SignUp from '@/views/Auth/SignUp';
import Logout from '@/views/Auth/Logout';

const routes = [
  {
    name: 'auth/login',
    path: '/auth/login',
    component: Login,
    meta: {
      icon: 'lock',
      middleware: ['guest'],
      title: 'Log In',
    },
  },
  {
    name: 'auth/sign-up',
    path: '/auth/sign-up',
    component: SignUp,
    meta: {
      icon: 'circle-plus',
      middleware: ['guest'],
      title: 'Sign Up',
    },
  },
  {
    name: 'auth/forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    meta: {
      middleware: ['guest'],
      title: 'Forgot Password',
    },
  },
  {
    name: 'auth/reset-password',
    path: '/auth/reset-password/:resetToken',
    component: ResetPassword,
    meta: {
      middleware: ['guest'],
      title: 'Reset Password',
    },
  },
  {
    name: 'auth/logout',
    path: '/auth/logout',
    component: Logout,
    meta: {
      icon: 'lock',
      title: 'Log Out',
    },
  },
];

export default routes;
