<template>
    <div class="container mx-auto px-6">
        <slot/>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Container',
})
</script>

<style scoped lang="scss">
.container {

}
</style>
