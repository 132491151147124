import {
  BandService as Service,
  Model,
  Workout
} from '@/internal';

import convert from 'convert';
import numeral from 'numeral';

export default class Band extends Model {

  static get key() {
    return 'band';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get brandAndName() {
    return `${this.brand} / ${this.name}`;
  }

  get maxForceIndex() {
    if (this.resistance.maxKg) {
      // 50 lbs = 22.6796 kgs
      return this.resistance.maxKg ? parseFloat((this.resistance.maxKg / 22.6796).toFixed(3)) : null;
    }
  }

  resistanceFormatted(systemOfMeasure) {

    if (systemOfMeasure && this.resistance) {

      const displayUnit = systemOfMeasure === 'metric' ? 'kg' : 'lb';
      const displayFormat = systemOfMeasure === 'metric' ? '0,0.0' : '0,0'
      let minResistance = '';
      let maxResistance = '';

      if (this.resistance.minKg) {
        minResistance = `${numeral(convert(parseFloat(this.resistance.minKg), 'kg').to(displayUnit)).format(displayFormat)} ${displayUnit}`;
      }

      if (this.resistance.maxKg) {
        maxResistance = `${numeral(convert(parseFloat(this.resistance.maxKg), 'kg').to(displayUnit)).format(displayFormat)} ${displayUnit}`;
      }

      return `${minResistance} ${minResistance && maxResistance ? '-' : ''} ${maxResistance}`.trim();
    }

    return '';
  }

  get workout() {
    return this.belongsTo(Workout);
  }
}
