<template>
    <div class="text-xl font-semibold dark:text-white mb-3">
        <slot />
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Subtitle'
});
</script>

<style scoped lang="scss">

</style>
