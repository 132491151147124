<template>
    <div class="relative">
        <slot></slot>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Tooltip',
  props: {
    label: {
      required: true,
      type: String,
    },
    position: {
      default: 'top',
      type: String,
    }
  }
})
</script>

<style scoped lang="scss">

</style>
