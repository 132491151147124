<template>

    <Loading v-if="loading"/>

    <input ref="file" class="hidden h-0" type="file" accept="image/*" @change="handleFileChange">

    <div v-if="file">
        <div class="rounded-2xl overflow-hidden relative">
            <div class="absolute top-2 right-2 flex items-center justify-around gap-x-2">
                <div class="cursor-pointer bg-neutral-400 h-9 w-9 rounded-3xl flex items-center justify-center"
                     v-if="isImage" @click="handleImageEdit">
                    <Icon icon="pencil" fixed-width/>
                </div>
                <div class="cursor-pointer bg-neutral-400 h-9 w-9 rounded-3xl flex items-center justify-center"
                     @click="handleFileDelete">
                    <Icon icon="trash" fixed-width/>
                </div>
            </div>
            <div class="aspect-square bg-cover bg-center" :style="`background-image: url('${file.proxyUrl}');`" v-if="isImage"></div>
            <div class="p-5 bg-neutral-200 flex items-center justify-center" v-else>
                {{ file.name }}
            </div>
        </div>
    </div>

    <Button :block="block" primary outlined @click="handleFinderLaunch" v-else>
        <template v-if="selectedFile">
            File: {{ selectedFile.name }}
        </template>
        <div class="inline-block" v-else>
            <div class="flex items-center justify-between">
                <Icon icon="upload" class="mr-3"/>
                <div>Upload File</div>
            </div>
        </div>
    </Button>

</template>

<script>
import {
  mapActions,
} from 'vuex';

export default {
  name: 'FileInput',
  computed: {
    file() {
      // return this.value ? this.$store.getters['file/findBy'](this.value) : null;
      // Not sure why 👆 isn't working

      return this.files.find(file => file.id === this.modelValue) || null;
    },
    files() {
      return this.$store.getters['file/all'];
    },
    isImage() {
      return this.file.mime.includes('image/');
    },
  },
  data() {
    return {
      loading: false,
      localPreview: false,
      selectedFile: null,
    };
  },
  methods: {
    ...mapActions({
      fileStore: 'file/store',
      fileDestroy: 'file/destroy',
      uiImageEditor: 'ui/imageEditor',
    }),
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    handleFinderLaunch() {
      this.$refs.file.click();
    },
    handleFileDelete() {
      window.app.dialog({
        title: 'Delete File',
        message: 'Are you sure you want to delete this file?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.fileDestroy({
                id: this.file.id,
              }).then(() => {
                window.app.snackbar('File Deleted');
                this.selectedFile = null;
                this.$emit('update:modelValue', null);
              }).finally(() => {
                this.loading = false;
                this.selectedFile = null;
                this.$refs.file.value = null;
              });

            },
          }
        ],
      });
    },
    handleFileStore() {

      this.loading = true;

      const formData = new FormData();

      formData.append('file', this.selectedFile);
      formData.append('name', this.selectedFile.name);

      this.fileStore({formData}).then((response) => {
        this.$emit('update:modelValue', parseInt(response));
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
        this.selectedFile = null;
        this.$refs.file.value = null;
      });

    },
    handleImageEdit() {
      this.uiImageEditor({
        active: true,
        fileId: this.file.id,
      });
    },
  },
  props: {
    block: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      required: false,
      type: Boolean,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    types: {
      default: () => [],
      required: false,
      type: Array,
    },
    modelValue: {
      required: true,
    },
  },
  watch: {
    selectedFile: {
      handler() {
        if (this.selectedFile) {
          this.handleFileStore();
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">

</style>
