<template>
    <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 overflow-hidden">

        <div class="dark:bg-neutral-700 bg-neutral-200 flex items-center justify-between p-3">

            <div>
                <span class="font-semibold">{{ workout.workoutAt.format('ddd MMM D, YYYY') }}</span>
                <div class="text-sm flex items-center gap-x-4">
                    <div v-if="user.startDate">{{ workout.sinceStart(user.startDate) }}</div>
                    <div v-if="workout.duration">
                        <Icon icon="stopwatch" /> {{$timerValue(workout.duration)}}
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-between gap-x-5">
                <div v-if="loading">
                    <Icon icon="loader" class="text-lg fa-spin" />
                </div>
                <div class="cursor-pointer" @click="handleWorkoutDuplicate" v-else>
                    <Icon icon="copy" class="text-lg"></Icon>
                </div>
                <div class="cursor-pointer" @click="handleWorkoutShare">
                    <Icon icon="arrow-up-from-bracket" class="text-lg"></Icon>
                </div>
                <div class="cursor-pointer" @click="handleWorkoutEdit">
                    <Icon icon="edit" class="text-lg"></Icon>
                </div>
            </div>

        </div>

        <div v-if="workout.file">
            <img :src="workout.file.proxyUrl" :alt="workout.file.name">
        </div>

        <div v-if="workout.template" class="bg-neutral-400 dark:bg-neutral-900 p-1 text-center font-bold">
            {{workout.template.name}}
        </div>

        <div class="p-3 text-center font-semibold" v-if="!workout.exercises.length">
            No Exercises Logged
        </div>

        <div class="-my-1.5" v-else>
            <div class="p-3">
                <template v-for="exercise in workout.exercises">
                    <ExerciseRow :exercise="exercise" />
                </template>
            </div>
        </div>

        <div class="p-4 pt-0" v-if="workout.notes">
            <Label>Notes</Label>
            <div>{{ workout.notes }}</div>
        </div>

    </div>
</template>

<script>
import {defineComponent} from 'vue'

import ExerciseRow from '@/components/Exercise/Row.vue';
import {mapActions} from 'vuex';

export default defineComponent({
  name: 'ResourceListItem',
  components: {
    ExerciseRow,
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    workout() {
      return this.resource;
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      workoutDuplicate: 'workout/duplicate',
    }),
    handleWorkoutDuplicate() {
        this.loading = true;
        return this.workoutDuplicate({
          id: this.workout.uuid,
        }).then((id) => {
          window.app.snackbar({
            message: 'Workout Duplicated',
          });
          const workout = this.$store.getters['workout/findBy'](id);
          this.$router.push(`/workout/${workout.uuid}/edit`);
        }).finally(() => {
          this.loading = false;
        });
    },
    handleWorkoutEdit() {
      this.$router.push(`/workout/${this.workout.uuid}/edit`);
    },
    handleWorkoutShare() {
      this.$router.push(`/workout/${this.workout.uuid}/share`);
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">

</style>
