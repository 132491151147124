<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title>Forgot Password?</Title>

                <Field>
                    <Label>Email *</Label>
                    <TextInput v-model="form.fields.email" placeholder="Email"/>
                    <FormError :form="form" field="email"/>
                </Field>

                <Button primary block @click="handleForgotPassword">
                    Send Password Reset Email
                </Button>

                <div class="text-center my-5">
                    - or -
                </div>

                <Button secondary outlined block @click="handleLogIn">
                    Log In
                </Button>
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'

import {
  Form,
} from '@/internal.js';
import {mapActions} from 'vuex';
import {isLocal} from '@/helpers.js';

export default defineComponent({
  name: 'ForgotPassword',
  created() {
    if (isLocal()) {
      this.form.fields = {
        email: 'nick@portonefive.com',
      }
    }
  },
  data() {
    return {
      form: new Form({
        email: '',
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authForgotPassword: 'auth/forgotPassword',
    }),
    handleForgotPassword() {

      if (this.loading) {
        return;
      }

      this.form.errors.clear();

      this.loading = true;

      this.authForgotPassword({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Check your email for more instructions',
          type: 'success',
        })
      }).catch((error) => {
        window.app.snackbarError(error);
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleLogIn() {
      this.$router.push(window.app.findRouteByName('auth/login'));
    },
  },
})
</script>


<style scoped>

</style>
