<template>
    <div class="logo font-semibold text-2xl font-montserrat">
        <span class="text-black dark:text-white">V3</span><span class="text-neutral-500">ME</span>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {env} from '@/helpers.js';

export default defineComponent({
  name: 'Logo',
  data() {
    return {
      appName: env('APP_NAME'),
    };
  },
})
</script>

<style scoped>

</style>
