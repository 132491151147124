import {
  File,
  Log,
  Model, Perk,
  UserService as Service,
} from '@/internal';

export default class User extends Model {

  static get key() {
    return 'user';
  }

  static get fields() {
    return {
      birthDate: {
        required: false,
        type: Date,
      },
      email: {
        required: true,
        type: String,
      },
      firstName: {
        required: true,
        type: String,
      },
      lastName: {
        required: true,
        type: String,
      },
      startDate: {
        required: false,
        type: Date,
      },
      trialEndsAt: {
        required: false,
        type: Date,
      },
      username: {
        required: false,
        type: String,
      },
    };
  }

  static get service() {
    return Service;
  }

  get file() {
    return this.belongsTo(File);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  featureEnabled(key, defaultValue = false) {
    return true;
  }

  hasRole(role) {
    return this.role === role;
  }

  hasPermission(permission) {
    return this.permissions[permission];
  }

  get initials() {
    return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
  }

  isV2() {
    return this.sourceType === 'x3-log-v2';
  }

  isV2ImportComplete() {
    return this.settings && this.settings.importComplete;
  }

  get logs() {
    return this.hasMany(Log);
  }

  get perks() {
    return this.hasMany(Perk);
  }
}
