import {createApp} from 'vue';
import '@/internal';
import mitt from 'mitt';
import pluralize from 'pluralize';
import convert from 'convert';
import {ColorPicker} from 'vue3-colorpicker';
import numeral from 'numeral';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import BugsnagPerformance from '@bugsnag/browser-performance'
import {parseBoolean} from '@/helpers.js';

import App from './App.vue'
import router, {routerBuilder} from './router';
import store from './store';

import 'vue3-colorpicker/style.css';
import '@/assets/scss/app.scss';

import Framework from '@/domain/Framework.js';

import AuthDriver from '@/drivers/AuthDriver.js';
import LocalStorageDriver from '@/drivers/LocalStorageDriver.js';
import ThemeDriver from '@/drivers/ThemeDriver.js';

import AutocompleteInput from '@/components/AutocompleteInput';
import Box from '@/components/Box';
import Button from '@/components/Button';
import Container from '@/components/Container';
import Controls from '@/components/Controls.vue';
import DateInput from '@/components/DateInput.vue';
import DateTimeInput from '@/components/DateTimeInput.vue';
import Dropdown from '@/components/Dropdown.vue';
import FakeInput from '@/components/FakeInput.vue';
import Field from '@/components/Field';
import FileInput from '@/components/FileInput.vue';
import FilePickerButton from '@/components/FilePickerButton.vue';
import FontAwesomeIconInput from '@/components/FontAwesomeIconInput.vue';
import FormError from '@/components/FormError';
import Help from '@/components/Help.vue';
import Icon from '@/components/Icon';
import Instruction from '@/components/Instruction.vue';
import JSON from '@/components/JSON';
import Label from '@/components/Label';
import Loading from '@/components/Loading';
import NumberInput from '@/components/NumberInput.vue';
import NumberInputUnitAware from '@/components/NumberInputUnitAware.vue';
import Page from '@/components/Page';
import Paragraph from '@/components/Paragraph';
import PasswordInput from '@/components/PasswordInput';
import PhoneInput from '@/components/PhoneInput';
import RangeInput from '@/components/RangeInput.vue';
import ResourceList from '@/components/ResourceList.vue';
import ResourceListItem from '@/components/ResourceListItem.vue';
import SelectInput from '@/components/SelectInput.vue';
import StartStopInput from '@/components/StartStopInput.vue';
import Subtitle from '@/components/Subtitle.vue';
import Switch from '@/components/Switch';
import TextareaInput from '@/components/TextareaInput.vue';
import TextInput from '@/components/TextInput';
import TimezoneInput from '@/components/TimezoneInput.vue';
import Title from '@/components/Title';
import Tooltip from '@/components/Tooltip.vue';
import UnderConstruction from '@/components/UnderConstruction.vue';

import appData from '../package.json';

const emitter = mitt();
const framework = new Framework();

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  appVersion: appData.version,
  enabledReleaseStages: [
    'local',
    'production',
  ],
  releaseStage: import.meta.env.VITE_APP_ENV,
  onError: function(event) {
    const user = store.getters['auth/user'];
    if (user) {
      event.setUser(user.id, user.email, user.fullName);
    }
  },
});

BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY });

framework.env({
  apiBaseUrl: import.meta.env.VITE_APP_API_URL,
  appBaseUrl: import.meta.env.VITE_APP_APP_URL,
  environment: import.meta.env.VITE_APP_ENV,
  streamrNodeIp: import.meta.env.VITE_STREAMR_NODE_IP,
  streamrStreamId: import.meta.env.VITE_STREAMR_STREAM_ID,
  streamrPrivateKey: import.meta.env.VITE_STREAMR_PRIVATE_KEY,
});

framework.auth(AuthDriver);
framework.storage(LocalStorageDriver);
framework.theme(ThemeDriver);

framework.addVue(createApp, App);
framework.addStore(store);
framework.addRouter(router, routerBuilder);

const bugsnagVue = Bugsnag.getPlugin('vue');
framework.addPlugin(bugsnagVue);

framework.addComponent(AutocompleteInput);
framework.addComponent(Box);
framework.addComponent(Button);
framework.addComponent(ColorPicker);
framework.addComponent(Container);
framework.addComponent(Controls);
framework.addComponent(DateInput);
framework.addComponent(DateTimeInput);
framework.addComponent(Dropdown);
framework.addComponent(FakeInput);
framework.addComponent(Field);
framework.addComponent(FileInput);
framework.addComponent(FilePickerButton);
framework.addComponent(FontAwesomeIconInput);
framework.addComponent(FormError);
framework.addComponent(Help);
framework.addComponent(Icon);
framework.addComponent(Instruction);
framework.addComponent(JSON);
framework.addComponent(Label);
framework.addComponent(Loading);
framework.addComponent(NumberInput);
framework.addComponent(NumberInputUnitAware);
framework.addComponent(Page);
framework.addComponent(Paragraph);
framework.addComponent(PasswordInput);
framework.addComponent(PhoneInput);
framework.addComponent(RangeInput);
framework.addComponent(ResourceList);
framework.addComponent(ResourceListItem);
framework.addComponent(SelectInput);
framework.addComponent(StartStopInput);
framework.addComponent(Subtitle);
framework.addComponent(Switch);
framework.addComponent(TextareaInput);
framework.addComponent(TextInput);
framework.addComponent(TimezoneInput);
framework.addComponent(Tooltip);
framework.addComponent(Title);
framework.addComponent(UnderConstruction);

framework.addGlobalProperty('authenticated', () => store.state.auth.authenticated);
framework.addGlobalProperty('convert', convert);
framework.addGlobalProperty('isProduction', import.meta.env.VITE_APP_ENV === 'production');
framework.addGlobalProperty('isStandalone', window.navigator.standalone);
framework.addGlobalProperty('numeral', numeral);
framework.addGlobalProperty('parseBoolean', parseBoolean);
framework.addGlobalProperty('pluralize', (word, count) => pluralize(word, count));
framework.addGlobalProperty('themeDark', true);
framework.addGlobalProperty('timerValue', (timerValue) => {
  const minutes = Math.floor(timerValue / 60);
  const rawSeconds = timerValue - (60 * minutes);
  const seconds = rawSeconds < 10 ? `0${rawSeconds}` : rawSeconds;
  return `${minutes}:${seconds}`;
});

framework.addHelper('snackbar', (config = null) => {

  if (typeof config === 'string') {
    config = {
      message: config,
    };
  }

  return store.dispatch('ui/snackbar', {
    active: true,
    ...config,
  });
});
framework.addHelper('snackbarError', (error, config = {}) => {

  let errors = error.response && error.response.data && error.response.data.errors ? error.response.data.errors : null;

  if (errors) {

    if (!Array.isArray(errors)) {
      errors = Object.values(errors);
    }

    config.message = errors.map((error) => {
      return error.detail ? error.detail : error;
    }).join('<br />');

  } else {
    console.error('Cannot find error message.', error);
    config.message = 'Error';
  }

  window.app.snackbar({
    type: 'error',
    ...config,
  });
});
framework.addHelper('dialog', (config = {}) => {
  return store.dispatch('ui/dialog', {
    active: true,
    ...config,
  });
});
framework.addHelper('dialogClose', () => {
  return store.dispatch('ui/dialogClose');
});
framework.addHelper('emit', (eventName, payload = {}) => {
  emitter.emit(eventName, payload);
});
framework.addHelper('on', (eventName, handler = null) => {
  emitter.on(eventName, handler);
});
framework.addHelper('off', (eventName, handler = null) => {
  emitter.off(eventName, handler);
})
framework.addHelper('randomHexColor', () => {
  return `#${Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, '0')}`;
});

framework.beforeBoot = () => {

  const token = window.app.auth.getToken();

  if (token && store._actions['my/show']) {
    return store.dispatch('my/show');
  }

  return new Promise((resolve) => {
    resolve();
  });
};

framework.boot();
