<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-md">
                <WorkoutResourceList :key="$route.fullPath" />
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import WorkoutResourceList from '@/components/Workout/ResourceList.vue';

export default defineComponent({
  name: 'Index',
  components: {
    WorkoutResourceList,
  },
});
</script>

<style scoped lang="scss">

</style>
