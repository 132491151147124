<template>
    <div v-if="onTrial" class="mb-3 sm:px-0 px-5 w-full">
        <Button primary outlined block @click="handleClick">
            {{ label }}
        </Button>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';

export default defineComponent({
  name: 'OnTrial',
  computed: {
    label() {
      return `Trial Ends ${this.user.trialEndsAt.fromNow()}`;
    },
    onTrial() {
      return this.user && this.user.onTrial && !this.user.subscribed;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    ...mapActions({
      setMobileMenuActive: 'ui/mobileMenuActive',
    }),
    handleClick() {
      this.setMobileMenuActive(false);
      this.$router.push(window.app.findRouteByName('my/billing'));
    },
  }
})
</script>

<style scoped lang="scss">

</style>
