<template>
    <div>
        <input type="range"
               class="input range-input w-full h-2 bg-neutral-300 rounded-lg appearance-none cursor-pointer dark:bg-neutral-600"
               :value="modelValue"
               @input="handleInput($event.target.value)"
               @blur="handleBlur"
               :max="max"
               :min="min"
               :step="step"
        />
        <div class="flex items-center justify-between">
            <div>{{min}}</div>
            <div>{{max}}</div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'RangeInput',
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    max: {
      default: null,
      required: false,
    },
    min: {
      default: null,
      required: false,
    },
    step: {
      default: null,
      required: false,
    },
    modelValue: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
