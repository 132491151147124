import Form from '@/views/Movement/Form.vue';
import Index from '@/views/Movement/Index';

const routes = [
  {
    name: 'movement/index',
    path: '/movement',
    component: Index,
    meta: {
      icon: 'dumbbell',
      title: 'Movements',
    },
  },
  {
    name: 'movement/create',
    path: '/movement/create',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Add Movement',
    },
  },
  {
    name: 'movement/edit',
    path: '/movement/:movementUUID/edit',
    component: Form,
    meta: {
      icon: 'bolt',
      title: 'Edit Movement',
    },
  },
];

export default routes;
