import {
  ResourceService,
} from '@/internal';

export default class WorkoutService extends ResourceService {

  constructor() {
    super();
    this.baseUrl = 'workout';
  }

  charts(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/charts`, params),
      params: this.prepareParams(params),
    });
  }

  duplicate(id, payload = {}) {

    const {params, responseType} = payload;

    return this.request({
      method: 'post',
      url: this.generateUrl(`${this.baseUrl}/${id}/duplicate`, params),
      params: this.prepareParams(params),
      responseType,
    });
  }

  share(id, payload = {}) {

    const {params, responseType} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/${id}/share`, params),
      params: this.prepareParams(params),
      responseType,
    });
  }
}
