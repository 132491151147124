<template>
    <div class="menu py-2">

        <div class="cursor-pointer flex justify-between py-2 uppercase text-sm" v-if="menu.title" @click="handleIsOpenToggle">
            <span class="dark:text-neutral-400">{{ menu.title }}</span>
            <Icon :icon="open ? 'chevron-down' : 'chevron-right'" />
        </div>

        <template v-for="menuItem in menu.menuItems" v-if="open">
            <MenuItem :menuItem="menuItem" @click="$emit('menuItemClick')"/>
        </template>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

import {Menu} from '@/internal.js';
import MenuItem from '@/components/MenuItem.vue';

export default defineComponent({
  name: 'Menu',
  components: {
    MenuItem,
  },
  created() {
    this.open = this.menu.open;
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    handleIsOpenToggle() {
      this.open = !this.open;
    },
  },
  props: {
    menu: {
      required: true,
      type: Menu,
    },
  }
});
</script>

<style scoped lang="scss">
.menu {

}
</style>
