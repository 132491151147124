<template>
    <input type="number"
           pattern="\d*"
           inputmode="decimal"
           class="input w-full block flex-1 p-2 rounded placeholder:text-gray-400 bg-transparent border border-neutral-300 dark:border-neutral-500"
           :value="modelValue"
           @input="handleInput($event.target.value)"
           @change="handleChange"
           @blur="handleBlur"
           :max="max"
           :min="min"
           :step="step"
    />
</template>

<script>
export default {
  name: 'NumberInput',
  methods: {
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleChange() {
      if (this.min !== null && this.modelValue < this.min) {
        this.handleInput(this.min);
      } else if (this.max !== null && this.modelValue > this.max) {
        this.handleInput(this.max);
      }
    },
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    max: {
      default: null,
      required: false,
      type: Number,
    },
    min: {
      default: null,
      required: false,
      type: Number,
    },
    step: {
      default: null,
      required: false,
      type: Number,
    },
    modelValue: {
      required: true,
    },
  }
}
</script>

<style scoped lang="scss">

</style>
