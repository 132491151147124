<template>
    <div class="bg-neutral-100 dark:bg-neutral-800 rounded my-4 flex items-center justify-between cursor-pointer"
         @click="handleMovementEdit">
        <div class="flex items-center p-3">
            <div>
                {{ resource.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResourceListItem',
  computed: {
    movement() {
      return this.resource;
    },
  },
  methods: {
    handleMovementEdit() {
      this.$router.push(`/movement/${this.movement.uuid}/edit`);
    },
  },
  props: {
    resource: {
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">

</style>
