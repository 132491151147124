<template>
    <TextInput type="date"
               :modelValue="modelValue"
               :placeholder="placeholder"
               @update:modelValue="handleInput"
    />
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'DateInput',
  methods: {
    handleInput(value) {
      this.$emit('update:modelValue', value);
    },
  },
  props: {
    modelValue: {
      required: true,
    },
    placeholder: {
      default: 'Date',
      type: String,
    },
  }
})
</script>

<style scoped lang="scss">

</style>
