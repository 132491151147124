<template>
    <div>
        <Button outlined :secondary="!running" :primary="running" @click="handleToggle">
            <Icon fixedWidth :icon="running ? 'volume-xmark' : 'volume'" fixed-width />
        </Button>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

import metronomeSound from '@/assets/sounds/metronome.mp3';

export default defineComponent({
  name: 'Metronome',
  beforeUnmount() {
    if (this.running) {
      this.soundSource.stop();
    }
    this.audioContext.close();
  },
  computed: {
    label() {
      return this.running ? 'Stop Metronome' : 'Start Metronome';
    },
  },
  data() {
    return {
      running: false,
      audioContext: new (window.AudioContext || window.webkitAudioContext)(),
      soundBuffer: null,
    };
  },
  mounted() {
    this.loadSound(metronomeSound);
  },
  methods: {
    loadSound(url) {
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          this.audioContext.decodeAudioData(buffer, (decodedBuffer) => {
            this.soundBuffer = decodedBuffer;
          });
        });
    },
    handleToggle() {
      this.running = !this.running;

      if (this.running) {
        this.soundSource = this.audioContext.createBufferSource();
        this.soundSource.buffer = this.soundBuffer;
        this.soundSource.loop = true;
        this.soundSource.connect(this.audioContext.destination);
        this.soundSource.start(0);
      } else {
        this.soundSource.stop();
      }
    },
  },
});
</script>

<style scoped lang="scss">

</style>
