import {
  Menu,
} from '@/internal';

export default (payload = {}) => {
  return new Menu({
    title: 'Manage',
    open: true,
    menuItems: [
      {
        route: window.app.findRouteByName('log/index'),
      },
      {
        route: window.app.findRouteByName('band/index'),
      },
      {
        route: window.app.findRouteByName('movement/index'),
      },
      {
        route: window.app.findRouteByName('template/index'),
      },
    ],
  });
};
