<template>
    <Loading v-if="loading"/>

    <ResourceListHeader :addLabel="addLabel"
                        :addRoute="addRoute"
                        :title="title"
    />

    <template v-for="resource in collection">
        <component :is="renderAs" :resource="resource"/>
    </template>

    <Paginator :paginator="paginator"
               @previous="handlePagePrevious"
               @next="handlePageNext"
    />
</template>

<script>
import {
  mapActions,
} from 'vuex';

import Paginator from '@/components/Paginator.vue';
import ResourceListHeader from '@/components/ResourceListHeader.vue';
import ResourceListItem from '@/components/ResourceListItem.vue';

/**
 * Load the data based on the url
 * Use the query to manage paginator
 */


export default {
  name: 'ResourceList',
  components: {
    Paginator,
    ResourceListHeader,
    ResourceListItem,
  },
  computed: {
    collection() {
      return this.resource.vuexModuleKey ? this.$store.getters[`${this.resource.vuexModuleKey}/collection`](this.ids) : [];
    },
    indexAction() {
      return this.resource.vuexModuleKey ? `${this.resource.vuexModuleKey}/index` : null;
    },
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      ids: [],
      loading: false,
      paginator: null,
      header: {},
    };
  },
  methods: {
    ...mapActions({}),
    loadData(page = null, inBackground = false) {

      if (!this.indexAction) {
        console.error('No indexAction');
        return false;
      }

      let params = {
        page: page || this.$route.query.page || 1,
        perPage: 10,
        ...this.params,
        ...this.query,
      };

      this.loading = !inBackground;
      return this.$store.dispatch(this.indexAction, {
        params,
      }).then(({ids, paginator}) => {
        this.ids = ids;
        this.paginator = paginator;
      }).finally(() => {
        this.loading = false;
      });
    },
    handlePageNext() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.paginator.currentPage + 1,
        }
      });
    },
    handlePagePrevious() {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: this.paginator.currentPage - 1,
        }
      });
    },
  },
  props: {
    addLabel: {
      default: null,
      required: false,
      type: String,
    },
    addRoute: {
      required: false,
    },
    params: {
      default: () => {
      },
      required: false,
    },
    query: {
      default: () => {
      },
      required: false,
    },
    renderAs: {
      default: () => ResourceListItem,
      required: false,
    },
    resource: {
      required: true,
    },
    title: {
      default: () => '',
      type: String,
    }
  },
  watch: {
    ['$route']: {
      deep: true,
      handler() {
        this.loadData();
      },
    }
  }
}
</script>

<style scoped lang="scss">

</style>
