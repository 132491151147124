import store from '@/store';

export default (to, from, next) => {

  const authenticated = store.state.auth.authenticated;
  const user = store.getters['auth/user'];
  const subscribed = user ? user.subscribed : false;
  const onTrial = user ? user.onTrial : false;
  const subscribedOrOnTrial = subscribed || onTrial;

  const trialEndedPath = window.app.findRouteByName('my/trial-ended').path;

  const allowablePaths = [
    window.app.findRouteByName('my/billing').path,
    window.app.findRouteByName('my/profile').path,
    trialEndedPath,
  ]

  if (authenticated && user && !subscribedOrOnTrial && !allowablePaths.includes(to.path)) {

    let path = trialEndedPath;

    window.app.d(`[checkSubscription] Redirected to ${path}`);

    return next({ path });

  } else {

    return next();

  }
}
