<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">
                <BandResourceList :key="$route.fullPath" />
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import BandResourceList from '@/components/Band/ResourceList.vue';

export default defineComponent({
  name: 'Index',
  components: {
    BandResourceList,
  },
});
</script>

<style scoped lang="scss">

</style>
