<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <Title>Reset Password</Title>

                <Field>
                    <Label>Email *</Label>
                    <TextInput v-model="form.fields.email"/>
                    <FormError :form="form" field="email"/>
                </Field>

                <Field>
                    <Label>Password *</Label>
                    <PasswordInput v-model="form.fields.password"/>
                    <FormError :form="form" field="password"/>
                </Field>

                <Field>
                    <Label>Password Confirmation *</Label>
                    <PasswordInput v-model="form.fields.passwordConfirmation"/>
                    <FormError :form="form" field="passwordConfirmation"/>
                </Field>

                <Button primary block @click="handleResetPassword">
                    Reset Password
                </Button>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import {isLocal} from '@/helpers.js';

export default defineComponent({
  name: 'ResetPassword',
  created() {
    if (isLocal()) {
      this.form.fields = {
        ...this.form.fields,
        password: 'secret',
        passwordConfirmation: 'secret',
      };
    }
  },
  data() {
    return {
      form: new Form({
        email: this.$route.query.email,
        password: '',
        passwordConfirmation: '',
        token: this.$route.params.resetToken,
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      authResetPassword: 'auth/resetPassword',
    }),
    handleResetPassword() {

      if (this.loading) {
        return;
      }

      this.form.errors.clear();

      this.loading = true;

      this.authResetPassword({
        ...this.form.fields,
      }).then(() => {
        window.app.snackbar({
          message: 'Password reset!',
          type: 'success',
        });
        this.$router.push(window.app.findRouteByName('dashboard'));
      }).catch((error) => {
        window.app.snackbarError(error);
        this.form.recordErrors(error);
      }).finally(() => {
        this.loading = false;
      });

    },
  },
})
</script>

<style scoped>

</style>
