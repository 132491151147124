<template>

</template>

<script>
import {defineComponent} from 'vue'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default defineComponent({
  name: 'WebsocketManager',
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    this.pusher = Pusher;

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: import.meta.env.VITE_PUSHER_APP_KEY,
      cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
      wsHost: import.meta.env.VITE_PUSHER_HOST,
      wsPort: import.meta.env.VITE_PUSHER_PORT,
      wssPort: import.meta.env.VITE_PUSHER_PORT,
      forceTLS: false,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      encrypted: true,
      authEndpoint: `${window.app.env.apiBaseUrl}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${window.app.auth.getToken()}`,
        },
      },
    });

    this.channels.import = this.echo.private(`user.${this.user.uuid}`)
      .listen('ImportStatusUpdate', (e) => {
        window.app.emit('importStatusUpdate', e);
      });

  },
  destroyed() {
    if (this.channels.import) {
        this.echo.leaveChannel(`user.${this.user.uuid}`);
    }
  },
  data() {
    return {
      channels: {
        import: null,
      },
      echo: null,
      pusher: null,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
