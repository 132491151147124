import {
  ResourceService,
} from '@/internal';

export default class ExerciseService extends ResourceService {
  constructor() {
    super();
    this.baseUrl = 'exercise';
  }

  last(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/last`, params),
      params: this.prepareParams(params),
    });
  }

  max(payload = {}) {

    const {params} = payload;

    return this.request({
      method: 'get',
      url: this.generateUrl(`${this.baseUrl}/max`, params),
      params: this.prepareParams(params),
    });
  }
}
