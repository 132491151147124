import Features from '@/views/Public/Features.vue';
import Pricing from '@/views/Public/Pricing.vue';
import PrivacyPolicy from '@/views/Public/PrivacyPolicy.vue';
import TermsOfService from '@/views/Public/TermsOfService.vue';

const routes = [
  {
    name: 'features',
    path: '/features',
    component: Features,
    meta: {
      icon: 'check',
      title: 'Features',
    },
  },
  {
    name: 'terms-of-service',
    path: '/terms-of-service',
    component: TermsOfService,
    meta: {
      icon: 'gavel',
      title: 'Terms of Service',
    },
  },
  {
    name: 'privacy-policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    meta: {
      icon: 'eye',
      title: 'Privacy Policy',
    },
  },
  {
    name: 'pricing',
    path: '/pricing',
    component: Pricing,
    meta: {
      icon: 'dollar',
      title: 'Pricing',
    },
  },
];

export default routes;
