<template>
    <div class="logo font-semibold text-2xl font-montserrat">
        <span :class="[dark ? 'text-white' : 'text-black']">X3</span><span class="text-neutral-500">LOG</span>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {env} from '@/helpers.js';
import color from 'color';

export default defineComponent({
  name: 'Logo',
  computed: {
    dark() {
      return color(this.background).isDark();
    },
  },
  data() {
    return {
      appName: env('APP_NAME'),
    };
  },
  props: {
    background: {
      required: true,
    },
  },
})
</script>

<style scoped>

</style>
