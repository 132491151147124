<template>

</template>

<script>
import {defineComponent} from 'vue';
import LogRocket from 'logrocket';

export default defineComponent({
  name: 'LogRocket',
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {

    if (import.meta.env.VITE_APP_ENV === 'local') {
      return;
    }

    const rootHostname = import.meta.env.VITE_APP_MARKETING_URL.replace('https://', '');

    LogRocket.init(import.meta.env.VITE_LOG_ROCKET_APP_ID, {
      rootHostname,
    });
  },
  data() {
    return {
      identified: false,
    };
  },
  watch: {
    user: {
      immediate: true,
      handler() {

        if (this.user && !this.identified) {

          LogRocket.identify(this.user.id, {
            name: this.user.fullName,
            email: this.user.email,
          });

          this.identified = true;
        }
      },
    },
  }
})
</script>

<style scoped lang="scss">

</style>
