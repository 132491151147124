const defaultDialog = {
  active: false,
  title: null,
  message: '',
  actions: [],
};

const defaultFileDialog = {
  active: false,
  fileId: null,
};

const defaultSnackbar = {
  active: false,
  message: null,
  action: null,
  duration: 5000,
  type: 'default',
};

export default {
  key: 'ui',
  namespaced: true,
  state: {
    dialog: {
      ...defaultDialog,
    },
    imageEditor: {
      ...defaultFileDialog,
    },
    isMobile: false,
    mobileMenuActive: false,
    scrollPosition: null,
    snackbar: {
      ...defaultSnackbar,
    },
  },
  actions: {
    dialog({commit}, payload) {
      commit('dialog', payload);
    },
    dialogClose({commit}, payload) {
      commit('dialog', {
        ...defaultDialog,
      });
    },
    imageEditor({commit}, payload) {
      commit('imageEditor', payload);
    },
    imageEditorClose({commit}, payload) {
      commit('imageEditor', {
        ...defaultFileDialog,
      });
    },
    isMobile({commit}, payload) {
      commit('isMobile', payload);
    },
    mobileMenuActive({commit}, payload) {
      commit('mobileMenuActive', payload);
    },
    snackbar({commit}, payload) {
      commit('snackbar', {
        ...defaultSnackbar,
        ...payload,
      });
    },
    snackbarClose({commit}, payload) {
      commit('snackbar', {
        ...defaultSnackbar,
      });
    },
  },
  mutations: {
    dialog(state, payload) {
      state.dialog = {
        ...state.dialog,
        ...payload,
      };
    },
    imageEditor(state, payload) {
      state.imageEditor = {
        ...state.imageEditor,
        ...payload,
      };
    },
    isMobile(state, payload) {
      state.isMobile = payload;
    },
    mobileMenuActive(state, payload) {

      const body = document.body;
      let scrollPosition = state.scrollPosition;

      if (body.style.position === 'fixed') {
        body.style.position = '';
        body.style.left = '';
        body.style.right = '';
        body.style.bottom = '';
        body.style.top = '';
        window.scrollTo(0, scrollPosition);
        document.documentElement.scrollTop = scrollPosition; // For Safari
        document.body.scrollTop = scrollPosition; // For old versions of IE
      } else {
        scrollPosition = window.scrollY || window.pageYOffset; // Save current scroll position
        body.style.position = 'fixed';
        body.style.left = '0';
        body.style.right = '0';
        body.style.bottom = '0';
        body.style.top = `-${scrollPosition}px`;
      }

      state.scrollPosition = scrollPosition;
      state.mobileMenuActive = payload;
    },
    snackbar(state, payload) {
      state.snackbar = {
        ...state.snackbar,
        ...payload,
      };
    },
  },
};
