import {
  ApiService,
} from '@/internal';

export default class AuthService extends ApiService {

  forgotPassword(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/forgot-password`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  login(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/login`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  logout(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/log-out`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  resetPassword(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/reset-password`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  signUp(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: `/auth/sign-up`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  userShow(payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'get',
      url: '/auth/user',
      params: this.prepareParams(params),
    });
  }

  userUpdate(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/auth/user',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  userImport(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/auth/user/import',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
