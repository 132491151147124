import {createStore} from 'vuex';
import StoreBuilder from '@/domain/StoreBuilder';

import {
  AdminStore,
  AuthStore,
  BandStore,
  ExerciseStore,
  FileStore,
  LogStore,
  LogEntryStore,
  LogItemStore,
  LogEntryItemStore,
  MovementStore,
  MyStore,
  PerkStore,
  SystemStore,
  TemplateStore,
  UserStore,
  UIStore,
  WorkoutStore,
} from '@/internal';

export const storeBuilder = new StoreBuilder();

storeBuilder.addModule(AdminStore);
storeBuilder.addModule(AuthStore);
storeBuilder.addModule(BandStore);
storeBuilder.addModule(ExerciseStore);
storeBuilder.addModule(FileStore);
storeBuilder.addModule(LogStore);
storeBuilder.addModule(LogEntryStore);
storeBuilder.addModule(LogItemStore);
storeBuilder.addModule(LogEntryItemStore);
storeBuilder.addModule(MovementStore);
storeBuilder.addModule(MyStore);
storeBuilder.addModule(PerkStore);
storeBuilder.addModule(SystemStore);
storeBuilder.addModule(TemplateStore);
storeBuilder.addModule(UIStore);
storeBuilder.addModule(UserStore);
storeBuilder.addModule(WorkoutStore);

export default createStore({
  modules: storeBuilder.getVuexModules(),
});
