<template>
    <div class="box mx-auto my-6 overflow-hidden rounded bg-neutral-200 dark:bg-neutral-800">
        <div class="p-4">
            <slot />
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Box'
})
</script>

<style scoped lang="scss">
.box {

}
</style>
