import {
  Exercise as Model,
  ExerciseService as Service,
  ResourceStore,
} from '@/internal';

const service = new Service();

const store = new ResourceStore({
  model: Model,
  actions: {
    last: ({commit}, payload) => {
      return service.last(payload).then((response) => {
        return ResourceStore.processData(Model.key, commit, response.data);
      });
    },
    max: ({commit}, payload) => {
      return service.max(payload).then((response) => {
        return ResourceStore.processData(Model.key, commit, response.data);
      });
    },
  },
});

export default store.toObject();
