<template>
    <Page>
        <Container>

            <Loading v-if="loading"/>

            <div class="mx-auto max-w-lg">

                <Title class="mb-6">Analytics</Title>

                <DateRangeInput v-model="range"/>

                <div class="flex items-center">
                    <Field class="flex-1">
                        <Label>Log</Label>
                        <SelectInput :options="logsAsOptions" v-model="log" />
                    </Field>
                    <Field class="ml-3 mt-6">
                        <div class="flex items-center">
                            <Button :primary="chartType === 'column'"
                                    :secondary="chartType !== 'column'"
                                    :outlined="chartType !== 'column'"
                                    @click="chartType = 'column'"
                                    class="!rounded-r-none"
                            >
                                <Icon icon="chart-column" />
                            </Button>
                            <Button :primary="chartType === 'line'"
                                    :secondary="chartType !== 'line'"
                                    :outlined="chartType !== 'line'"
                                    @click="chartType = 'line'"
                                    class="!rounded-l-none"
                            >
                                <Icon icon="chart-line" />
                            </Button>
                        </div>
                    </Field>
                </div>


                <Field v-if="log === 'workouts'">
                    <Label>Movement</Label>
                    <SelectInput :options="movementsAsOptions" v-model="movement"/>
                </Field>

                <template v-for="options in charts">
                    <Chart :options="options"
                           :range="range"
                    />
                </template>

            </div>

        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import DateRangeInput from '@/components/DateRangeInput.vue';
import {mapActions} from 'vuex';
import UnderConstruction from '@/components/UnderConstruction.vue';
import Chart from '@/components/Chart.vue';
import Instruction from '@/components/Instruction.vue';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'Index',
  components: {
    Instruction,
    Chart,
    UnderConstruction,
    DateRangeInput
  },
  computed: {
    bands() {
      return this.$store.getters['band/all'] || [];
    },
    logs() {
      return this.$store.getters['log/all'] || [];
    },
    logsAsOptions() {
      return [
        {
          label: '- Select a Log -',
          value: null,
        },
        {
          label: 'Workouts',
          value: 'workouts',
        },
        ...this.logs.map((log) => {
          return {
            label: log.name,
            value: log.id,
          };
        }),
      ];
    },
    movements() {
      return this.$store.getters['movement/all'] || [];
    },
    movementsAsOptions() {
      return [
        {
          label: '- Select a Movement -',
          value: null,
        },
        ...this.movements.sort((a, b) => a.name > b.name ? 1 : -1).map((movement) => {
          return {
            label: movement.name,
            value: movement.id,
          };
        }),
      ];
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {
    await this.loadBands();
    await this.loadLogs();
    await this.loadMovements();
  },
  data() {
    return {
      charts: [],
      chartType: 'column',
      loading: false,
      log: 'workouts',
      chartsDataLog: null,
      movement: null,
      range: {
        end: moment().endOf('month').toDate(),
        start: moment().startOf('month').toDate(),
        value: '1m',
      },
      chartsDataWorkout: null,
    };
  },
  methods: {
    ...mapActions({
      bandIndex: 'band/index',
      logIndex: 'log/index',
      logCharts: 'log/charts',
      movementIndex: 'movement/index',
      workoutCharts: 'workout/charts',
    }),
    createCharts() {
      let charts = [];

      if (this.log === 'workouts') {

        if (!this.movement) {
          return;
        }

        let yAxisMax = 16;

        let exercises = [
          ...(this.chartsDataWorkout ? this.chartsDataWorkout : []),
        ].sort((a, b) => a.x > b.x ? 1 : -1);

        charts.push({
          chart: {
            type: this.chartType,
          },
          title: {
            text: '',
          },
          series: [
            {
              name: 'Repetitions',
              lineWidth: 2,
              marker: {
                enabled: true,
                fillColor: 'transparent'
              },
              data: exercises.map(exercise => {

                let y = exercise.repetitions;

                if (y > yAxisMax) {
                  yAxisMax = y;
                }

                return {
                  x: exercise.x,
                  y,
                  bands: exercise.bands || [],
                };
              }),
            },
            {
              name: 'Max Force',
              data: exercises.map(exercise => {

                let y = exercise.max_force > 0 ? exercise.max_force : null;

                if (y > yAxisMax) {
                  yAxisMax = y;
                }

                return {
                  x: exercise.x,
                  y,
                  bands: exercise.bands || [],
                };
              }),
            },
          ],
          tooltip: {
            useHTML: true,
            formatter: function () {

              const system = window.app.storage.getItem('system') || {
                settings: {
                  timezone: moment.tz.guess() || 'utc'
                }
              };

              let date = moment.tz(this.x, system.settings.timezone).format('lll z');

              let markup = ``;

              markup += `<div class="text-base bg-white">`;

              markup += `<div class="my-1">${date}</div>`;

              markup += `<div class="font-bold my-1">${this.point.series.getName()}: ${this.point.y}</div>`;

              (this.point.bands || []).forEach((band) => {
                markup += `<div class="flex items-center my-1">`;
                if (band.color) {
                  markup += `<div class="w-3 aspect-square rounded rounded-full mr-2 border border-neutral-600" style="background: ${band.color};"></div>`;
                }
                markup += `<div>${band.name}</div>`;
                markup += `</div>`;
              });

              markup += `</div>`;

              return markup;
            },
          },
          yAxis: {
            min: 0,
            max: !exercises.length ? 40 : null,
          },
        });

      } else {

        if (this.chartsDataLog) {

          let yAxisMin = 20;
          let yAxisMax = 20;

          charts.push({
            chart: {
              type: this.chartType,
            },
            series: [
              ...this.chartsDataLog.map((logItem) => {

                return {
                  name: logItem.name,
                  data: logItem.log_item_entries.map(logItemEntry => {

                    if (yAxisMin > logItemEntry.chart_data.y) {
                      yAxisMin = logItemEntry.chart_data.y;
                    }

                    if (yAxisMax < logItemEntry.chart_data.y) {
                      yAxisMax = logItemEntry.chart_data.y;
                    }

                    return logItemEntry.chart_data;
                  }).sort((a, b) => a.x > b.x ? 1 : -1),
                };
              }),
            ],
            yAxis: {
              min: Math.max(yAxisMin * 0.9, 0),
              max: !this.chartsDataLog.length ? 20 : null,
            },
          });
        }
      }

      this.charts = [
        ...charts,
      ];
    },
    loadBands() {
      this.loading = true;
      return this.bandIndex().finally(() => {
        this.loading = false;
      });
    },
    loadLog() {
      this.loading = true;
      return this.logCharts({
        id: this.log,
        params: {
          start: this.range.start,
          end: this.range.end,
        },
      }).then((response) => {
        this.chartsDataLog = [
          ...response.data.data,
        ];
      }).finally(() => {
        this.loading = false;
      });
    },
    loadLogCharts() {
      if (this.log) {
        if (this.log === 'workouts') {
          if (this.movement) {
            this.loadWorkouts();
          }
        } else {
          this.loadLog();
        }
      }
    },
    loadLogs() {
      this.loading = true;
      return this.logIndex().finally(() => {
        this.loading = false;
      });
    },
    loadWorkouts() {
      this.loading = true;
      return this.workoutCharts({
        params: {
          movementId: this.movement,
          start: this.range.start,
          end: this.range.end,
        },
      }).then((response) => {
        this.chartsDataWorkout = [
          ...response.data.data,
        ];
      }).finally(() => {
        this.loading = false;
      });
    },
    loadMovements() {
      this.loading = true;
      return this.movementIndex().finally(() => {
        this.loading = false;
      });
    },
  },
  watch: {
    chartsDataLog: {
      deep: true,
      handler() {
        this.createCharts();
      },
    },
    chartsDataWorkout: {
      deep: true,
      handler() {
        this.createCharts();
      },
    },
    chartType: {
      handler() {
        this.createCharts();
      },
    },
    movement: {
      handler() {
        this.loadWorkouts();
      },
    },
    log: {
      handler() {
        this.loadLogCharts();
      },
    },
    range: {
      deep: true,
      handler() {
        this.loadLogCharts();
      },
    },
  },
})
</script>

<style scoped lang="scss">

</style>
