import {
  ApiService,
} from '@/internal';

export default class MyService extends ApiService {

  destroy(payload = {}) {
    const {params, ...rest} = payload;

    return this.request({
      method: 'delete',
      url: `/my/user`,
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  import(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/my/import',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  onboard(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/my/onboard',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  password(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/my/password',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  show(payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'get',
      url: '/my/user',
      params: this.prepareParams(params),
    });
  }

  stripeCheckout(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/my/stripe-checkout',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  stripePortal(payload = {}) {

    const { params } = payload;

    return this.request({
      method: 'get',
      url: '/my/stripe-portal',
      params: this.prepareParams(params),
    });
  }

  update(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'put',
      url: '/my/user',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  x3LogConnect(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'post',
      url: '/my/x3-log',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }

  x3LogDisconnect(payload = {}) {

    const { params, ...rest } = payload;

    return this.request({
      method: 'delete',
      url: '/my/x3-log',
      params: this.prepareParams(params),
      data: this.preparePayload(rest),
    });
  }
}
