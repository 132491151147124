<template>

    <headlessDialog :open="active" @close="handleClose" class="relative z-40">

        <div class="fixed inset-0 bg-black/50" aria-hidden="true" v-if="active"/>

        <div class="fixed inset-0 flex w-screen items-center justify-center p-4">

            <DialogPanel class="bg-white dark:bg-neutral-900 rounded p-4 max-w-sm w-full">

                <DialogTitle class="text-2xl font-semibold mb-3 text-center">{{ title }}</DialogTitle>

                <DialogDescription>{{ message }}</DialogDescription>

                <div class="flex items-center gap-x-2.5 justify-end mt-5">

                    <template v-for="action in actions">

                        <Button :primary="action.primary"
                                :secondary="action.secondary"
                                :danger="action.danger"
                                :outlined="action.outlined"
                                :warning="action.warning"
                                :class="action.class"
                                @click="handleActionClick(action)"
                        >
                            {{ action.text }}
                        </Button>

                    </template>

                </div>

            </DialogPanel>

        </div>

    </headlessDialog>

</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Dialog as headlessDialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'

export default {
  name: 'Dialog',
  components: {
    headlessDialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
  },
  computed: {
    active() {
      return this.$store.state.ui.dialog.active || false;
    },
    title() {
      return this.$store.state.ui.dialog.title || '';
    },
    message() {
      return this.$store.state.ui.dialog.message || '';
    },
    actions() {
      return this.$store.state.ui.dialog.actions || [];
    },
  },
  methods: {
    ...mapActions({
      setDialog: 'ui/dialog',
      dialogClose: 'ui/dialogClose',
    }),
    handleActionClick(action) {

        if (action.click) {
          action.click();
        }

        this.handleClose();
    },
    handleClose() {
      this.dialogClose();
    },
  }
};
</script>

<style scoped lang="scss">

</style>
