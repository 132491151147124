<template>
    <div class="field mb-5">
        <slot></slot>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Field',
});
</script>

<style scoped lang="scss">
.field {

}
</style>
