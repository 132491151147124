import Index from '@/views/Planner/Index';

const routes = [
  {
    name: 'planner/index',
    path: '/planner',
    component: Index,
    meta: {
      icon: 'calendar-week',
      title: 'Planner',
    },
  },
];

export default routes;
