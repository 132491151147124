<template>

    <Loading v-if="loading"/>

    <headlessDialog :open="active" class="relative z-40">

        <div class="fixed inset-0 bg-black/50" aria-hidden="true" v-if="active"/>

        <div class="fixed inset-0 flex w-screen items-center justify-center p-4">

            <DialogPanel class="bg-white dark:bg-neutral-900 rounded-lg overflow-hidden max-w-xl w-full relative">

                <div class="image-editor" style="height: 50vh;">
                    <PinturaEditor
                            ref="editor"
                            v-bind="props"
                            :src="src"
                            @pintura:load="handleLoad($event)"
                            @pintura:process="handleProcess($event)"
                    />
                </div>

                <div class="flex items-center justify-end gap-x-4 p-4">
                    <Button @click="handleClose">
                        Cancel
                    </Button>
                    <Button primary @click="handleSave">
                        Save
                    </Button>
                </div>

            </DialogPanel>

        </div>

    </headlessDialog>

</template>

<script>
import {
  mapActions,
} from 'vuex';

import {
  Dialog as headlessDialog,
  DialogPanel,
} from '@headlessui/vue'

import {PinturaEditor} from '@pqina/vue-pintura';
import {
  getEditorDefaults,
} from '@pqina/pintura';

import '@pqina/pintura/pintura.css';

export default {
  name: 'ImageEditor',
  components: {
    headlessDialog,
    DialogPanel,
    PinturaEditor,
  },
  computed: {
    active() {
      return this.$store.state.ui.imageEditor.active || false;
    },
    file() {
      return this.$store.state.ui.imageEditor.fileId ? this.$store.getters['file/show'](this.$store.state.ui.imageEditor.fileId) : null;
    },
  },
  data() {
    return {
      props: getEditorDefaults(),
      src: null,
      editedFile: null,
      result: undefined,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      fileReplace: 'file/replace',
      imageEditorClose: 'ui/imageEditorClose',
      setImageEditor: 'ui/imageEditor',
    }),
    handleClose() {
      this.imageEditorClose();
    },
    handleLoad(event) {
      // console.log('load', event.detail);
    },
    handleSave() {
      this.loading = true;
      let doneButton = document.getElementsByClassName('PinturaButtonExport')[0];
      doneButton.click();
    },
    handleProcess(event) {
      this.editedFile = event.detail.dest;
      this.result = URL.createObjectURL(event.detail.dest);
      this.handleFileReplace();
    },
    handleFileReplace() {

      this.loading = true;
      const formData = new FormData();

      formData.append('file', this.editedFile);
      formData.append('name', this.file.name);

      let fileId = this.file.id;

      this.fileReplace({
        id: fileId,
        formData,
      }).then(() => {
        window.app.snackbar({
          message: 'Changes Saved',
        });
        this.handleClose();

        window.app.emit('fileUpdated', {
          fileId,
        });

      }).catch((error) => {
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    async urlToFile(url, filename, mimeType) {

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Network response was not ok (${response.status})`);
        }
        const blob = await response.blob();
        return new File([blob], filename, {type: mimeType});
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
      }
    }
  },
  watch: {
    file: {
      immediate: true,
      handler() {

        if (this.file) {

          this.urlToFile(this.file.proxyUrl, this.file.name, this.file.mime)
            .then(file => {
              this.src = file;
            })
            .catch(error => {
              console.error('Error:', error);
            });

        } else {
          this.src = null;
        }

      }
    }
  }
};
</script>

<style lang="scss">
.image-editor {
    .PinturaButtonExport {
        display: none;
    }
}

.dark {
    .pintura-editor {
        --color-background: #171717;
        --color-foreground: #FFFFFF;
    }
}
</style>
