import {
  LogEntryItemService as Service,
  LogItem,
  Model,
} from '@/internal';

export default class LogEntryItem extends Model {

  static get key() {
    return 'logEntryItem';
  }

  static get service() {
    return Service;
  }

  static get fields() {
    return {};
  }

  get logItem() {
    return this.belongsTo(LogItem);
  }

}
