<template>
    <Loading v-if="loading"/>
    <Page>

        <Container>
            <div class="mx-auto max-w-md">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <Field>
                    <Label>Date</Label>
                    <DateTimeInput v-model="form.fields.loggedAt"/>
                </Field>

                <template v-for="logItem in logItems">

                    <Field>

                        <Label>{{ logItem.name }}</Label>

                        <template v-if="form.fields.logEntryItems && form.fields.logEntryItems[logItem.id]">

                            <NumberInput v-model="form.fields.logEntryItems[logItem.id].value"
                                         v-if="logItem.input === 'number'"
                            />

                            <TextInput v-model="form.fields.logEntryItems[logItem.id].value"
                                       v-else-if="logItem.input === 'text'"
                            />

                            <FileInput v-model="form.fields.logEntryItems[logItem.id].value"
                                       v-else-if="logItem.input === 'file'"
                            />

                            <Switch v-model="form.fields.logEntryItems[logItem.id].value"
                                    v-else-if="logItem.input === 'boolean'"
                            />

                            <RangeInput v-model="form.fields.logEntryItems[logItem.id].value"
                                        v-else-if="logItem.input === 'range'"
                                        :min="logItem.config.min"
                                        :max="logItem.config.max"
                                        :step="logItem.config.step"
                            />

                            <StartStopInput v-model="form.fields.logEntryItems[logItem.id].value"
                                            v-else-if="logItem.input === 'start-stop'"
                            />

                            <Timer v-model="form.fields.logEntryItems[logItem.id].value"
                                   v-else-if="logItem.input === 'timer'"
                            />

                        </template>

                    </Field>

                </template>

                <Controls>

                    <template v-slot:left>
                        <Button danger outlined @click="handleDelete" v-if="logEntry">
                            Delete
                        </Button>
                    </template>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue';
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import Timer from '@/components/Timer.vue';
import DateTimeInput from '@/components/DateTimeInput.vue';
import moment from 'moment-timezone';

export default defineComponent({
  name: 'Form',
  components: {DateTimeInput, Timer},
  computed: {
    log() {
      return this.$route.params.logKey ? this.$store.getters['log/findBy'](this.$route.params.logKey, 'key') : null;
    },
    logEntry() {
      return this.$route.params.logEntryUUID ? this.$store.getters['logEntry/findBy'](this.$route.params.logEntryUUID, 'uuid') : null;
    },
    logEntryItems() {
      return this.logEntry ? this.logEntry.logEntryItems : [];
    },
    logItems() {
      return this.log ? this.log.logItems.filter(logItem => !logItem.deletedAt) : [];
    },
    pageTitle() {
      if (this.log && this.logEntry) {
        return `${this.log.name} / Edit Entry`;
      } else if (this.log) {
        return `${this.log.name} / Add Entry`;
      } else {
        return 'Unknown';
      }
    },
    timezone() {
      return this.user.timezone || moment.tz.guess();
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  async created() {
    if (this.$route.params.logKey) {
      await this.loadLog().then(() => {

        if (this.log) {

          let fields = {
            loggedAt: moment(new Date(), this.timezone).format('YYYY-MM-DDTHH:mm'),
            logEntryItems: {},
          };

          this.log.logItems.filter(logItem => !logItem.deletedAt).forEach((logItem) => {
            fields.logEntryItems[logItem.id] = {
              logItemId: logItem.id,
              value: logItem.input === 'boolean' ? false : null,
            };
          });

          this.form.fields = {
            ...fields,
          }
        }
      });
    }

    if (this.$route.params.logEntryUUID) {
      await this.loadLogEntry();
    }
  },
  data() {
    return {
      form: new Form({
        loggedAt: moment(),
        logEntryItems: {},
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      logShow: 'log/show',
      logEntryDestroy: 'logEntry/destroy',
      logEntryShow: 'logEntry/show',
      logEntryStore: 'logEntry/store',
      logEntryUpdate: 'logEntry/update',
    }),
    fillForm() {

      let fields = {
        loggedAt: moment(this.logEntry.loggedAt, this.timezone).format('YYYY-MM-DDTHH:mm'),
        logEntryItems: {
          ...this.form.fields.logEntryItems,
        },
      };

      this.logEntryItems.forEach((logEntryItem) => {
        fields.logEntryItems[logEntryItem.logItemId] = {
          logItemId: logEntryItem.logItemId,
          value: logEntryItem.value,
        };
      });

      this.form.fields = {
        ...this.form.fields,
        ...fields,
      };

    },
    handleCancel() {
      this.$router.back();
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Log Entry',
        message: 'Are you sure you want to delete this log entry?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.logEntryDestroy({
                id: this.logEntry.uuid,
              }).then(() => {
                window.app.snackbar('Log Entry Deleted');
                this.selectedFile = null;
                this.$router.push(`/log/${this.log.key}`);
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleSave() {

      if (this.loading) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
        logEntryItems: Object.values(this.form.fields.logEntryItems),
        loggedAt: moment.tz(this.form.fields.loggedAt, this.timezone).utc().toISOString(),
      };

      if (this.logEntry) {
        payload.id = this.logEntry.uuid;
        action = this.logEntryUpdate;
      } else {
        payload.logUUID = this.log.uuid;
        action = this.logEntryStore;
      }

      this.loading = true;
      action(payload).then(() => {
        window.app.snackbar({
          type: 'success',
          message: 'Log Entry Saved',
        });
        this.$router.back();
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadLog() {
      this.loading = true;
      return this.logShow({
        id: this.$route.params.logKey,
        params: {
          include: [
            'logItems',
          ],
        },
      }).then(() => {
        if (this.log) {

        }
      }).catch((error) => {
        window.app.snackbarError(error);
      })
        .finally(() => {
          this.loading = false;
        });
    },
    loadLogEntry() {
      this.loading = true;
      return this.logEntryShow({
        id: this.$route.params.logEntryUUID,
        params: {
          include: [
            'logEntryItems',
          ],
        },
      }).then(this.fillForm)
        .catch((error) => {
          window.app.snackbarError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
