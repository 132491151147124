<template>
    <label class="label block text-xs font-medium uppercase mb-2">
        <slot />
    </label>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Label',
})
</script>

<style scoped lang="scss">
.label {

}
</style>
