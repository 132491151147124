<template>
    <div class="flex items-center justify-between mb-6">
        <div class="flex-grow">
            <Title>{{title}}</Title>
        </div>
        <div>
            <Button primary @click="handleClick">{{ addLabel }}</Button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ResourceListHeader',
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$router.push(this.addRoute);
    },
  },
  props: {
    addLabel: {
      default: null,
      required: false,
      type: String,
    },
    addRoute: {
      required: false,
    },
    title: {
      type: String,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
