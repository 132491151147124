<template>
    <div class="theme-switcher flex items-center justify-between py-2">
        <span>
            <Icon icon="moon" fixedWidth class="mr-3"/>Dark Theme
        </span>
        <Switch v-model="themeDark"/>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';

export default defineComponent({
  name: 'ThemeSwitcher',
  computed: {
    settings() {
      return this.$store.state.system.settings;
    },
  },
  created() {
    this.themeDark = this.settings.theme === 'dark';
  },
  data() {
    return {
      themeDark: false,
    };
  },
  methods: {
    ...mapActions({
      settingsUpdate: 'system/settings',
    }),
  },
  watch: {
    themeDark: {
      handler() {
        this.settingsUpdate({
          ...this.settings,
          theme: this.themeDark ? 'dark' : 'light',
        });
      }
    },
  }
})
</script>

<style scoped lang="scss">
.theme-switcher {
    font-size: 1.2em;
}
</style>
