<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">

                <div class="flex items-center justify-between mb-6">
                    <Title>{{pageTitle}}</Title>
                    <div class="cursor-pointer" @click="handleCancel">
                        <Icon icon="times" class="text-2xl"/>
                    </div>
                </div>

                <Field>
                    <Label>Name *</Label>
                    <TextInput v-model="form.fields.name" placeholder="Name"/>
                    <FormError :form="form" field="name"/>
                </Field>

                <Field>
                    <Label>Brand *</Label>
                    <TextInput v-model="form.fields.brand" placeholder="X3 Bar, Clench, SS, etc."/>
                    <FormError :form="form" field="brand"/>
                </Field>

                <Field>
                    <Label>Description</Label>
                    <TextInput v-model="form.fields.description" placeholder="Description"/>
                    <FormError :form="form" field="description"/>
                </Field>

                <Field>
                    <Label>Color *</Label>
                    <ColorPicker v-model:pureColor="form.fields.color" format="hex"/>
                    <FormError :form="form" field="color"/>
                </Field>

                <Field>

                    <Label>Resistance *</Label>

                    <div class="flex items-start justify-between gap-x-4 p-4 rounded border border-neutral-300 dark:border-neutral-500">

                        <div class="w-1/3">
                            <Label>Minimum ({{resistanceDisplayUnit}})</Label>
                            <NumberInputUnitAware v-model="form.fields.resistance.minKg"
                                                  base-unit="kg"
                                                  :display-unit="resistanceDisplayUnit"
                                                  class="text-center"
                            />
                            <FormError :form="form" field="resistanceMinKg"/>
                        </div>

                        <div class="w-1/3">
                            <Label>Maximum ({{resistanceDisplayUnit}})</Label>
                            <NumberInputUnitAware v-model="form.fields.resistance.maxKg"
                                                  base-unit="kg"
                                                  :display-unit="resistanceDisplayUnit"
                                                  class="text-center"
                            />
                            <FormError :form="form" field="resistanceMaxKg"/>
                        </div>

                        <div class="w-1/3">
                            <Label>Max Force Index</Label>
                            <FakeInput>
                                <div class="text-center">
                                    {{maxForceIndex}}
                                </div>
                            </FakeInput>
                        </div>

                    </div>

                </Field>

                <Field>

                    <Label>Dimensions</Label>

                    <div class="flex items-center justify-between gap-x-4 p-4 rounded border border-neutral-300 dark:border-neutral-500">

                        <div class="w-1/3">
                            <Label>Length ({{ dimensionsDisplayUnit }})</Label>
                            <NumberInputUnitAware v-model="form.fields.dimensions.lengthCm"
                                                  base-unit="cm"
                                                  :display-unit="dimensionsDisplayUnit"
                                                  class="text-center"
                            />
                        </div>

                        <div class="w-1/3">
                            <Label>Width ({{ dimensionsDisplayUnit }})</Label>
                            <NumberInputUnitAware v-model="form.fields.dimensions.widthCm"
                                                  base-unit="cm"
                                                  :display-unit="dimensionsDisplayUnit"
                                                  class="text-center"
                            />
                        </div>

                        <div class="w-1/3">
                            <Label>Thickness ({{ dimensionsDisplayUnit }})</Label>
                            <NumberInputUnitAware v-model="form.fields.dimensions.thicknessCm"
                                                  base-unit="cm"
                                                  :display-unit="dimensionsDisplayUnit"
                                                  class="text-center"
                            />
                        </div>

                    </div>

                </Field>

                <Controls>

                    <template v-slot:left>
                        <Button danger outlined @click="handleDelete" v-if="band">
                            Delete
                        </Button>
                    </template>

                    <template v-slot:right>
                        <div class="flex items-center justify-between gap-4">
                            <Button primary @click="handleSave">
                                Save
                            </Button>
                        </div>
                    </template>
                </Controls>

            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions} from 'vuex';
import {Form} from '@/internal.js';
import NumberInput from '@/components/NumberInput.vue';
import NumberInputUnitAware from '@/components/NumberInputUnitAware.vue';
import FakeInput from '@/components/FakeInput.vue';

export default defineComponent({
  name: 'Form',
  components: {FakeInput, NumberInputUnitAware, NumberInput},
  computed: {
    band() {
      return this.$route.params.bandUUID ? this.$store.getters['band/findBy'](this.$route.params.bandUUID, 'uuid') : null;
    },
    dimensionsDisplayUnit() {
      return this.user.systemOfMeasure === 'metric' ? 'cm' : 'in';
    },
    maxForceIndex() {
      if (this.form.fields.resistance.maxKg) {
        // 50 lbs = 22.6796 kgs
        return this.form.fields.resistance.maxKg ? parseFloat((this.form.fields.resistance.maxKg / 22.6796).toFixed(3)) : null;
      }
    },
    pageTitle() {
      return this.band ? 'Edit Band' : 'New Band';
    },
    resistanceDisplayUnit() {
      return this.user.systemOfMeasure === 'metric' ? 'kg' : 'lb';
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    if (this.$route.params.bandUUID) {
      this.loadBand();
    }
  },
  data() {
    return {
      form: new Form({
        brand: '',
        color: window.app.randomHexColor(),
        description: '',
        dimensions: {
          lengthCm: null,
          thicknessCm: null,
          widthCm: null,
        },
        name: '',
        resistance: {
          minKg: null,
          maxKg: null,
        },
      }),
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      bandDestroy: 'band/destroy',
      bandShow: 'band/show',
      bandStore: 'band/store',
      bandUpdate: 'band/update',
    }),
    fillForm() {
      this.form.fields = {
        brand: this.band.brand,
        color: this.band.color,
        description: this.band.description,
        name: this.band.name,
        dimensions: {
          lengthCm: null,
          thicknessCm: null,
          widthCm: null,
          ...this.band.dimensions,
        },
        resistance: {
          minKg: null,
          maxKg: null,
          ...this.band.resistance,
        },
      };
    },
    handleCancel() {
      this.$router.push(window.app.findRouteByName('band/index'));
    },
    handleDelete() {
      window.app.dialog({
        title: 'Delete Band',
        message: 'Are you sure you want to delete this band?',
        actions: [
          {
            text: 'No',
            outlined: true,
            click: () => {
            },
          },
          {
            text: 'Yes',
            danger: true,
            click: () => {

              this.loading = true;
              this.bandDestroy({
                id: this.band.uuid,
              }).then(() => {
                window.app.snackbar('Band Deleted');
                this.selectedFile = null;
                this.$router.push(window.app.findRouteByName('band/index'));
              }).finally(() => {
                this.loading = false;
              });

            },
          }
        ],
      });
    },
    handleSave() {

      if (this.loading) {
        return false;
      }

      let action;
      let payload = {
        ...this.form.fields,
      };

      if (this.band) {
        action = this.bandUpdate;
        payload.id = this.band.uuid;
      } else {
        action = this.bandStore;
      }

      this.loading = true;
      action(payload).then(() => {
        window.app.snackbar({
          type: 'success',
          message: 'Band Saved',
        });
        this.$router.back();
      }).catch((error) => {
        this.form.recordErrors(error);
        window.app.snackbarError(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadBand() {
      this.loading = true;
      this.bandShow({
        id: this.$route.params.bandUUID,
      }).then(this.fillForm)
        .catch((error) => {
          window.app.snackbarError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
})
</script>

<style scoped lang="scss">

</style>
