<template>
    <Loading v-if="loading"/>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">
                <Title class="mb-6">Switch User</Title>
                <UnderConstruction />
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'
import UnderConstruction from '@/components/UnderConstruction.vue';

export default defineComponent({
  name: 'UserSwitcher',
  components: {UnderConstruction},
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  data() {
    return {
      loading: false,
    };
  },
})
</script>

<style scoped lang="scss">

</style>
