<template>
    <div class="fixed inset-0 bg-black/50 z-30" aria-hidden="true" />
    <div class="fixed inset-0 flex w-screen items-center justify-center p-3 z-40">
        <div class="background bg-neutral-800 p-3 rounded-full">
            <svg class="animate-spin h-8 w-8" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="#ffffff" stroke-width="4"></circle>
                <path class="opacity-75" fill="#ffffff" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Loading',
  props: {
    fullScreen: {
      default: true,
      type: Boolean,
    }
  }
})
</script>

<style scoped lang="scss">

</style>
