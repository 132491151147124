<template>
    <Page>
        <Container>
            <div class="mx-auto max-w-sm">
                <Title>Marketplace</Title>
            </div>
        </Container>
    </Page>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Index'
})
</script>

<style scoped lang="scss">

</style>
